<!--
 * @FilePath: \zsw_new_edition\src\components\navComeDown\SuComeDown.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-25 14:21:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-03-03 09:26:35
-->
<!--贴图下拉框组件-->
<template>
  <div class="comeDownBox">
    <div class="contentBox">
      <ComeDownMiddle  :link="link" :classList="classList"></ComeDownMiddle>
    </div>
  </div>
</template>

<script>
import ComeDownMiddle from "@/components/navComeDown/ComeDownMiddle";
import { getNavigation } from "../../Api";

export default {
  name: "ChartletComeDown",
  props:[
    "link"
  ],
  data(){
    return {
      classList:""
    }
  },
  components: {
    ComeDownMiddle,
  },
  created() {
    getNavigation(2205).then((res) => {
      this.classList = res.data;
      console.log(res)
    });
  },
};
</script>

<style scoped>
.comeDownBox {
  height: 4.27rem;
  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(184, 184, 184, 0.16);
  opacity: 1;
  display: flex;
	justify-content: center;
  padding: 0.3rem;
}
.contentBox {
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
  display: flex;
	max-width: 100%;
}
</style>