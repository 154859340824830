<template>
  <div>
    <el-dialog
        @close="close"
        title="我要报错"
        width="30%"
      :visible.sync="centerDialogVisible"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <el-form>
        <el-form-item>
          <p style="font-size: 16px">如果您发现资料有不完善或者有误的，欢迎指出！我们将立即通知原作者进行更正！</p>
        </el-form-item>
        <el-form-item>
          <span style="color: #1d1d1d;font-weight: 500">错误内容描述：</span>
          <el-input v-model="content" type="textarea" :rows="3"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="submitError">提交报错</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import {addFeedback} from "../../Api";
import { getToken } from "../../utils/auth";
export default {
  name: "index",
  data() {
    return {
      centerDialogVisible: false,
      is: null,
      content: ''
    };
  },
  methods: {
    submitError(){
      if(!this.content){
        this.$message.warning('请输入描述后再进行提交')
        return false
      }
      const params = {
        type: 7,
        content:this.content,
        token: getToken(),
        info_id: this.id
      }
      addFeedback(params).then((res) => {
        if (res.data.error == "0") {
          this.$message.success("感谢您的反馈");
          this.close()
        }
      });
    },
    show(id) {
      this.id = id;
      this.centerDialogVisible = true;
    },
    //关闭回调
    close() {
      this.content = ''
      this.centerDialogVisible = false;
    },
  },
};
</script>

<style scoped>
/* ::v-deep .el-dialog__headerbtn{
  top: 0.15rem;
  right: 0.15rem;
  font-size: 0.16rem;
}
::v-deep .el-dialog__header{
  text-align: left;
  color: #040404;
  font-weight: bold;
  padding: 0.2rem 0.3rem;
  background: linear-gradient(180deg, #A4C3FC 0%, #F3F7FF 100%);
  height: 2.09rem;
}
::v-deep .el-dialog__title{
  font-size: 0.18rem;
} */
::v-deep .el-dialog{
  border-radius:16px
}
</style>