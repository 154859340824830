<template>
  <div v-if="centerDialogVisible">
    <div class="v-modal" style="z-index: 2002;"></div>
      <div class="scheduleBox">
        <div class="closeBox" @click="close">
          <img src="../../assets/image/footerVipClose.png" alt="" />
        </div>
        <div class="footerBox" @click="toVip">
          <img src="../../assets/image/footerVip.png" alt="" />
        </div>
      </div>
    </div>
</template>

<script>
import router from "../../router";
export default {
  name: "index",
  data() {
    return {
      centerDialogVisible: false,
    };
  },
  methods: {
    toVip(){
      this.close()
      router.push({'path':'/vip/course'})
    },
    show() {
      // this.content = content;
      this.centerDialogVisible = true;
      this.$nextTick(()=>{
        document.querySelector('body').style.overflow = 'hidden'
      })
    },
    //关闭回调
    close() {
      this.centerDialogVisible = false;
      this.$nextTick(()=>{
        document.querySelector('body').style.overflow = 'auto'
      })
    },
  },
};
</script>

<style scoped>
.v-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: .5;
  background: #000;
}
.scheduleBox{
  width:100%;
  position: fixed;
  bottom: -4px;
  z-index: 2003;
}
.footerBox{
  width:100%;
}
.footerBox img{
  width:100%;
  height:100%
}
.closeBox{
  width:100%;
  text-align: center;
}
.closeBox img{
  width:0.3rem;
  height:0.3rem;
  cursor: pointer;
}
/* ::v-deep .el-dialog__headerbtn{
  top: 0.15rem;
  right: 0.15rem;
  font-size: 0.16rem;
}
::v-deep .el-dialog__header{
  text-align: left;
  color: #040404;
  font-weight: bold;
  padding: 0.2rem 0.3rem;
  background: linear-gradient(180deg, #A4C3FC 0%, #F3F7FF 100%);
  height: 2.09rem;
}
::v-deep .el-dialog__title{
  font-size: 0.18rem;
} */
</style>