<!--
 * @FilePath: \zsw_new_edition\src\components\navComeDown\MaterialComeDown.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-25 14:21:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-01-13 19:41:29
-->
<!--素材下拉框组件-->
<template>
  <div class="comeDownBox">
    <div class="imgBox">
      <router-link
        v-for="(item,index) in hotMaterial" :key="index"
        :to="{ path: '/material/detail?detailId=' + item.id }"
        target="_blank"
        >
        <img :src="item.img" alt=""/>
      </router-link>
    </div>
    <div class="contentBox">
      <ComeDownMiddle :link="link" :classList="classList"></ComeDownMiddle>
    </div>
    <div class="rightBox">
      <div v-for="(item, index) in comeDownBanner" :key="index">
        <a target="_blank" :href="item.url">
          <img :src="item.src" alt="" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ComeDownMiddle from "@/components/navComeDown/ComeDownMiddle";
import { getBanner, navigationBar, getNavigation } from "../../Api";

export default {
  name: "MaterialComeDown",
  props:[
    "link"
  ],
  data() {
    return {
      hotMaterial: {},
      comeDownBanner: {},
      classList: [],
    };
  },
  components: {
    ComeDownMiddle,
  },
  created() {
    getBanner({
      position: 1148,
    }).then((res) => {
      this.comeDownBanner = res.data;
    });
    navigationBar(7).then((res) => {
      this.hotMaterial = res.data.slice(0, 2);
    });
    getNavigation(7).then((res) => {
      this.classList = res.data;
    });
  },
};
</script>

<style scoped>
.comeDownBox {
  height: 4.27rem;
  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(184, 184, 184, 0.16);
  opacity: 1;
  display: flex;
  justify-content: space-between;
  padding: 0.3rem 2.8rem 0.27rem 2.8rem;
}
.imgBox {
  display: flex;
  flex-direction: column;
}

.imgBox img:first-child {
  margin-bottom: 0.1rem;
}
.imgBox img {
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 8px;
  opacity: 1;
}

.contentBox {
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
  display: flex;
}

.rightBox {
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
  display: flex;
}

.rightBox {
  display: flex;
  flex-direction: column;
}
.rightBox img:not(:first-child) {
  margin-top: 0.1rem;
}
.rightBox img {
  width: 0.85rem;
  height: 0.85rem;
  opacity: 1;
  border-radius: 4px;
  cursor: pointer;
}
</style>