/*
 * @FilePath: \zhishe-pc\src\store\modules\uploadWork.js
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-23 14:04:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2021-12-20 15:58:50
 */
import {getUpToken} from "../../Api";

const state = {
    uploadTokenInfo:null,
    unSubmittedMaterial:[],
    unSubmittedSu:[],
    unSubmittedMax: [],
    unSubmittedSchemeText: [],
    CourseMultiVideo:[]
}

const mutations = {
    SET_UPLOAD_TOKEN_INFO: (state, data) => {
        state.uploadTokenInfo = data
    },
    SET_UN_SUBMITTED_MATERIAL:(state,data)=>{
        if(data.index!==-1){
            state.unSubmittedMaterial[data.index] = data.data
        }else{
            state.unSubmittedMaterial.push(data.data)
        }

    },
    SET_UN_SUBMITTED_SU:(state,data)=>{
        if(data.index!==-1){
            state.unSubmittedSu[data.index] = data.data
        }else{
            state.unSubmittedSu.push(data.data)
        }

    },
    SET_UN_SUBMITTED_MAX:(state,data)=>{
        if(data.index!==-1){
            state.unSubmittedMax[data.index] = data.data
        }else{
            state.unSubmittedMax.push(data.data)
        }

    },
    SET_UN_SUBMITTED_SCHEMETEXT:(state,data)=>{
        if(data.index!==-1){
            state.unSubmittedSchemeText[data.index] = data.data
        }else{
            state.unSubmittedSchemeText.push(data.data)
        }

    },
    REMOVE_UN_SUBMITTED_MATERIAL (state, index) {
        state.unSubmittedMaterial.splice(index,1)
    },
    REMOVE_UN_SUBMITTED_SU (state, index) {
        state.unSubmittedSu.splice(index,1)
    },
    REMOVE_UN_SUBMITTED_MAX (state, index) {
        state.unSubmittedSu.splice(index,1)
    },
    REMOVE_UN_SUBMITTED_SCHEMETEXT (state, index) {
        state.unSubmittedSchemeText.splice(index,1)
    },
    SET_COURSE_MULTI_VIDEO(state,data){
       let oldData =  state.CourseMultiVideo.filter(obj => { return(obj.id === data.id) })
        if(oldData.length===0){ // 添加
            state.CourseMultiVideo.push(data)
        }else{ // 修改
           const index = state.CourseMultiVideo.indexOf(oldData[0])
            state.CourseMultiVideo[index] = data
        }
    }
}
const actions = {
    //获取七牛云上传token
    getUploadToken({commit}){
        return new Promise((resolve, reject) =>{
            getUpToken().then(res=>{
                commit('SET_UPLOAD_TOKEN_INFO',res.data)
                resolve()
            }).catch(error => {
                console.log('错误')
                reject(error)
            })
        })
    },
    //点击新增作品时把未完成素材表单等数据缓存在vuex中
    addNewMaterial({commit},data){
        return new Promise(resolve=>{
            commit('SET_UN_SUBMITTED_MATERIAL',data)
            resolve()
        })
    },
    //点击新增作品时把未完成素材表单等数据缓存在vuex中
    addNewSu({commit},data){
        return new Promise(resolve=>{
            commit('SET_UN_SUBMITTED_SU',data)
            resolve()
        })
    },
    //点击新增作品时把未完成3D表单等数据缓存在vuex中
    addNewMax({commit},data){
        return new Promise(resolve=>{
            commit('SET_UN_SUBMITTED_MAX',data)
            resolve()
        })
    },
    //点击新增作品时把未完成方案文本表单等数据缓存在vuex中
    addNewSchemeText({commit},data){
        return new Promise(resolve=>{
            commit('SET_UN_SUBMITTED_SCHEMETEXT',data)
            resolve()
        })
    },
    // 删除待提交的素材
    removeNewMaterial({commit},index){
        commit('REMOVE_UN_SUBMITTED_MATERIAL',index)
    },
    // 删除待提交的素材
    removeNewSu({commit},index){
        commit('REMOVE_UN_SUBMITTED_SU',index)
    },
    // 删除待提交的3d
    removeNewMax({commit},index){
        commit('REMOVE_UN_SUBMITTED_MAX',index)
    },
    // 删除待提交的方案文本
    removeNewSchemeText({commit},index){
        commit('REMOVE_UN_SUBMITTED_SCHEMETEXT',index)
    },
    //上传章节视屏的视屏与资源信息
    setCourseMultiVideo({commit},data){
        commit('SET_COURSE_MULTI_VIDEO',data)
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}