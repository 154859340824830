<template>
  <el-dialog
    width="8.48rem"
    class="paySucceed"
    :visible.sync="innerVisible"
    @close="close"
    append-to-body
    v-if="!isPay"
  >
    <div slot="title">
      {{ paramss.title }}
      <!-- <span class="yue">（余额：{{ paramss.balance }}知币）</span> -->
    </div>
    <div class="payBox">
      <div class="left">
        <p>您要开通的是{{ paramss.typeTitle }}包含以下权益</p>
        <p v-for="item in paramss.contents" :key="item">{{ item }}</p>
      </div>
      <div class="right">
        <p class="mt20 f0404 f16">请选择支付方式</p>
        <el-radio-group v-model="payType" class="mt40">
          <el-radio label="0" class="">
            <i class="iconfont alipay-icon">&#xe655;</i>
            <p class="payTxt">支付宝支付</p>
          </el-radio>
          <el-radio label="1" class="">
            <i class="iconfont weCat-icon">&#xe662;</i>
            <p class="payTxt">微信支付</p>
          </el-radio>
        </el-radio-group>
        <div class="erWeiMaBox">
          <div class="payErWeiMa">
            <img
              class="img"
              :src="
                $ROOT_DIR +
                '/pay/' +
                (payType == 0 ? 'AliPay' : 'WeChatPay') +
                '/vipQrCodeNew?type=' +
                paramss.type +
                '&cate=' +
                paramss.cate +
                '&token=' +
                token
              "
              alt=""
            />
          </div>
          <div class="t_c">
            <p class="priceNum">
              ¥<span style="margin-left: 0.05rem">{{ paramss.money }}</span>
            </p>
          </div>
        </div>
        <p class="agreement c_p" @click="jump()">
          支付则默认同意《知设网用户协议》
        </p>
      </div>
    </div>
  </el-dialog>
  <el-dialog
    width="9.2rem"
    class="paySucceed"
    :visible.sync="innerVisible"
    append-to-body
    @close="closeSucces()"
    v-else
  >
    <div slot="title">开通会员</div>
    <div class="payCont">
      <img src="@/assets/image/icon/addBankSucceed.png" alt="" />
      <p class="f24 f_b f0404">恭喜你</p>
      <p class="f24 f_b f0404 mt10">本次交易支付成功</p>
    </div>
  </el-dialog>
</template>

<script>
import { getToken } from "../../utils/auth";
import { haveRecharge } from "../../Api";

export default {
  name: "index",
  data() {
    return {
      payType: "0",
      innerVisible: false,
      zhiCoinList: [],
      activeIndex: 0,
      token: getToken(),
      send: 0,
      isPay: false,
      timer: "",
    };
  },
  methods: {
    jump() {
      window.open("/#/other/index?type=2");
    },
    show() {
      this.innerVisible = true;
      this.timer = setInterval(() => {
        this.getRecharge();
      }, 2000);
    },
    getRecharge() {
      haveRecharge({ token: getToken(), type: "vip" }).then((res) => {
        if (res.data.res == 1) {
          this.isPay = true;
          clearInterval(this.timer);
        }
      });
    },
    close() {
      clearInterval(this.timer);
    },
    closeSucces(){
      this.isPay = false
      location.reload()
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
.agreement {
  color: #666666;
  font-size: 0.14rem;
  margin-top: 0.6rem;
}
.discount {
  height: 0.3rem;
  width: 1.23rem;
  text-align: center;
  line-height: 0.3rem;
  border: 0.01rem dashed #999999;
  border-radius: 0.16rem;
  margin-top: 0.2rem;
}
.priceNum {
  color: #f46600;
  font-size: 0.4rem;
  font-weight: bold;
}
.erWeiMaBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 3.33rem;
  height: 1.6rem;
  margin-top: 0.6rem;
}
.payErWeiMa {
  width: 1.6rem;
  height: 1.6rem;
  box-shadow: 0px 0px 0.06rem rgba(102, 102, 102, 0.2);
}
.payErWeiMa .img {
  width: 100%;
  height: 100%;
  background: coral;
  border-radius: 0.06rem;
}
::v-deep .el-radio-group {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.payTxt {
  font-size: 0.16rem;
  color: #040404;
  margin-left: 0.1rem;
}
::v-deep .el-radio {
  display: flex;
  align-items: center;
}
::v-deep .el-radio__label {
  display: flex;
  align-items: center;
}
.alipay-icon {
  color: #02a9f1;
  font-size: 0.3rem;
}
.weCat-icon {
  color: #09bb07;
  font-size: 0.28rem;
}
.payBox {
  display: flex;
  /* height: 4.27rem; */
}
.yue {
  color: #694114;
  font-size: 0.14rem;
}
.right {
  flex: 1;
  position: relative;
  padding: 0.4rem 0.6rem 0.4rem 0.8rem;
}
.left {
  width: 3.3rem;
  padding: 0.4rem 0.6rem;
  background-color: rgba(255, 250, 243, 0.5);
  border-radius: 0px 0px 0px 6px;
}
.left::after {
  content: "";
  visibility: hidden;
  opacity: 1;
  display: block;
  clear: both;
}
::v-deep .el-dialog__body {
  padding: 0;
}
.price {
  margin-top: 0.2rem;
  color: #ecb054;
  font-size: 0.24rem;
  font-weight: bold;
}
.item {
  width: 2rem;
  height: 1.7rem;
  background: #ffffff;
  border: 0.01rem solid #eeeeee;
  border-radius: 0.06rem;
  text-align: center;
  float: left;
  margin-right: 0.2rem;
  margin-top: 0.2rem;
  cursor: pointer;
}
.item.active {
  background: rgba(254, 223, 135, 0.46);
  border: 0.01rem solid #e09d06;
}
.zIcon {
  margin-top: 0.05rem;
  margin-bottom: 0.05rem;
  width: 0.6rem;
  height: 0.6rem;
}
.payCont {
  text-align: center;
  margin-top: 0.8rem;
  margin-bottom: 1.6rem;
}
.payCont img {
  width: 2.26rem;
  height: 2.24rem;
}
.paySucceed >>> .el-dialog__headerbtn {
  top: 0.2rem;
  right: 0.2rem;
  font-size: 0.22rem;
}
.paySucceed >>> .el-dialog__header {
  text-align: center;
  background: linear-gradient(90deg, #ffeed7 0%, #ffeed7 100%);
  height: 0.7rem;
  font-size: 0.22rem;
  color: #694114;
  font-weight: bold;
}

::v-deep .el-dialog__title {
  font-size: 0.18rem;
}
.left p {
  font-size: 0.14rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
  opacity: 1;
  margin-bottom: 0.3rem;
}
.left p:nth-child(1) {
  font-size: 0.18rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #040404;
  opacity: 1;
  margin-bottom: 0.4rem;
}
</style>