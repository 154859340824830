<template>
  <div>
    <el-dialog
      :visible.sync="centerDialogVisible"
      width="5rem"
      center
      :destroy-on-close="true"
      @close="close"
    >
      <div slot="title">
        <p>{{ dataInfo.title }}</p>
        <div class="payInfo">
          <div class="infoTitle">
            <p class="txt1">{{ dataInfo.titleDec }}:</p>
            <p class="txt2 txt-over">{{ dataInfo.projectInfo.name || dataInfo.projectInfo.title }}</p>
          </div>
          <p class="f16 t_c c_bb mt10">
            {{ dataInfo.title != "购买课程" ? "下载" : "学习" }}需支付相应的知币
          </p>
        </div>
      </div>
      <div>
        <div class="df_Yc">
          <p class="name f16">支付</p>
          ：
          <p class="f16 mr10">
            <span v-if="dataInfo.projectInfo.zsStr" class="c_f46">{{ dataInfo.projectInfo.zsStr }}</span>
            <span v-else class="c_f46">{{ dataInfo.projectInfo.price }}</span>知币
          </p>
          <p class="f14" v-if="dataInfo.title == '下载配套资源'">
            (VIP免费下载/观看,
            <a href="/#/vip/course" target="_blank"><span class="c_f46 c_p">立即开通>></span></a>
            )
          </p>
          <p class="f14" v-else>
            (VIP尊享8折,
            <a href="/#/vip/course" target="_blank"><span class="c_f46 c_p">立即开通>></span></a>
            )
          </p>
        </div>
        <div class="df_Yc mt30">
          <p class="name f16">知币余额</p>
          ：
          <p>
            <span class="mr8 f16">{{ dataInfo.balance }}</span
            >知币
          </p>
          <p
          v-if="!isMoney"
            style="
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #f46600;
              opacity: 1;
            "
          >(知币余额不足，请前往充值)
          </p>
        </div>
      </div>
      <div class="dfc mt60">
        <div class="topUp" @click="topUp">充值知币</div>
        <div class="submit" :class="{ noMoney: !isMoney }" @click="confirm">
          确认购买
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { zhiPay, picturePay } from "../../Api";

export default {
  name: "index",
  data() {
    return {
      centerDialogVisible: false,
      isMoney: false,
      isTopUp: false,
      payStatus: "noPay",
      dataInfo: {
        detailId: "",
        balance: 0,
        authorUid: "",
        userToken: "",
        projectInfo: {
          zsStr: "",
        },
      },
    };
  },
  created() {},
  methods: {
    show() {
      return new Promise((resolve) => {
        this.centerDialogVisible = true;
        this.dataInfo = this.data; // 页面给函数式组件传递的值（慎用）
				console.log(this.dataInfo);
				if (this.dataInfo.fid == 2205) {
					console.log(111);
					this.isMoney = this.dataInfo.balance >= this.dataInfo.projectInfo.price;
				} else{
					this.isMoney = this.dataInfo.balance >= this.dataInfo.projectInfo.zsStr;
				}
        resolve();
      });
    },
    //关闭回调
    close() {
      this.isTopUp = false;
    },
    //充钱
    topUp() {
      this.isTopUp = true;
      this.centerDialogVisible = false;
    },
    //确认支付
		confirm() {
			const params = {
				pid: this.dataInfo.detailId,
				token: this.dataInfo.userToken,
			};
			if (this.dataInfo.fid == 2205) {
				picturePay(params).then((res) => {
					const { data } = res;
					if (data.message === "paySuccess!") {
						this.payStatus = "paySuccess";
						this.$message.success("购买成功");
						this.centerDialogVisible = false;
					} else {
						this.$message.error("支付失败");
					}
				});
			} else{
				zhiPay(params).then((res) => {
					const { data } = res;
					if (data.message === "paySuccess!") {
						this.payStatus = "paySuccess";
						this.$message.success("购买成功");
						this.centerDialogVisible = false;
					} else {
						this.$message.error("支付失败");
					}
				});
			}
		},
  },
  mounted() {},
  computed: {},
};
</script>

<style scoped>
.noMoney {
  background: #bfbfbf !important;
}
.topUp {
  width: 1.4rem;
  height: 0.4rem;
  background: #6ea0fd;
  border-radius: 0.04rem;
  line-height: 0.4rem;
  text-align: center;
  color: #ffffff;
  font-size: 0.16rem;
  margin: 0 0.3rem;
  cursor: pointer;
}
.submit {
  width: 1.4rem;
  height: 0.4rem;
  background: #ff9e58;
  border-radius: 0.04rem;
  line-height: 0.4rem;
  text-align: center;
  color: #ffffff;
  font-size: 0.16rem;
  margin: 0 0.3rem;
  cursor: pointer;
}
.name {
  width: 0.8rem;
  display: inline-block;
  text-align: justify;
  text-align-last: justify;
}
.infoTitle .txt2 {
  color: #040404;
  font-size: 0.16rem;
}
.infoTitle .txt1 {
  margin-right: 0.1rem;
  color: #bfbfbf;
  font-size: 0.16rem;
  /* width: 1rem; */
  white-space: nowrap;
  flex-shrink: 0;
}
.infoTitle {
  padding: 0.25rem 0.2rem;
  display: flex;
  align-items: center;
  border-bottom: 1px dashed #d9d9d9;
}
.payInfo {
  width: 4.4rem;
  height: 1.14rem;
  background: url("../../assets/image/bg/payPop-bg.png") no-repeat;
  background-size: 100% 100%;
  margin-top: 0.2rem;
}
::v-deep .el-dialog__headerbtn {
  top: 0.15rem;
  right: 0.15rem;
  font-size: 0.16rem;
}
::v-deep .el-dialog__header {
  text-align: left;
  color: #040404;
  font-weight: bold;
  padding: 0.2rem 0.3rem;
  background: linear-gradient(180deg, #a4c3fc 0%, #f3f7ff 100%);
  height: 2.09rem;
}
::v-deep .el-dialog__title {
  font-size: 0.18rem;
}
</style>

