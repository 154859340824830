<template>
  <div>
    <el-dialog
      :visible.sync="centerDialogVisible"
      center
      :destroy-on-close="true"
      :show-close="false"
    >
      <div class="secretBox">
				<div class="decorateA"></div>
				<div class="decorateB"></div>
				<div class="absolute">
					<div class="head">
						<img src="../../assets/image/icon/close.png" alt="" @click="centerDialogVisible=false"/>
					</div>
					<div class="tips">
						<img src="../../assets/image/pop/tip.png" alt="" />
					</div>
					<p class="title">温馨提示</p>
					<p v-if="type == 1" class="info">
						{{msg}}
					</p>
					<div v-else-if="type == 2" class="warning">
						我们监测到您的账号<span class="red">存在恶意盗刷流量和爬取资料的行为</span>。现在将您的账号做<span class="red">封号</span>处理。如有疑义请
						<span class="link" @click="service">联系客服>></span>
					</div>
					<div v-else class="info">
						{{msg}}
					</div>
				</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { removeToken } from "@/utils/auth";
export default {
  name: "tooltipPop",
  data() {
    return {
			centerDialogVisible: false,
			type: '',
			msg: '您的账号当天下载次数已用完，请明天再下载'
    };
  },
  created() {},
	watch: {
		centerDialogVisible(val) {
			if (val == false && this.type == 2) {
				removeToken();
				location.reload()
			}
		}
	},
  methods: {
    show(type, msg) {
			this.type = type;
			this.msg = msg;
      this.centerDialogVisible = true;
    },
		service() {
			this.$servicePop().show()
		},
  },
};
</script>

<style scoped>
.secretBox {
  position: relative;
  margin: 0 auto;
  border-radius: 8px;
  width: 536px;
  height: 310px;
  background: #ffffff;
  opacity: 1;
	overflow: hidden;
}
.title {
	margin: 24px;
	text-align: center;
  font-size: 24px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: 500;
  letter-spacing: 0px;
  color: rgba(56, 56, 56, 1);
}
.info {
	margin: 0 auto;
	width: 376px;
  font-family: PingFang SC-Regular, PingFang SC;
	font-size: 18px;
	font-weight: 400;
	letter-spacing: 0px;
  text-align: center;
}
.warning {
	margin: 0 auto;
	width: 376px;
	font-family: PingFang SC-Regular, PingFang SC;
	font-size: 18px;
	font-weight: 400;
	letter-spacing: 0px;
	text-align: center;
}
.red {
	color: rgba(255, 60, 0, 1);
}
.link {
	color: rgba(74, 158, 255, 1);
	cursor: pointer;
}
.absolute {
	width: 100%;
	height: 100%;
  padding: 17px;
	position: absolute;
}
.decorateA {
	position: absolute;
	left: -104px;
	top: -65px;
	width: 256px;
	height: 256px;
	border-radius: 128px;
	z-index: 0;
	opacity: 0.8;
	background: linear-gradient(180deg, rgba(253, 251, 251, 1) 0%, rgba(235, 237, 238, 1) 100%);
	filter: blur(1px);
}
.decorateB {
	position: absolute;
	left: 468px;
	top: 103px;
	width: 178px;
	height: 178px;
	border-radius: 89px;
	opacity: 0.8;
	z-index: 0;
	background: linear-gradient(180deg, rgba(253, 251, 251, 1) 0%, rgba(235, 237, 238, 1) 100%);
	filter: blur(1px);
}
.tips {
  width: 44px;
  height: 44px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin: 0 auto;
}
.tips img{
  width: 44px;
  height: 44px;
}
::v-deep .el-input {
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 270px;
  height: 38px;
  background: #eeeeee;
  opacity: 1;
  border-radius: 4px;
}

::v-deep .el-input__inner {
  -webkit-appearance: none;
  background: #eeeeee;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}

::v-deep .el-button--primary:first-child {
  width: 120px;
  background-color: #ffffff;
  border-color: #d9d9d9;
  color: #999999;
}
::v-deep .el-button--primary:last-child {
  width: 120px;
  background-color: #ff9e58;
  border-color: #ff9e58;
}

::v-deep .el-dialog {
  background: rgba(0, 0, 0, 0);

  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0);

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0);
}
::v-deep .el-dialog__headerbtn {
    position: absolute;
    top: 69px;
    right: 175px;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    font-size: 16px;
    z-index: 1000;
}
.head{
  display: flex;
  flex-direction: row-reverse;
}
.head img{
  width: 16px;
  height: 16px;
}
</style>