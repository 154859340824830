/*
 * @FilePath: \zsw_new_edition\src\router\modules\personalCenter.js
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-23 14:04:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-01-20 15:30:54
 */
// 个人中心页路由列表
import Layout from '@/layout'

const uploadRouter = {
    path: '/personalCenter',
    component: Layout,
    redirect:'/personalCenter/index',
    name:'PersonalCenter',
    meta: { title: '个人中心'},
    children:[
        {
            path:'index',
            component: ()=> import('@/views/personalCenter/index'),
            name:'PersonalCenterIndex',
            meta: {title:'我的知设',activeMenu: '/personalCenter',icon:"&#xe74c;",is_verify:true},
        },
        {
            path:'myVip',
            component: ()=> import('@/views/personalCenter/myVip'),
            name:'MyVip',
            meta: {title:'我的VIP中心',activeMenu: '/personalCenter',icon:"&#xe74a;",is_verify:true},
        },
        {
            path:'myEarnings',
            component: ()=> import('@/views/personalCenter/myEarnings'),
            name:'MyEarnings',
            meta: {title:'我的收益',activeMenu: '/personalCenter',icon:"&#xe7cb;",is_verify:true},
        },
        {
            path:'myEarningsAddBank',
            component: ()=> import('@/views/personalCenter/myEarningsAddBank'),
            name:'MyEarnings',
            meta: {isHidde:true,title:'添加银行卡',activeMenu: '/personalCenter',is_verify:true},
        },
        {
            path:'myDownload',
            component: ()=> import('@/views/personalCenter/myDownload'),
            name:'MyDownload',
            meta: {title:'我的下载',activeMenu: '/personalCenter',icon:"&#xe74d;",is_verify:true},
        },
        {
            path:'myStudy',
            component: ()=> import('@/views/personalCenter/myStudy'),
            name:'MyStudy',
            meta: {title:'我的学习',activeMenu: '/personalCenter',icon:"&#xe6f8;",is_verify:true},
        },
        {
            path:'myCollect',
            component: ()=> import('@/views/personalCenter/myCollect'),
            name:'MyCollect',
            meta: {title:'我的收藏',activeMenu: '/personalCenter',icon:"&#xe7b3;",is_verify:true},
        },
        {
            path:'myCollectDetail',
            component: ()=> import('@/views/personalCenter/myCollectDetail'),
            name:'MyCollect',
            meta: {isHidde:true,title:'我的收藏详情',activeMenu: '/personalCenter',is_verify:true},
        },
        {
            path:'myOrder',
            component: ()=> import('@/views/personalCenter/myOrder'),
            name:'MyOrder',
            meta: {title:'我的订单',activeMenu: '/personalCenter',icon:"&#xe7b5;",is_verify:true},
        },
        {
            path:'myMessage',
            component: ()=> import('@/views/personalCenter/myMessage'),
            name:'MyMessage',
            meta: {title:'我的消息',activeMenu: '/personalCenter',icon:"&#xe745;",is_verify:true},
        },
        {
            path:'myHomePage',
            redirect: '/user/index',
            component: ()=> import('@/views/personalCenter/index'),
            name:'MyHomePage',
            meta: {title:'我的主页',activeMenu: '/personalCenter',icon:"&#xe7a7;",is_verify:true},
        },
        {
            path:'myPassWordSet',
            component: ()=> import('@/views/personalCenter/myPassWordSet'),
            name:'MyPassWordSet',
            meta: {title:'密码设置',activeMenu: '/personalCenter',icon:"&#xe75b;",is_verify:true},
        },
        {
            path:'myAccountSet',
            component: ()=> import('@/views/personalCenter/myAccountSet'),
            name:'MyAccountSet',
            meta: {isHidde:true, title:'账号设置',activeMenu: '/personalCenter',is_verify:true},
        }
    ]
}
export default uploadRouter