/*
 * @FilePath: \zhishe-pc\src\store\modules\sign.js
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-23 14:04:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2021-12-21 13:59:52
 */
const state = {
    joinData:{}
}

const mutations = {
    SET_JOIN_DATA: (state, data) => {
        state.joinData = data
    }
}
const actions = {
    //设置签约数据
    setJoinData({commit},data){
        commit('SET_JOIN_DATA',data)
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}