<!--
 * @FilePath: \zsw_new_edition\src\layout\componts\UploadSidebar.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2022-01-11 10:24:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-01-14 09:56:29
-->
<template>
  <div class="uploadSidebarList">
    <router-link
      :to="{ path: '/upload/uploadWork' }"
      class="uploadSidebarItem"
      :class="{ active: $route.name == 'UploadWork' }"
    >
      <i class="f16 mr10" v-if="$route.name != 'UploadWork'"
        ><img
          src="../../assets/image/icon/upload-black-icon.png"
          alt=""
          style="width: 0.16rem; height: 0.16rem"
      /></i>
      <i class="f16 mr10" v-else
        ><img
          src="../../assets/image/icon/upload-white-icon.png"
          alt=""
          style="width: 0.16rem; height: 0.16rem"
      /></i>
      <span class="f14 f_b">上传作品</span>
      <i class="iconfont f16" style="margin-left: auto">&#xe665;</i>
    </router-link>
    <router-link
      :to="{ path: '/upload/myUpload' }"
      class="uploadSidebarItem"
      :class="{ active: $route.name == 'MyUpload' }"
    >
      <i class="f16 mr10" v-if="$route.name != 'MyUpload'"
        ><img
          src="../../assets/image/icon/downloadSucces.png"
          alt=""
          style="width: 0.16rem; height: 0.16rem"
      /></i>
      <i class="f16 mr10" v-else
        ><img
          src="../../assets/image/icon/upload-success-icon.png"
          alt=""
          style="width: 0.16rem; height: 0.16rem"
      /></i>
      <span class="f14 f_b">我的上传</span>
      <i class="iconfont f16" style="margin-left: auto">&#xe665;</i>
    </router-link>
    <router-link v-if="$store.state.user.userInfo.joinState > 0"
            :to="{ path: '/upload/teacherList' }"
            class="uploadSidebarItem"
            :class="{ active: $route.name == 'TeacherList' }"
    >
      <i class="f16 mr10" v-if="$route.name != 'TeacherList'"
      ><i class="el-icon-user"
          style="width: 0.16rem; height: 0.16rem"
      /></i>
      <i class="f16 mr10" v-else
      ><i class="el-icon-user"
              style="width: 0.16rem; height: 0.16rem"
      /></i>
      <span class="f14 f_b">老师列表</span>
      <i class="iconfont f16" style="margin-left: auto">&#xe665;</i>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "UploadSidebar",
};
</script>

<style scoped>
.uploadSidebarItem.active {
  background: #f46600;
  color: #ffffff;
}
.uploadSidebarList {
  width: 2.4rem;
  background: #ffffff;
  border-radius: 0.04rem;
}
.uploadSidebarItem {
  cursor: pointer;
  color: #333333;
  display: flex;
  align-items: center;
  padding: 0.15rem 0.2rem 0.15rem 0.3rem;
  border-radius: 0.04rem;
}
</style>
