/*
 * @FilePath: \zhishe-pc\src\utils\filters.js
 * @Brief: 过滤器
 * @Version: 1.0
 * @Date: 2021-11-30 10:08:28
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2021-12-21 18:29:57
 */


//年月日时分秒转年月日
function formDate(param, format="yyyy-MM-dd HH:mm:ss 星期w") {
	if (!param) return ''
	const time = (param).toString()
	const length = time.length
	let newTime = time
	if (length < 13) {
		const remainingLength = 13 - length
		for (let i = 0; i < remainingLength; i++) {
			newTime += '0'
		}
	}
	let date = new Date(Number(newTime));
	function addZero(date) {
		if (date < 10) {
			return "0" + date;
		}
		return date;
	}
	let getTime = {
		yyyy: date.getFullYear(),
		yy: date.getFullYear() % 100,
		MM: addZero(date.getMonth() + 1),
		M: date.getMonth() + 1,
		dd: addZero(date.getDate()),
		d: date.getDate(),
		HH: addZero(date.getHours()),
		H: date.getHours(),
		hh: addZero(date.getHours() % 12),
		h: date.getHours() % 12,
		mm: addZero(date.getMinutes()),
		m: date.getMinutes(),
		ss: addZero(date.getSeconds()),
		s: date.getSeconds(),
		w: (function () {
			let a = ["日", "一", "二", "三", "四", "五", "六"];
			return a[date.getDay()];
		})(),
	};
	for (let i in getTime) {
		format = format.replace(i, getTime[i]);
	}
	return format;
}

//年月日时分秒转年月日
function formartDate(param) {
  let date = new Date(param);
  let Y = date.getFullYear() + "-";
  let M =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1) + "-"
      : date.getMonth() + 1 + "-";
  let D =
    date.getDate() < 10 ? "0" + date.getDate() + " " : date.getDate() + " ";
  return Y + M + D;
}

//平台名称
function platformName(str) {
  if (str == 1154) {
    return "公众号 & 小程序";
  }
  if (str == 1161) {
    return "bilibili平台";
  }
  if (str == 1165) {
    return "抖音平台";
  }
}

//过滤数量用w表示
function changeW(str) {
  if (str >= 10000) {
    let newval = str / 10000;
    return Number(newval).toFixed(2) + "w";
  }else{
    return str
  }
}
//截取两个价格的情况
function splitMoney(str){
  if(str){
    return str.split(',')[0]
  }else{
    return 0
  }
}

export default {
  formartDate,
  platformName,
  changeW,
  splitMoney,
	formDate,
};
