import { render, staticRenderFns } from "./codeForm.vue?vue&type=template&id=700d2b10&scoped=true&"
import script from "./codeForm.vue?vue&type=script&lang=js&"
export * from "./codeForm.vue?vue&type=script&lang=js&"
import style0 from "./codeForm.vue?vue&type=style&index=0&id=700d2b10&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "700d2b10",
  null
  
)

export default component.exports