/*
 * @FilePath: \zsw_pc_test\src\utils\request.js
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-23 14:04:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: Xxster 40750223+Xxster@users.noreply.github.com
 * @LastEditTime: 2024-07-24 15:22:39
 */
import axios from 'axios'

// create an axios instance
let Rurl = ''
if (process.env.NODE_ENV == 'development') {
    Rurl = '/v2api'
} else {
    Rurl = 'https://apidev.zhishew.com/v2'
    // Rurl = 'http://apitest.zhishew.com/v2'
}
const service = axios.create({
    // baseURL: 'https://apidev.zhishew.com/v2',
    // if (process.env.NODE_ENV == 'development'){}
    // baseURL: 'http://localhost:8080/v2api',
    baseURL: Rurl,
    timeout: 5000,
})
// response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data
        // if the custom code is not 20000, it is judged as an error.
        if (res.status != 200) {
            console.log('网络请求失败')
            // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
            if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
                console.log('注销')
            }
            return res
        } else {
            return res
        }
    },
    error => {
        console.log('err' + error) // for debug
        return Promise.reject(error)
    }
)
export default service
