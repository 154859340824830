<!--
 * @FilePath: \zsw_new_edition\src\components\navComeDown\ComeDownMiddle.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-25 14:21:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-01-21 09:40:28
-->
<!--课程下拉框中间部分组件-->
<template>
  <div class="contentBoxs" v-if="classList">
    <div v-for="(item, index) in classList" :key="index">
      <p v-if="item.title != '其他'">
        <router-link
          target="_blank"
          :to="{ path: link, query: { child1: item.id } }"
        >
          {{ item.title }}
        </router-link>
      </p>
      <p v-else>
          {{ item.title }}
      </p>
      <ul>
        <li v-for="(iitem, index) in item.child" :key="index">
          <router-link
           v-if="item.title != '其他'"
            target="_blank"
            :to="{ path: link, query: { child1: item.id, child2: iitem.id } }"
            class="liBox"
          >
            {{ iitem.title }}
          </router-link>
          <router-link
           v-else
            target="_blank"
            :to="{ path: link, query: { child1: iitem.id} }"
            class="liBox"
          >
            {{ iitem.title }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ComeDownMiddle",
  props: ["classList", "link"],
  created() {
    // min-width: 1400px();
  },
};
</script>

<style scoped>
.contentBoxs {
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
  display: flex;
  margin-left: 0.2rem;
}
.contentBoxs div {
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
  margin-right: 0.2rem;
  padding-bottom: 0.2rem;
}

.contentBoxs div p {
  height: 0.22rem;
  font-size: 0.16rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #040404;
  opacity: 1;
  margin-bottom: 0.1rem;
  cursor: pointer;
	text-align: center;
}

.contentBoxs div ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  writing-mode: vertical-lr;
}

.contentBoxs div li {
  height: 0.2rem;
  font-size: 0.14rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
  opacity: 1;
	margin: 0.1rem;
  writing-mode: horizontal-tb;
  cursor: pointer;
	text-align: center;
}
.liBox:hover {
  color: #f46600;
}
</style>