<!--
 * @FilePath: \zsw_new_edition\src\components\loginPop\codeForm.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2022-02-09 15:21:17
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-03-21 14:11:32
-->
<template>
  <div>
    <div class="formBox">
      <p class="formBoxTitle">短信验证码登录</p>
      <el-input
        v-model="username"
        placeholder="请输入手机号码"
        class="username"
      >
        <template slot="prefix"><p>账号</p></template>
      </el-input>
      <div class="codeBox">
        <el-input v-model="code" placeholder="请输入验证码"> </el-input>
        <div class="getCode" @click="getCode">{{ getTxt }}</div>
      </div>
      <div class="forget">
        <span @click="changeLoginForm('account')">账号密码登录</span>
        <!-- <span @click="changeLoginForm('forget')">忘记密码？</span> -->
      </div>
      <div class="btn" @click="submitForm()"><p>登录</p></div>
    </div>
    <div class="border"></div>
    <div class="other">
      <div @click="changeLoginForm('wx')">
        <img src="../../assets/image/login/wx.png" alt="" />
        <p>微信登录</p>
      </div>
      <div @click="changeLoginForm('register')">
        <img src="../../assets/image/login/telMan.png" alt="" />
        <p>手机号注册</p>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { getMessage, sendMessage } from "@/Api";
export default {
  name: "codeForm",
  data() {
    return {
      username: "",
      code: "",
      getTxt: "获取验证码",
      isGet: true,
      timer: null,
    };
  },
  methods: {
    changeLoginForm(type) {
      this.$emit("changeLoginForm", type);
    },
    submitForm() {
      if (!this.username) {
        this.$toast({ text: "手机号不能为空" });
        return;
      } else {
        const reg =
          /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
        if (!reg.test(this.username)) {
          this.$toast({ text: "手机号格式不正确" });
          return;
        }
      }
      if (!this.code) {
        this.$toast({ text: "验证码不能为空" });
        return;
      }
      const data = {
        username: this.username,
        messageCode: this.code,
        type: 6,
      };
      store.dispatch("user/codeLogin", data).then(() => {
        this.$emit("loginSucceed");
      });
    },
    //获取验证码
    getCode() {
      if (!this.username) {
        this.$toast({ text: "手机号不能为空" });
        return;
      } else {
        const reg =
          /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
        if (!reg.test(this.username)) {
          this.$toast({ text: "手机号格式不正确" });
          return;
        }
      }
      if (this.isGet) {
        getMessage(this.username).then((response) => {
          let auth = response.data.auth;
          let num = this.$randomWord(true, 8, 8);
          let newSendMsg = num + auth;
          sendMessage({ auth: newSendMsg, type: 6 }).then((res) => {
            if (res.status == 200 && res.data.error == 0) {
              this.$message.success("发送成功");
              this.isGet = false;
              const TIME_COUNT = 60;
              if (!this.timer) {
                this.count = TIME_COUNT;
                this.timer = setInterval(() => {
                  if (this.count > 0 && this.count <= TIME_COUNT) {
                    this.getTxt = "重新获取" + this.count;
                    this.count--;
                  } else {
                    this.getTxt = "重新获取";
                    this.isGet = true;
                    clearInterval(this.timer);
                    this.timer = null;
                  }
                }, 1000);
              }
            } else {
              this.$toast({ text: res.data.message });
            }
          });
        });
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
::v-deep input::-webkit-input-placeholder {
  font-size: 0.14rem;
}

::v-deep input::-moz-input-placeholder {
  font-size: 0.14rem;
}

::v-deep input::-ms-input-placeholder {
  font-size: 0.14rem;
}
.formBox {
  margin: 0.3rem 0.8rem 0.74rem 0.8rem;
}
.formBoxTitle {
  font-size: 0.2rem;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #040404;
  line-height: 0.28rem;
  text-align: center;
  margin-bottom: 0.3rem;
}
.username {
  margin-bottom: 0.1rem;
}
::v-deep .el-input__prefix {
  left: 5px;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-input__prefix p {
  font-size: 0.14rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #040404;
  line-height: 0.2rem;
  padding-right: 0.3rem;
  width: 0.74rem;
}
::v-deep .el-input--prefix .el-input__inner {
  padding-left: 0.74rem;
}
.forget {
  display: flex;
  justify-content: space-between;
  margin: 0.2rem 0 0.15rem 0;
}
.forget span {
  font-size: 0.12rem;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 0.17rem;
  cursor: pointer;
}
.forget span:first-child {
  font-size: 0.12rem;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 0.17rem;
  color: #f46600;
}
.btn {
  width: 2.87rem;
  height: 0.4rem;
  background: #ff842a;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn p {
  height: 0.2rem;
  font-size: 0.14rem;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ffffff;
  line-height: 0.2rem;
}
.border {
  width: 3.67rem;
  opacity: 1;
  border-bottom: 1px dashed #bfbfbf;
  margin: 0 auto;
  margin-bottom: 0.25rem;
}
.other {
  width: 2.36rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.7rem;
}
.other div {
  display: flex;
  cursor: pointer;
}
.other div img {
  width: 0.2rem;
  height: 0.2rem;
  margin-right: 0.06rem;
}
.other div p:hover{
  color: #F46600;
}
.other div p {
  font-size: 0.14rem;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #040404;
  line-height: 0.2rem;
}
.codeBox {
  display: flex;
  justify-content: space-between;
}
.getCode {
  width: 145px;
  height: 40px;
  background: #f7f6fb;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.14rem;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #040404;
  line-height: 0.2rem;
  cursor: pointer;
}
</style>