/*
 * @FilePath: \zhishe-pc\src\store\index.js
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-23 14:04:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2021-12-21 13:50:32
 */
import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import user from './modules/user'
import app from './modules/app'
import nav from './modules/nav'
import upload from './modules/uploadWork'
import sign from './modules/sign'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        user,
        app,
        upload,
        nav,
        sign
    },
    getters
})

export default store