<template>
  <div v-if="centerDialogVisible">
    <div class="v-modal" style="z-index: 2002;"></div>
      <div class="scheduleBox">
        <div class="footerBox">
					<div class="closeBox" @click="close">
						<img src="../../assets/image/pop/renewClose.png" alt="" />
					</div>
          <img src="../../assets/image/pop/about-bg.png" alt="" />
          <div class="desc">
            <p class="title">免费领会员</p>
            <p class="tips">仅需填写就职公司/在读院校（完整名称），审核通过即可获得1个月素材会员</p>
            <div class="form-box">
              <div class="input-box">
                <input
                  class="input" 
                  type="text"
                  v-model="aboutName" 
                  placeholder="请填写就职公司/在读学校"/>
              </div>
              <div class="input-box">
                <input
                  class="input" 
                  type="text"
                  v-model="wxh" 
                  placeholder="请填写你的微信号，便于我们发放奖励"/>
              </div>
              <div class="but" @click="submit">提交</div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { getUserAbout, addUserAbout } from "../../Api";
import { getToken } from "@/utils/auth";
export default {
  name: "index",
  data() {
    return {
      centerDialogVisible: false,
      userInfo:{},
      aboutName: '',
      wxh: '',
      fun: null,
      token: null,
    };
  },
  created() {
    this.token = getToken();
  },
  methods: {
    submit(){
      if(!this.aboutName.trim()) {
        this.$toast({ text: "就职公司/在读学校不能为空" });
        return
      }
      if(!this.wxh.trim()) {
        this.$toast({ text: "微信号不能为空" });
        return
      }
      const params = {
        name: this.aboutName,
        wxh: this.wxh,
        token: this.token,
      };
      addUserAbout(params).then((res) => {
        if (res.data&&res.data.error == 0) {
          this.$message.success("提交成功!");
        } else {
          this.$message.error(res.data.message);
        }
        this.close()
      });
    },
    show(fun) {
      let newTime = new Date().getTime()
      let userAboutTime = Number(localStorage.getItem("userAboutTime")) + 60*60*18*1000
      // 一天内已经弹出
      if (userAboutTime > newTime) {
        fun()
        return
      }
      // 判断登录
      if(!this.token) {
        fun()
        return
      }
      // 发送请求判断是否弹出
      getUserAbout(this.token).then((res) => {
        if (res.data&&res.data.error == 0) {
          this.fun = fun;
          this.centerDialogVisible = true;
          //将弹出时间存入缓存
          localStorage.setItem("userAboutTime", newTime);
          this.$nextTick(()=>{
            document.querySelector('body').style.overflow = 'hidden'
          })
        } else {
          fun()
        }
      }).catch((err) => {
        fun()
				console.log(err);
			});
    },
    //关闭回调
    close() {
      this.centerDialogVisible = false;
      this.$nextTick(()=>{
        document.querySelector('body').style.overflow = 'auto'
      })
      this.fun()
    },
  },
};
</script>

<style scoped>
.v-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: .5;
  background: #000;
}
.scheduleBox{
  width:100%;
  position: fixed;
  margin: auto;
  z-index: 2003;
  left: calc(50% - 3.75rem);
  top: calc(50% - 3.75rem)
}
.footerBox{
  width: 8rem;
  height: 8rem;
  position: relative;
}
.footerBox img{
  width:100%;
  height:100%;
  position: relative;
}
.closeBox{
	width: 100%;
	position: absolute;
	top: 0.25rem;
  z-index: 2003;
}
.closeBox img{
  width: 0.53rem;
  height: 1.18rem;
	display: block;
	margin: 0 auto;
  cursor: pointer;
}
.desc{
	width: 4rem;
  position: absolute;
  left: 2.06rem;
	top: 2.8rem;
  text-align: center;
  user-select: none;
}
.desc>.title{
  color: rgba(132, 66, 50, 1);
  font-size: 0.36rem;
  font-weight: 400;
}
.desc>.tips{
  margin-top: 0.08rem;
  color: rgba(132, 66, 50, 1);
  line-height: 0.29rem;
  font-size: 0.2rem;
  font-weight: 400;
}
.form-box {
  margin-top: 0.2rem;
}
.form-box>.input-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 0.5rem;
  margin-top: 0.13rem;
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(220, 223, 230, 1);
  padding: 0 0.16rem;
  font-size: 0.2rem;
}
.input-box>.input {
  flex: 1;
}
.input-box>.input::placeholder {
  color: #CCCCCC;
}
.form-box>.but {
  width: 4rem;
  height: 0.48rem;
  margin-top: 0.20rem;
  line-height: 0.5rem;
  background: rgba(203, 121, 49, 1);
  box-shadow:inset 0 0 1px 1px rgba(255, 255, 255, 0.127), 0 -1px 0 rgba(203, 121, 49, 1);
  border-radius: 6px;
  text-align: center;
  text-shadow: 0 0 2px rgba(115, 46, 29, 1);
  color: rgba(255, 238, 209, 1);
  cursor: pointer;
  font-size: 0.2rem;
}
</style>
