/*
 * @FilePath: \zsw_pc_test\src\components\aboutPop\index.js
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-12-16 16:21:49
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: Xxster 40750223+Xxster@users.noreply.github.com
 * @LastEditTime: 2024-07-23 17:17:15
 */
import Vue from 'vue';
import comment from './index.vue';

let confirmInstance;
const ConfirmConstructor = Vue.extend(comment);  // 使用extend方法创建类似Vue的构造函数

const initInstance = () => {
    confirmInstance = new ConfirmConstructor();
    document.body.appendChild(confirmInstance.$mount().$el);
    // 实例化后手动挂载，得到$el真实Dom，将其添加到body最后
}

export default options => { // options 为可配置选项
    if (!confirmInstance) {
        initInstance(); // 未挂载就进行挂载
    }
    Object.assign(confirmInstance, options); // 该操作可以修改confirmInstance,将新的配置data挂载到this下
    return confirmInstance
}

