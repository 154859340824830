import request from "@/utils/request";


//判断token是否过期
export function userCheckTokenState(token) {
  return request({
    url: "/user/login/checkTokenState",
    method: "get",
    params: { token },
  });
}

//账号登录
export function login(params) {
  return request({
    url: "/user/login/login",
    method: "post",
    params,
  });
}

// 验证码登录
export function smsLogin(params) {
  return request({
    url: "/user/login/smsLogin",
    method: "post",
    params,
  });
}

// 获取用户信息
export function getInfo(token) {
  return request({
    url: "/comms/Users/getUser",
    method: "get",
    params: { token },
  });
}

// 获取短信验证码
export function getMessage(getAuth) {
  return request({
    url: "/app/sms/getAuth",
    method: "get",
    params: { getAuth },
  });
}

// 发送验证码
export function sendMessage(params) {
  return request({
    url: "/app/sms/sendMessageNew",
    method: "post",
    params,
  });
}

// 检查账号是否已注册
export function isExistPhone(tel) {
  return request({
    url: "/user/Register/isExist",
    method: "get",
    params: { tel },
  });
}

// 用户注册
export function register(params) {
  return request({
    url: "/user/Register/registerTwo",
    method: "post",
    params,
  });
}

// 找回密码
export function resetPass(params) {
  return request({
    url: "/user/index/changePwd",
    method: "post",
    params,
  });
}

// 获取banner
export function getBanner(params) {
  return request({
    url: "/comms/Banner/getPicNav",
    method: "get",
    params,
  });
}

//获取首页热门视频
export function getHotCourse() {
  return request({
    url: "/web/index/originalCourse",
    method: "get",
  });
}

//获取首页热门课程
export function frontHotCoursePageData(type) {
  return request({
    url: "/web/Index/frontPageData",
    method: "get",
    params: { type }
  });
}
//获取首页热门数据
export function frontPageData(type) {
  return request({
    url: "/web/Index/frontPageDataNew",
    method: "get",
    params: { type }
  });
}

// 获取首页ps 热门类型
export function getHotPs(params) {
  return request({
    url: "comms/Banner/getPicNav",
    method: "get",
    params,
  });
}

// 获取首页方案文本 热门类型
export function getHotSchemeText(params) {
  return request({
    url: "comms/Banner/getPicNav",
    method: "get",
    params,
  });
}
//获取首页热门SU
// export function getHotCourse() {
//     return request({
//         url: '/web/index/originalCourse',
//         method: 'get'
//     })
// }

//获取首页热门素材
export function getHotMaterial() {
  return request({
    url: "/web/index/hotResource",
    method: "get",
  });
}

//获取导航（课程、素材、3d）
export function getMenu(id) {
  return request({
    url: "/web/CloudLibrary/getListNewTwo",
    method: "get",
    params: { id },
  });
}

//获取图库
export function getPhotoMenu(id) {
  return request({
    url: "/web/CloudLibrary/getListNewTwo",
    method: "get",
    params: { id, type: 1 },
  });
}

//获取课程列表
export function getCourse(params) {
  return request({
    url: "/web/CloudClass/getListByCategory",
    method: "get",
    params,
  });
}

//获取课程列表(新)
export function getCourseNew(params) {
  return request({
    url: "/web/CloudClass/getListByCategoryNew",
    method: "get",
    params,
  });
}

//获取素材列表(新)
export function getMaterialNew(params) {
  return request({
    url: "/web/CloudLibrary/getListByCategoryNew",
    method: "get",
    params,
  });
}

//获取素材列表
export function getMaterial(params) {
  return request({
    url: "/web/CloudLibrary/getListByCategoryNew",
    method: "get",
    params,
  });
}

//获取图库热门标签
export function getPhotoHotTag() {
  return request({
    url: "/web/IntentGallery/getHotTagsNew",
    method: "get",
  });
}

//获取图库列表
export function getPhoto(params) {
  return request({
    url: "/web/IntentGallery/getListBySearchNew",
    method: "get",
    params,
  });
}

//获获取我的个人信息
export function getMyInfo(token) {
  return request({
    url: "/user/index/edit",
    method: "get",
    params: { token },
  });
}

//获取个人收益信息
export function getEarningsInfo(token) {
  return request({
    url: "/user/Finance/getTopInfo",
    method: "get",
    params: { token },
  });
}

// 获取知币余额
export function getBalance(token) {
  return request({
    url: "/pay/ZhiCoin/getBalance",
    method: "get",
    params: { token },
  });
}

//修改个人信息
export function editMyInfo(params) {
  return request({
    url: "/user/index/edit",
    method: "post",
    params,
  });
}

//获取入驻状态
export function getCheckJoin(token) {
  return request({
    url: "/user/join/checkJoin",
    method: "get",
    params: { token },
  });
}

//上传头像
export function uploadUserAvatar(token, params) {
  return request({
    url: "/user/Lists/uploadPic?token=" + token,
    method: "post",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    params: params,
  });
}

//获取邮箱验证码
export function getEmailCode(params) {
  return request({
    url: "/user/index/sendEmail",
    method: "post",
    params,
  });
}

//绑定邮箱
export function userBindEmail(params) {
  return request({
    url: "/user/index/bindEmail",
    method: "post",
    params,
  });
}

//绑定邮箱
export function monthRating(token) {
  return request({
    url: "/comms/ZhicoinTask/monthRating",
    method: "get",
    params: { token },
  });
}

//获取收益记录
export function getEarningRecord(params) {
  return request({
    url: "/user/Finance/indexNew",
    method: "get",
    params,
  });
}

//获取提现记录
export function getDepositRecode(params) {
  return request({
    url: "/user/Finance/withdraw",
    method: "get",
    params,
  });
}
//提现
export function addWithdraw(params) {
  return request({
    url: "/user/Finance/addWithdraw",
    method: "post",
    params,
  });
}
//获取收益统计
export function getMyIncomeStatistic(params) {
  return request({
    url: "/user/Finance/getMyIncomeStatistic",
    method: "get",
    params,
  });
}

//获取绑定的银行卡列表
export function getBankList(token) {
  return request({
    url: "/user/Finance/bank",
    method: "get",
    params: { token },
  });
}

//获取课程播放记录列表
export function getPayRecordList(params) {
  return request({
    url: "/web/PersonalCenter/payRecord",
    method: "get",
    params,
  });
}

//发表评论
export function publishComment(params) {
  return request({
    url: "/web/CloudLibraryDetail/addComment",
    method: "post",
    params,
  });
}

//获取我的下载
// export function getMyDownload(params) {
//   return request({
//     url: "/user/Download/index",
//     method: "get",
//     params,
//   });
// }

//获取我的下载
export function getMyDownload(params) {
  return request({
    url: "/user/Download/indexNew",
    method: "get",
    params,
  });
}

//删除我的下载
export function deleteDownload(params) {
  return request({
    url: "/user/Download/del",
    method: "post",
    params,
  });
}

//获取收藏夹
export function getCollectFolder(params) {
  return request({
    url: "/user/Collect/getMyFolder",
    method: "get",
    params,
  });
}

//获取图库收藏夹
export function getGalleryFolderList(params) {
  return request({
    url: "/web/IntentGallery/getFolderList",
    method: "get",
    params,
  });
}

//添加银行卡发送验证码
export function sendMsg(params) {
  return request({
    url: "/user/Sms/bankBindSms",
    method: "post",
    params,
  });
}

//保存添加银行卡
export function addBankcard(params, token) {
  return request({
    url: "/user/Finance/bankAdd?token=" + token,
    method: "post",
    params,
  });
}

// 新增收藏夹
export function addCollectFolder(params) {
  return request({
    url: "/web/IntentGallery/addFolder",
    method: "post",
    params,
  });
}

//新增收藏夹 （图库）
export function addPhotoFolder(params) {
  return request({
    url: "/web/IntentGallery/addFolderNew",
    method: "post",
    params,
  });
}

//修改收藏夹
export function editFolder(params) {
  return request({
    url: "/user/Collect/editFolder",
    method: "post",
    params,
  });
}

//编辑图库收藏夹
export function editPhotoFolder(params) {
  return request({
    url: "/web/IntentGallery/editFolder",
    method: "post",
    params,
  });
}

// 获取收藏列表
export function getCollectList(params) {
  return request({
    url: "/user/Collect/getCollectListNew",
    method: "get",
    params,
  });
}

//获取图库收藏
export function getPhotoCollectList(params) {
  return request({
    url: "/web/IntentGallery/getCollectList",
    method: "get",
    params,
  });
}

//取消收藏
export function deleteCollect(params) {
  return request({
    url: "/user/Collect/del",
    method: "post",
    params,
  });
}

// 图库取消收藏
export function photoCollectDel(params) {
  return request({
    url: "/web/IntentGallery/del",
    method: "post",
    params,
  });
}

//购买课程、素材记录
export function admireList(params) {
  return request({
    url: "/web/PersonalCenter/admireList",
    method: "get",
    params,
  });
}

//获取我开通的vip
export function myVipList(params) {
  return request({
    url: "/web/vip/getMyVipList",
    method: "get",
    params,
  });
}

//获取开通的特惠vip
export function getSpecialVip(params) {
  return request({
    url: "/user/Vip/specialVipIndex",
    method: "post",
    params,
  });
}

//获取审核消息
export function getAuditMessage(params) {
  return request({
    url: "/user/Message/index",
    method: "post",
    params,
  });
}

//获取留言和点赞
export function getMessageAndGive(params) {
  return request({
    url: "/web/PersonalCenter/messageAndPrais",
    method: "get",
    params,
  });
}

//获取个人主页banner
export function userGetBanner(uid) {
  return request({
    url: "/web/PersonalCenter/userHomeBackNew",
    method: "get",
    params: { uid },
  });
}
//获取个人主页头部信息
export function portalPersonInfo(uid) {
  return request({
    url: "/web/GatewayPerson/getAuthorInfo",
    method: "get",
    params: { uid },
  });
}

//个人主页课程
export function portalCourseList(params) {
  return request({
    url: "/web/GatewayCompany/classList",
    method: "get",
    params,
  });
}

//获取个人主页素材
export function personMaterList(params) {
  return request({
    url: "/web/GatewayCompany/libraryList",
    method: "get",
    params,
  });
}

//获取个人主页图库
export function getPortGalleryList(params) {
  return request({
    url: "/web/GatewayPerson/getTagsResultNew",
    method: "get",
    params,
  });
}

//获取个人主页栏目
export function vipPortalColumn(params) {
  return request({
    url: "/web/nav/userCenterColumn",
    method: "get",
    params,
  });
}

//素材面包屑
export function cloudLibrarygetCateNav(id) {
  return request({
    url: "/web/CloudLibraryDetail/getCateNav",
    method: "get",
    params: { id },
  });
}

//获取素材详情
export function getMaterialDetail(params) {
  return request({
    url: "/web/CloudLibraryDetail/getDetailNew",
    method: "get",
    params,
  });
}

//获取作者信息
export function getAuthorInfo(params) {
  return request({
    url: "/web/CloudLibraryDetail/getAuthorInfoNew",
    method: "get",
    params
  });
}

//获取图库作者信息
export function getPhotoAuthorInfo(id) {
  return request({
    url: "/web/IntentGallery/getAuthor",
    method: "get",
    params: { id },
  });
}

//获取素材推荐
export function cloudLibrarygetRelatedInfo(params) {
  return request({
    url: "/web/SuModel/getRelatedSuNew",
    method: "get",
    params,
  });
}

//获取图库详情
export function galleryDetail(params) {
  return request({
    url: "/web/IntentGallery/getDetailNew",
    method: "get",
    params
  });
}

//获取作者其他图库
export function getAuthorOtherPics(uid) {
  return request({
    url: "/web/IntentGallery/getOtherPics",
    method: "get",
    params: { uid },
  });
}

//获取图库推荐
export function galleryRelateImg(id) {
  return request({
    url: "/web/IntentGallery/getRelatedPics",
    method: "get",
    params: { id },
  });
}

//获取课程面包屑
export function cloudCoursePosition(id) {
  return request({
    url: "/web/CloudClassDetail/getCateNav",
    method: "get",
    params: { id },
  });
}

export function cloudCourseDetail(id) {
  return request({
    url: "/web/CloudClassDetail/getDetail",
    method: "get",
    params: { id },
  });
}

//上传素材图片
export function uploadMaterialImg(token, params) {
  return request({
    url: "/comms/Release/uploadPics?token=" + token,
    method: "post",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    params: params,
  });
}

//获取七牛云token
export function getUpToken() {
  return request({
    url: "/comms/videos/uptoken",
    method: "get",
  });
}

//上传时获取栏目
export function getProNavList(params) {
  return request({
    url: "/user/Lists/getCateById",
    method: "get",
    params,
  });
}
//获取素材属性列表
export function getAttr(token) {
  return request({
    url: "/user/Lists/getAttr",
    method: "get",
    params: { token },
  });
}
//获取编辑素材
export function materialEdit(params) {
  return request({
    url: "/web/PersonalCenter/addLibraryNew",
    method: "get",
    params,
  });
}
//编辑素材
export function editMaterial(params) {
  return request({
    url: "/web/PersonalCenter/addLibraryNew",
    method: "post",
    params,
  });
}
//我的图库
export function myGalleryList(params) {
  return request({
    url: "/user/Lists/myGalleryList",
    method: "get",
    params,
  });
}

//添加素材
export function addMaterial(params) {
  return request({
    url: "/web/PersonalCenter/addLibraryNew",
    method: "post",
    params,
  });
}
//max回显
export function maxEdit(params) {
  return request({
    url: "/web/MaxModel/edit",
    method: "get",
    params,
  });
}
//编辑max
export function editMax(params) {
  return request({
    url: "/web/MaxModel/edit",
    method: "post",
    params,
  });
}
//方案文本回显
export function schemeTextEdit(params) {
  return request({
    url: "/web/SchemeText/edit",
    method: "get",
    params,
  });
}
//编辑方案文本
export function editSchemeText(params) {
  return request({
    url: "/web/SchemeText/edit",
    method: "post",
    data: params,
  });
}
//获取国家数据
export function getAllCountry(params) {
  return request({
    url: "/web/DesignCompetition/getAllCountry",
    method: "get",
    params,
  });
}
//su回显
export function suEdit(params) {
  return request({
    url: "/web/SuModel/edit",
    method: "get",
    params,
  });
}
//编辑su
export function editSu(params) {
  return request({
    url: "/web/SuModel/edit",
    method: "post",
    params,
  });
}
//上传课程封面
export function uploadCourseCover(token, params) {
  return request({
    url: "/user/Lists/uploadPicLimited?token=" + token,
    method: "post",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    params: params,
  });
}
//添加单视频
export function addVideo(params) {
  return request({
    url: "/comms/Videos/addNew",
    method: "post",
    data: params,
  });
}
//编辑单视频
export function editVideo(params) {
  return request({
    url: "/comms/Videos/editNew",
    method: "post",
    data: params,
  });
}
// 生成多视频的id
export function hasDrafts(token) {
  return request({
    url: "/comms/Videos/hasDrafts",
    method: "get",
    params: { token },
  });
}
//添加章
export function addChapter(params) {
  return request({
    url: "/comms/Videos/addChapter",
    method: "post",
    params,
  });
}
//修改章
export function editChapter(params) {
  return request({
    url: "/comms/Videos/editChapter",
    method: "post",
    params,
  });
}
//添加节
export function addSection(params) {
  return request({
    url: "/comms/Videos/addSectionNew",
    method: "post",
    params,
  });
}
//修改节
export function editSection(params) {
  return request({
    url: "/comms/Videos/editSectionNew",
    method: "post",
    params,
  });
}
//编辑章节课程
export function editVideoInfo(params) {
  return request({
    url: "/comms/Videos/editNew",
    method: "post",
    data: params,
  });
}
//获取su模型属性
export function getAttributes() {
  return request({
    url: "/web/SuModel/getAttributes",
    method: "get",
  });
}
// 获取su模型列表
export function getSuModeList(params) {
  return request({
    url: "/web/SuModel/getList",
    method: "get",
    params,
  });
}

// 获取3d模型属性
export function getMaxAttributes() {
  return request({
    url: "/web/MaxModel/getAttributes",
    method: "get",
  });
}
// 获取3d模型列表
export function getMaxModeList(params) {
  return request({
    url: "/web/MaxModel/getList",
    method: "get",
    params,
  });
}
//获取3d详情
export function getMaxDetail(params) {
  return request({
    url: "/web/MaxModel/getDetail",
    method: "get",
    params,
  });
}

// 获取方案文本属性
export function getSchemeTextAttributes() {
  return request({
    url: "/web/SchemeText/getAttributes",
    method: "get",
  });
}
// 获取方案文本列表
export function getSchemeTextList(params) {
  return request({
    url: "/web/SchemeText/getList",
    method: "get",
    params,
  });
}
//获取方案文本详情
export function getSchemeTextDetail(params) {
  return request({
    url: "/web/SchemeText/getDetail",
    method: "get",
    params,
  });
}
// 获取Ps列表
export function getPsList(params) {
  return request({
    url: "/web/Ps/getList",
    method: "get",
    params,
  });
}
//获取su详情
export function getSuDetail(params) {
  return request({
    url: "/web/SuModel/getDetail",
    method: "get",
    params,
  });
}
//获取PS详情
export function getPSDetail(params) {
  return request({
    url: "/web/Ps/getDetail",
    method: "get",
    params,
  });
}
// 下载素材或获取提取码判断是否需要购买
export function getMaterialCanDown(params) {
  return request({
    url: "/web/CloudLibraryDetail/canDown",
    method: "get",
    params,
  });
}
// 下载课程或获取提取码判断是否需要购买
export function getClassCanDown(params) {
  return request({
    url: "/web/CloudClassDetail/canDownNew",
    method: "get",
    params,
  });
}

// 下载素材或获取提取码判断是否可以下载
export function checkDownTimes(params) {
  return request({
    url: "/web/CloudLibraryDetail/checkDownTimes",
    method: "get",
    params,
  });
}
// 下载
export function getCloudLibraryDetailDown(params) {
  return request({
    url: "/web/CloudLibraryDetail/down",
    method: "get",
    params,
  });
}

//知币支付
export function zhiPay(params) {
  return request({
    url: "/pay/AliPayDo/zhiPayNew",
    method: "get",
    params,
  });
}
//知币充值
export function getZhicoinList() {
  return request({
    url: "/pay/ZhiCoin/getZhiListNew",
    method: "get",
  });
}
//su添加
export function addNewSu(params) {
  return request({
    url: "/web/SuModel/add",
    method: "post",
    params,
  });
}
//3d添加
export function addNewMax(params) {
  return request({
    url: "/web/MaxModel/add",
    method: "post",
    params,
  });
}
//方案文本添加
export function addNewSchemeText(params) {
  return request({
    url: "/web/SchemeText/add",
    method: "post",
    data: params,
  });
}
//获取热搜词
export function hotSearch(params) {
  return request({
    url: "web/index/hotWords",
    method: "get",
    params,
  });
}
//获取课程详情
export function getClassDetail(params) {
  return request({
    url: "/web/CloudClassDetail/getDetailNew",
    method: "get",
    params,
  });
}
//获取推荐课程
export function getRelatedInfo(id) {
  return request({
    url: "/web/CloudClassDetail/getRelatedInfoNew",
    method: "get",
    params: { id },
  });
}
//获取课程点赞
export function getClassZan(params) {
  return request({
    url: "/web/CloudClassDetail/zan",
    method: "get",
    params,
  });
}
//获取课程是否点赞
export function getClassIsZan(params) {
  return request({
    url: "/web/CloudClassDetail/isZan",
    method: "get",
    params,
  });
}
//获取课程收藏数
export function getClassCollect(params) {
  return request({
    url: "/web/CloudClassDetail/collect",
    method: "get",
    params,
  });
}
//获取课程是否收藏
export function getClassIsCollected(params) {
  return request({
    url: "/web/CloudClassDetail/isCollected",
    method: "get",
    params,
  });
}
//获取素材/su是否收藏
export function getCloudClassIsCollected(params) {
  return request({
    url: "web/CloudClassDetail/isCollected",
    method: "get",
    params,
  });
}
//获取图库是否收藏
export function getPhotoIsCollected(params) {
  return request({
    url: "web/IntentGallery/isCollect",
    method: "get",
    params,
  });
}
//收藏
export function doCollect(params) {
  return request({
    url: "/user/Collect/doCollect",
    method: "post",
    params,
  });
}
//收藏
export function doPhotoCollect(params) {
  return request({
    url: "/web/IntentGallery/doCollect",
    method: "post",
    params,
  });
}
//获取视频章节
export function getVideoChapterSections(params) {
  return request({
    url: "/comms/Videos/chapterSections",
    method: "get",
    params,
  });
}
//获取课程评论
export function getClassComment(params) {
  return request({
    url: "/web/CloudLibraryDetail/comment",
    method: "get",
    params,
  });
}
//获取收藏
export function getCollectView(params) {
  return request({
    url: "/user/Collect/collectView",
    method: "get",
    params,
  });
}
//获取首页导航
export function getNavigation(id) {
  return request({
    url: "/web/SuModel/getNavigation",
    method: "get",
    params: { id },
  });
}
//关于我们
export function getAbout(params) {
  return request({
    url: "/web/Article/getAbout",
    method: "get",
    params,
  });
}
//首页其他
export function getOther(cate) {
  return request({
    url: "/web/Index/siteRelated",
    method: "get",
    params: { cate },
  });
}
//提交意见反馈
export function addFeedback(params) {
  return request({
    url: "/user/feedback/add",
    method: "post",
    params,
  });
}
//判断课程是否需要购买
export function playVideo(params) {
  return request({
    url: "/web/CloudClassDetail/playVideo",
    method: "post",
    params,
  });
}
//添加图库
export function addPics(params) {
  return request({
    url: "/web/IntentGallery/addPics",
    method: "post",
    params,
  });
}
export function addPicsNew(params) {
  return request({
    url: "/web/IntentGallery/addPicsNew",
    method: "post",
    params,
  });
}
//获取签约状态
export function joinType(params) {
  return request({
    url: "/user/join/joinType",
    method: "get",
    params,
  });
}
//检查签约状态
export function checkJoin(params) {
  return request({
    url: "/user/join/checkJoin",
    method: "get",
    params,
  });
}
//签约
export function personJoin(params) {
  return request({
    url: "/user/join/personJoinNew",
    method: "post",
    params,
  });
}
//公司签约
export function companyJoin(params) {
  return request({
    url: "/user/join/companyJoinNew",
    method: "post",
    params,
  });
}
//所属行业
export function getProfession(params) {
  return request({
    url: "/user/join/getProfession",
    method: "get",
    params,
  });
}
//我的上传数据
export function workStatistic(params) {
  return request({
    url: "/user/Lists/workStatistic",
    method: "get",
    params,
  });
}
//我的上传作品
export function getWorkList(params) {
  return request({
    url: "/user/Lists/indexNew",
    method: "get",
    params,
  });
}
//下架我的上传
export function offShelf(params) {
  return request({
    url: "/user/Lists/offShelf",
    method: "post",
    params,
  });
}
//获取关注
export function getFocus(params) {
  return request({
    url: "/user/Focus/focus",
    method: "get",
    params,
  });
}
//关注
export function focus(params) {
  return request({
    url: "/user/Focus/focus",
    method: "post",
    params,
  });
}
//取消关注
export function cancel(params) {
  return request({
    url: "/user/Focus/cancel",
    method: "post",
    params,
  });
}
//删除我的图库
export function delPhoto(params) {
  return request({
    url: "/user/Lists/galleryDelete",
    method: "post",
    params,
  });
}
//编辑课程回显
export function classEdit(params) {
  return request({
    url: "/comms/Videos/editInfoNew",
    method: "get",
    params,
  });
}
//删除章节
export function delSection(params) {
  return request({
    url: "/comms/Videos/delSection",
    method: "post",
    params,
  });
}
//获取用户的上传数据统计
export function getCateSum(params) {
  return request({
    url: "/web/CloudLibrary/getCateSum",
    method: "get",
    params,
  });
}
//获取用户的上传数据分类统计
export function getGatewayCateAndNum(params) {
  return request({
    url: "/web/CloudLibrary/getGatewayCateAndNum",
    method: "get",
    params,
  });
}
//记录用户的观看时长
export function watchRecord(params) {
  return request({
    url: "/user/Lists/watchRecord",
    method: "post",
    params,
  });
}
//学习记录列表
export function myWatchRecord(params) {
  return request({
    url: "/user/Lists/myWatchRecord",
    method: "get",
    params,
  });
}
//删除学习记录
export function delWatchRecord(params) {
  return request({
    url: "/user/Lists/delWatchRecord",
    method: "post",
    params,
  });
}
//公告
export function letterList(params) {
  return request({
    url: "user/Message/letter",
    method: "get",
    params,
  });
}
//清空公告
export function setMsgView(params) {
  return request({
    url: "/user/Message/setMsgView",
    method: "post",
    params,
  });
}
//删除收藏夹
export function delfolder(params) {
  return request({
    url: "/user/collect/delfolder",
    method: "post",
    params,
  });
}
//收藏夹
export function moveAll(params) {
  return request({
    url: "/user/collect/moveAll",
    method: "post",
    params,
  });
}
//收藏夹
export function incomeRankList(params) {
  return request({
    url: "/web/Index/incomeRankList",
    method: "get",
    params,
  });
}
//删除小节
export function delChapter(params) {
  return request({
    url: "/comms/Videos/delChapter",
    method: "post",
    params,
  });
}
//获取图库面包屑
export function getPhotoCate(params) {
  return request({
    url: "/web/IntentGallery/getCateNew",
    method: "get",
    params,
  });
}
//获取签到列表
export function weekSignIn(token) {
  return request({
    url: "/comms/ZhicoinTask/weekSignIn",
    method: "get",
    params: { token },
  });
}
//签到
export function signInNew(token) {
  return request({
    url: "/comms/ZhicoinTask/signInNew",
    method: "post",
    params: { token },
  });
}
//我的会员中心
export function myVipCenter(token) {
  return request({
    url: "/web/Vip/myVipCenter",
    method: "post",
    params: { token },
  });
}
//图库分类
export function getCateThumbnail(params) {
  return request({
    url: "/web/IntentGallery/getCateThumbnail",
    method: "get",
    params,
  });
}
//最近查看图库
export function recentView(token) {
  return request({
    url: "/web/IntentGallery/recentView",
    method: "get",
    params: { token },
  });
}
//判断用户是否是vip
export function userIsVip(token) {
  return request({
    url: "/web/Vip/userIsVip",
    method: "get",
    params: { token },
  });
}
//VIP页面数据
export function webDataStatistic(params) {
  return request({
    url: "/web/Index/webDataStatistic",
    method: "get",
    params,
  });
}
//VIP页面数据
export function vipAuthor(params) {
  return request({
    url: "/web/Vip/vipAuthor",
    method: "get",
    params,
  });
}
//公告
export function latestAnnouncement(params) {
  return request({
    url: "/web/Index/latestAnnouncementNew",
    method: "get",
    params,
  });
}
//支付结果
export function haveRecharge(params) {
  return request({
    url: "/pay/AliPay/haveRecharge",
    method: "get",
    params,
  });
}
//是否开通vip
export function isOpenVipNew(params) {
  return request({
    url: "/web/Vip/isOpenVipNew",
    method: "get",
    params,
  });
}
//个人中心栏目列表
export function columnListNew(token) {
  return request({
    url: "/user/ColumnAuth/columnListNew",
    method: "get",
    params: { token },
  });
}
//获取密码
export function getSecret(token) {
  return request({
    url: "/user/columnAuth/getSecret",
    method: "get",
    params: { token },
  });
}
//修改密码
export function updateSecret(params) {
  return request({
    url: "/user/columnAuth/updateSecret",
    method: "post",
    params
  });
}
//设置栏目加密
export function updateColumnAuth(params) {
  return request({
    url: "/user/columnAuth/updateColumnAuth",
    method: "post",
    params
  });
}
//验证栏目加密
export function checkAuth(params) {
  return request({
    url: "/user/columnAuth/checkAuth",
    method: "post",
    params
  });
}
//验证栏目加密验证码
export function checkMessageCode(params) {
  return request({
    url: "/user/columnAuth/checkMessageCode",
    method: "post",
    params
  });
}
//导航栏下拉推荐
export function navigationBar(type) {
  return request({
    url: "/web/Index/navigationBar",
    method: "post",
    params: { type }
  });
}
//检查电话号码是否注册
export function checkTel(tel) {
  return request({
    url: "/web/Index/checkTel",
    method: "get",
    params: { tel }
  });
}
//解绑
export function unbind(params) {
  return request({
    url: "/user/index/unbind",
    method: "get",
    params
  });
}
//绑定
export function bindUsername(params) {
  return request({
    url: "/user/login/bindNew",
    method: "post",
    params
  });
}
//绑定
export function bindUsernameRegister(params) {
  return request({
    url: "/user/Register/bindNew",
    method: "post",
    params
  });
}
//课程下载资源
export function downloadClass(params) {
  return request({
    url: "/web/CloudLibraryDetail/downNew",
    method: "get",
    params
  });
}
//判断是否是vip作者
export function isVipAuthor(token) {
  return request({
    url: "/web/Vip/isVipAuthor",
    method: "get",
    params: { token }
  });
}
//我的解锁
export function disCountList(params) {
  return request({
    url: "/user/Lists/disCountList",
    method: "get",
    params
  });
}
//上传作品排行榜
export function uploadRank() {
  return request({
    url: "/web/Index/uploadRank",
    method: "get",
  });
}

//收益排行榜新
export function incomeRankListNew2() {
  return request({
    url: "web/Index/incomeList",
    method: "get",
  });
}
//收益排行榜
export function incomeRankListNew() {
  return request({
    url: "/web/Index/incomeRankListNew",
    method: "get",
  });
}
//学生认证
export function studentCertification(data) {
  return request({
    url: "/user/index/studentCertification",
    method: "post",
    data: data
  });
}
//登录二维码
export function loginQrCode(params) {
  return request({
    url: "/user/WeChatLogin/qrCode",
    method: "get",
    params
  });
}
//判断是否关注
export function checkIfSubscribe(params) {
  return request({
    url: "/user/WeChatLogin/checkIfSubscribe",
    method: "get",
    params
  });
}
//扫码登录
export function weChatLogin(data) {
  return request({
    url: "/user/WeChatLogin/login",
    method: "post",
    params: data
  });
}
//绑定时判断是否关注
export function checkIfBind(data) {
  return request({
    url: "/user/WeChatLogin/checkIfBind",
    method: "post",
    params: data
  });
}
//微信解绑
export function wxUnBind(data) {
  return request({
    url: "/user/WeChatLogin/unBindWechat",
    method: "post",
    params: data
  });
}
//绑定手机号
export function bindMobile(data) {
  return request({
    url: "/user/WeChatLogin/bindMobile",
    method: "post",
    params: data
  });
}
//手机号解绑
export function telUnBind(data) {
  return request({
    url: "/user/WeChatLogin/unBindMobile",
    method: "post",
    params: data
  });
}
//获取PS属性
export function getPsAttributes(params) {
  return request({
    url: "/web/Ps/getAttributes",
    method: "get",
    params
  });
}
//Ps回显
export function psEdit(params) {
  return request({
    url: "/web/Ps/edit",
    method: "get",
    params
  });
}
//Ps回显
export function editPs(data) {
  return request({
    url: "/web/Ps/edit",
    method: "post",
    data: data
  });
}
//添加PS素材
export function addPs(data) {
  return request({
    url: "/web/Ps/add",
    method: "post",
    params: data
  });
}
//获取vip价格
export function getVipPrice(params) {
  return request({
    url: "/web/Vip/getPriceNew",
    method: "get",
    params
  });
}

// 免费软件常用软件
export function getCommonSoftware(params) {
  return request({
    url: "/web/Software/getCommonSoftware",
    method: "get",
    params
  });
}

// 免费软件本周排行榜
export function getWeekRanking(params) {
  return request({
    url: "/web/Software/getWeekRanking",
    method: "get",
    params
  });
}

// 免费软件所有分类
export function getAllCate(params) {
  return request({
    url: "/web/Software/getAllCate",
    method: "get",
    params
  });
}

// 搜索关键词跳转免费软件列表页
export function getList(data) {
  return request({
    url: "/web/Software/getList",
    method: "post",
    params: data
  });
}

// id跳转免费软件列表页
export function getListById(params) {
  return request({
    url: "/web/Software/getList",
    method: "get",
    params
  });
}

// 获取软件详情
export function getDetail(params) {
  return request({
    url: "/web/Software/getDetail",
    method: "get",
    params
  });
}

// 获取软件分类
export function getSoftwareCateById(params) {
  return request({
    url: "/web/Software/getAllCate",
    method: "get",
    params
  });
}

// 获取系统属性
export function getSoftWareAttributes(params) {
  return request({
    url: "/web/Software/getAttributes",
    method: "get",
    params
  });
}

// 发布软件
export function addSoftware(data) {
  return request({
    url: "/web/Software/add",
    method: "post",
    params: data
  });
}

// 获取我的订单
export function getOrderList(params) {
  return request({
    url: "/web/Software/orderList",
    method: "get",
    params
  });
}

//查询远程支付结果
export function haveRechargeSoftware(params) {
  return request({
    url: "/pay/AliPay/haveRechargeSoftware",
    method: "get",
    params,
  });
}

// 获取首页教师
export function frontPageTeacher(params) {
  return request({
    url: "/web/Index/frontPageTeacher",
    method: "get",
    params,
  });
}

// 关注老师
export function subscribe(data) {
  return request({
    url: "/web/ClassTeacher/subscribe",
    method: "post",
    params: data
  });
}

// 取消关注老师
export function cancel_subscribe(data) {
  return request({
    url: "/web/ClassTeacher/cancel_subscribe",
    method: "post",
    params: data
  });
}

// 获取课程类型页轮播
export function classBanner(params) {
  return request({
    url: "/web/CloudClass/classBanner",
    method: "get",
    params,
  });
}

// 根据分类获取四个热门课程
export function getCateHotClass(params) {
  return request({
    url: "/web/CloudClass/getCateHotClass",
    method: "get",
    params,
  });
}

// 老师和课程信息
export function teacherOverview(params) {
  return request({
    url: "/web/ClassTeacher/teacherOverview",
    method: "get",
    params,
  });
}

// 课程详情老师信息
export function teacherInfo(params) {
  return request({
    url: "/web/ClassTeacher/teacherInfo",
    method: "get",
    params,
  });
}

// 老师主页
export function teacherIndex(params) {
  return request({
    url: "/web/ClassTeacher/index",
    method: "get",
    params,
  });
}

// 获取老师推荐
export function classTeacherList(params) {
  return request({
    url: "/web/ClassTeacher/classTeacherList",
    method: "get",
    params,
  });
}

// 获取老师列表
export function getTeacherList(params) {
  return request({
    url: "/web/ClassTeacher/getTeacherList",
    method: "get",
    params,
  });
}

// 添加老师
export function addTeacher(params) {
  return request({
    url: "/web/ClassTeacher/add",
    method: "get",
    params,
  });
}

// 修改老师
export function editTeacher(data) {
  return request({
    url: "/web/ClassTeacher/edit",
    method: "post",
    params: data
  });
}

// 删除老师
export function delTeacher(data) {
  return request({
    url: "/web/ClassTeacher/del",
    method: "post",
    params: data
  });
}

// 获取课程属性
export function getClassAttr(data) {
  return request({
    url: "/web/CloudClass/getClassAttr",
    method: "get",
    data
  });
}

// 首页常用软件
export function frontPageSoftware(params) {
  return request({
    url: "/web/Software/getCommonSoftware",
    method: "get",
    params
  });
}
// 首页销售榜单
export function frontUploadRankList(params) {
  return request({
    url: "/web/Index/uploadRankList",
    method: "get",
    params
  });
}

// 栏目搜索关键词
export function getRecommendHotWords(params) {
  return request({
    url: "/web/Index/recommendHotWords",
    method: "get",
    params,
  });
}

// 获取 专享课程 和 专享素材
export function getFidSumAndPrice(params) {
  return request({
    url: "/web/Vip/getFidSumAndPrice",
    method: "get",
    params,
  });
}

// 获取
export function getRecommendList(params) {
  return request({
    url: "/web/Software/getRecommendList",
    method: "get",
    params,
  });
}

// 获取详情底部推荐
export function getRelatedMaterial(params) {
  return request({
    url: "web/SuModel/getRelatedMaterial",
    method: "get",
    params,
  });
}



/* 竞赛 */
// 添加竞赛
export function DesignCompetitionAdd(params) {
  return request({
    url: "/web/DesignCompetition/add",
    method: "post",
    data: params
  });
}
// 获取竞赛列表
export function DesignCompetitionGetList(params) {
  return request({
    url: "/web/DesignCompetition/getList",
    method: "get",
    params,
  });
}
// 删除竞赛
export function DesignCompetitionDel(params) {
  return request({
    url: "/web/DesignCompetition/del",
    method: "post",
    params: params
  });
}
// 删除竞赛详情
export function DesignCompetitionDelSection(params) {
  return request({
    url: "/web/DesignCompetition/delSection",
    method: "get",
    params,
  });
}
// 获取竞赛详情
export function DesignCompetitionGetDetail(params) {
  return request({
    url: "/web/DesignCompetition/getDetail",
    method: "get",
    params,
  });
}
// 获取编辑竞赛（获取详情）
export function DesignCompetitionEditDetail(params) {
  return request({
    url: "/web/DesignCompetition/edit",
    method: "get",
    params,
  });
}
// 编辑竞赛（更新）
export function DesignCompetitionEdit(params) {
  return request({
    url: "/web/DesignCompetition/edit",
    method: "post",
    data: params
  });
}

//获取设计竞赛侧边广告
export function getDesignMenu(id) {
  return request({
    url: "/web/CloudLibrary/getListNewTwo",
    method: "get",
    params: { id },
  });
}

// 编辑设计竞赛联系方式
export function DesignCompetitionEditContactDetail(params) {
  return request({
    url: "/web/DesignCompetition/editContactDetail",
    method: "post",
    data: params
  });
}

// 获取设计竞赛联系方式
export function DesignCompetitionGetContactDetail(params) {
  return request({
    url: "/web/DesignCompetition/getContactDetail",
    method: "get",
    params,
  });
}

// 获取设计竞赛权限
export function DesignCompetitionCheckPermissions(params) {
  return request({
    url: "/web/DesignCompetition/checkPermissions",
    method: "get",
    params,
  });
}

// 转存上传图片
export function uploadImg(token, params) {
  return request({
    url: "/web/DesignCompetition/uploadPics?token=" + token,
    method: "post",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: params,
  });
}

/* 贴图 */
// 添加贴图
export function pictureAdd(params) {
  return request({
    url: "/web/Picture/add",
    method: "post",
    data: params
  });
}
// 新增时获取专辑列表
export function pictureGetMyAlbum(params) {
  return request({
    url: "/web/Picture/getMyAlbum",
    method: "get",
    params,
  });
}
// 编辑时获取详情
export function pictureGetEditInfo(params) {
  return request({
    url: "/web/Picture/getEditInfo",
    method: "get",
    params,
  });
}
// 获取贴图列表
export function pictureGetList(params) {
  return request({
    url: "/web/Picture/getList",
    method: "get",
    params,
  });
}
// 获取贴图详情
export function pictureGetDetail(params) {
  return request({
    url: "/web/Picture/getDetail",
    method: "get",
    params,
  });
}
// 专辑详情列表
export function pictureGetAlbumPicList(params) {
  return request({
    url: "/web/Picture/getAlbumPicList",
    method: "get",
    params,
  });
}
// 获取贴图banner
export function pictureGetBanner(params) {
  return request({
    url: "/web/Picture/getBanner",
    method: "get",
    params,
  });
}
// 获取详情底部推荐
export function getPictureRelatedPic(params) {
  return request({
    url: "/web/Picture/relatedPic",
    method: "get",
    params,
  });
}
// 分类图标
export function getPictureCateList(params) {
  return request({
    url: "/web/Picture/cateList",
    method: "get",
    params,
  });
}
//获取分类导航
export function getPictureCateByFid(id) {
  return request({
    url: "/web/Picture/getCateByFid",
    method: "get",
    params: { id },
  });
}

//获取面包屑
export function getPictureCateNav(params) {
  return request({
    url: "/web/CloudLibraryDetail/getCateNav",
    method: "get",
    params,
  });
}

// 贴图下载/获取提取码判断是否需要购买
export function getPictureCanDown(params) {
  return request({
    url: "/web/Picture/canDown",
    method: "get",
    params,
  });
}

// 贴图知币支付
export function picturePay(params) {
  return request({
    url: "/pay/AliPayDo/picturePay",
    method: "get",
    params,
  });
}



/* 图库 */
// 添加图库
export function photoAdd(params) {
  return request({
    url: "/web/Photo/add",
    method: "post",
    data: params
  });
}
// 编辑时获取详情
export function photoGetEditInfo(params) {
  return request({
    url: "/web/Photo/getEditInfo",
    method: "get",
    params,
  });
}
// 获取图库列表
export function photoGetList(params) {
  return request({
    url: "/web/Photo/getList",
    method: "get",
    params,
  });
}
// 获取图库详情
export function photoGetDetail(params) {
  return request({
    url: "/web/Photo/getDetail",
    method: "get",
    params,
  });
}
// 专辑详情列表
export function photoGetAlbumPicList(params) {
  return request({
    url: "/web/Photo/getAlbumPicList",
    method: "get",
    params,
  });
}
// 获取详情右侧专辑推荐
export function getPhotoPicAlbumRelated(params) {
  return request({
    url: "/web/Photo/picAlbumRelated",
    method: "get",
    params,
  });
}
// 获取详情底部推荐
export function getPhotoRelatedPic(params) {
  return request({
    url: "/web/Photo/relatedPic",
    method: "get",
    params,
  });
}
// 分类图标
export function getPhotoCateList(params) {
  return request({
    url: "/web/Photo/cateList",
    method: "get",
    params,
  });
}

// 获取是否可以分享
export function getIsShareUser(params) {
  return request({
    url: "web/Index/isShareUser",
    method: "get",
    params,
  });
}
// 获取分享链接
export function getShareLink(params) {
  return request({
    url: "web/Index/getShareLink",
    method: "get",
    params,
  });
}

//获取是否弹窗
export function getUserAbout(token) {
  return request({
    url: "/user/about/getAbout",
    method: "get",
    params: { token },
  });
}

// 添加用户就职公司
export function addUserAbout(data) {
  return request({
    url: "/user/about/addAbout",
    method: "post",
    params: data
  });
}
