/*
 * @FilePath: \zhishe-pc\src\store\modules\nav.js
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-12-06 09:57:08
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2021-12-06 10:12:47
 */
import { getBanner } from "@/Api";

const getDefaultState = () => {
  return {
    courseBanner: {},
  };
};
const state = getDefaultState();

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_COURSE_BANNER: (state, courseBanner) => {
    state.courseBanner = courseBanner;
  },
};
const actions = {
  //获取课程banner
  getCourseBanner({ commit }) {
    return new Promise((resolve, reject) => {
      getBanner({ position: 1054 })
        .then((res) => {
          commit("SET_COURSE_BANNER", res.data[0]);
          resolve();
        })
        .catch((error) => {
          console.log("错误");
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
