<template>
  <div v-if="centerDialogVisible && userInfo">
    <div class="v-modal" style="z-index: 2002;"></div>
      <div class="scheduleBox" v-if="userInfo">
        <div class="footerBox">
					<div class="closeBox" @click="close">
						<img src="../../assets/image/pop/renewClose.png" alt="" />
					</div>
          <img src="../../assets/image/pop/renew.png" alt="" />
          <div class="desc">
            <p v-show="userInfo.is_vip.class.is_vip && userInfo.is_vip.class.remain_day <= 7">您的课程会员仅剩{{userInfo.is_vip.class.remain_day}}天</p>
            <p v-show="userInfo.is_vip.library.is_vip &&userInfo.is_vip.library.remain_day <= 7">您的素材会员仅剩{{userInfo.is_vip.library.remain_day}}天</p >
            <p>为了避免到期后给您使用带来的不便</p>
            <p> 请立即续费，<span>续费{{userInfo.is_student == 1 ? 8 : 9}}折</span></p>
          </div>
					<div class="bottomBtn">
						<div class="renewBtn"  @click="toVip">立即续费</div>
					</div>
        </div>
      </div>
    </div>
</template>

<script>
import router from "../../router";
export default {
  name: "index",
  data() {
    return {
      centerDialogVisible: false,
      userInfo:{}
    };
  },
  methods: {
    toVip(){
      this.close()
      router.push({'path':'/vip/course'})
    },
    show(content) {
      this.userInfo = content;
      console.log(this.userInfo)
      this.centerDialogVisible = true;
      this.$nextTick(()=>{
        document.querySelector('body').style.overflow = 'hidden'
      })
    },
    //关闭回调
    close() {
      this.centerDialogVisible = false;
      this.$nextTick(()=>{
        document.querySelector('body').style.overflow = 'auto'
      })
    },
  },
};
</script>

<style scoped>
.v-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: .5;
  background: #000;
}
.scheduleBox{
  width:100%;
  position: fixed;
  margin: auto;
  z-index: 2003;
  left: calc(50% - 5rem);
  top: calc(50% - 5rem)
}
.footerBox{
  width: 10rem;
  height: 10rem;
  position: relative;
}
.footerBox img{
  width:100%;
  height:100%;
  position: relative;
}
.closeBox{
	width: 100%;
	position: absolute;
	top: 0.97rem;
  z-index: 2003;
}
.closeBox img{
  width:0.65rem;
  height:1.47rem;
	display: block;
	margin: 0 auto;
  cursor: pointer;
}
.desc{
	width: 100%;
  position: absolute;
	top: 4.28rem;
  text-align: center;
}
.desc>p:first-child{
  color: rgba(132, 66, 50, 1);
  font-size: 0.38rem;
  font-weight: 400;
}
.desc>p:nth-child(2){
  color: rgba(132, 66, 50, 1);
  font-size: 0.38rem;
  font-weight: 400;
}
.desc>p:nth-child(3){
  margin-top: 0.24rem;
  color: rgba(132, 66, 50, 1);
  font-size: 0.18rem;
  font-weight: 500;
}
.desc>p:nth-child(4){
  color: rgba(132, 66, 50, 1);
  font-size: 0.18rem;
  font-weight: 500;
}
.desc>p:nth-child(4)>span{
  color: rgba(214, 74, 39, 1);
  font-size: 0.24rem;
  font-weight: 500;
}
.bottomBtn {
	width: 100%;
	position: absolute;
	bottom: 2.7rem;
}
 .renewBtn{
		width: 2rem;
		height: 0.6rem;
		line-height: 0.6rem;
		background: rgba(203, 121, 49, 1);
		box-shadow:inset 0 0 2px 1px rgba(255, 255, 255, 0.35), 0 -2px 0 rgba(203, 121, 49, 1);
		border-radius: 30px;
		margin: auto;
		text-align: center;
		text-shadow: 0 0 2px rgba(115, 46, 29, 1);
		color: rgba(255, 238, 209, 1);
		font-size: 0.24rem;
		margin-top: 0.28rem;
		cursor: pointer;
 }
/* ::v-deep .el-dialog__headerbtn{
  top: 0.15rem;
  right: 0.15rem;
  font-size: 0.16rem;
}
::v-deep .el-dialog__header{
  text-align: left;
  color: #040404;
  font-weight: bold;
  padding: 0.2rem 0.3rem;
  background: linear-gradient(180deg, #A4C3FC 0%, #F3F7FF 100%);
  height: 2.09rem;
}
::v-deep .el-dialog__title{
  font-size: 0.18rem;
} */
</style>