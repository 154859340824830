import router from './router'
import NProgress from 'nprogress'
import store from './store'
import 'nprogress/nprogress.css'
NProgress.configure({ showSpinner: false })
router.beforeEach(async(to, from, next) => {
    NProgress.start()
    if(to.meta.is_verify){
        router.app.$login({isRouter:true,to:to,form:from,next:next})
        NProgress.done()
    }else{
        const hasGetUserInfo = store.getters.userInfo
        if(hasGetUserInfo){
            next()
        }else{
            try {
                await store.dispatch('user/getInfo')
                next()
                NProgress.done()
            } catch (error) {
                await store.dispatch('user/resetToken')
                next()
                NProgress.done()
            }
        }
    }

})
router.afterEach(() => {
    NProgress.done()
})