<template>
  <div>
    <el-dialog
      :visible.sync="centerDialogVisible"
      center
      :destroy-on-close="true"
      :show-close="false"
    >
      <div class="secretBox">
        <div class="head">
          <p>绑定微信</p>
          <img src="../../assets/image/icon/close.png" alt="" @click="centerDialogVisible=false"/>
        </div>
        <div class="qrcode">
          <img v-if="qrCodeSrc" :src="qrCodeSrc" alt="" />
        </div>
        <p class="info">请使用微信扫描二维码登录</p>
        <p class="info">“知设网”</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { loginQrCode, checkIfBind } from "@/Api";
import { getToken } from '../../utils/auth';
export default {
  name: "index",
  data() {
    return {
      centerDialogVisible: false,
      qrCodeSrc: "",
      qr_scene_str: "",
      timer: "",
    };
  },
  created() {
    loginQrCode().then((res) => {
      this.qrCodeSrc = res.data.qr_code;
      this.qr_scene_str = res.data.qr_scene_str;
      //开始轮序
      this.timer = window.setInterval(() => {
        this.checkIfBind();
      }, 1000);
    });
  },
  methods: {
    show() {
      this.centerDialogVisible = true;
    },
    //检验是否关注
    checkIfBind() {
      checkIfBind({ qr_scene_str: this.qr_scene_str,token:getToken() }).then((res) => {
        if (res.data.error == 0) {
          window.clearInterval(this.timer);
          this.centerDialogVisible = false
          location.reload()
        }
      });
    },
  },
  beforeDestroy() {
    window.clearInterval(this.timer);
  },
  watch:{
    centerDialogVisible(val){
      if(val == false){
        window.clearInterval(this.timer);
      }
    }
  }
};
</script>

<style scoped>
.secretBox {
  margin: 0 auto;
  border-radius: 8px;
  position: relative;
  width: 384px;
  height: 332px;
  background: #ffffff;
  opacity: 1;
  padding: 20px 30px 0 30px;
}
.secretBox p:nth-child(1) {
  width: 64px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #040404;
  line-height: 22px;
}
.secretBox .info {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #040404;
  line-height: 20px;
  text-align: center;
}
.secretBox .info {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #040404;
  line-height: 20px;
  text-align: center;
}
.qrcode {
  width: 200px;
  height: 200px;
  background: #d8d8d8;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin: 20px auto;
}
.qrcode img{
  width: 200px;
  height: 200px;
}
::v-deep .el-input {
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 270px;
  height: 38px;
  background: #eeeeee;
  opacity: 1;
  border-radius: 4px;
}

::v-deep .el-input__inner {
  -webkit-appearance: none;
  background: #eeeeee;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}

::v-deep .el-button--primary:first-child {
  width: 120px;
  background-color: #ffffff;
  border-color: #d9d9d9;
  color: #999999;
}
::v-deep .el-button--primary:last-child {
  width: 120px;
  background-color: #ff9e58;
  border-color: #ff9e58;
}

::v-deep .el-dialog {
  background: rgba(0, 0, 0, 0);

  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0);

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0);
}
::v-deep .el-dialog__headerbtn {
    position: absolute;
    top: 69px;
    right: 175px;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    font-size: 16px;
    z-index: 1000;
}
.head{
  display: flex;
  justify-content: space-between;
}
.head img{
  width: 16px;
  height: 16px;
}
</style>