import Vue from 'vue';
import comment from './index.vue';

let confirmInstance;
const ConfirmConstructor = Vue.extend(comment);  // 使用extend方法创建类似Vue的构造函数

const initInstance = () => {
    confirmInstance = new ConfirmConstructor();
    document.body.appendChild(confirmInstance.$mount().$el);
    // 实例化后手动挂载，得到$el真实Dom，将其添加到body最后
}

export default options => { // options 为可配置选项
    if (!confirmInstance) {
        initInstance(); // 未挂载就进行挂载
    }
    Object.assign(confirmInstance, options); // 该操作可以修改confirmInstance,将新的配置data挂载到this下

    return confirmInstance.show().then(()=>{
        confirmInstance = null;  // 调用之后即清空
    })
}

