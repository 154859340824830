<!-- 评价弹窗   -->
<template>
  <div>
    <el-dialog
      title="评论详情"
      :visible.sync="centerDialogVisible"
      width="35%"
      center
      :destroy-on-close="true"
    >
      <div>
        <p class="f12 t_c c_bb">~~和大家一起分享你的学习心得~~</p>
        <div class="commonName">
          <div class="commonImg">
            <el-image lazy :src="picture"></el-image>
          </div>
          <div class="commonNameInfo">
            <p class="w200 txt-over f14 f0404">{{ workTitle }}</p>
            <p class="f16 f0404 f_b">{{ price | splitMoney }}知币</p>
          </div>
        </div>
        <div class="df_Yc mt40">
          <p class="rateName">评分</p>
          <el-rate v-model="tate"> </el-rate>
        </div>
        <div class="commentBox">
          <p style="width: 50px" class="rateName">评价</p>
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="commentTxt"
            maxlength="300"
            show-word-limit
          >
          </el-input>
        </div>
        <div class="activeComment" @click="publishComment" v-show="showAdd">
          发布评论
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { publishComment } from "../../Api";
import { getToken } from "../../utils/auth";

export default {
  name: "index",
  data() {
    return {
      centerDialogVisible: false,
      tate: 0,
      commentTxt: "",
      picture: "",
      workTitle: "",
      id: "",
      price: "",
      showAdd: true,
    };
  },
  methods: {
    //挂载显示弹窗
    show() {
      return new Promise((resolve) => {
        this.centerDialogVisible = true;
        if (this.comment) {
          this.showAdd = false;
          this.commentTxt = this.comment.content;
          this.tate = this.comment.score;
        }
        this.price = this.work_price;
        resolve();
      });
    },
    //  发布评论
    publishComment() {
      const data = {
        token: getToken(),
        commentId: 0,
        score: this.tate,
        content: this.commentTxt,
        order_id: this.id,
        pid: this.pid,
      };
      publishComment(data).then((res) => {
        if (res.status === "200" && res.data.message === "addSuccess") {
          this.$message.success("评论成功");
          this.centerDialogVisible = false;
          location.reload();
        }
      });
    },
  },
};
</script>

<style scoped>
.activeComment {
  width: 1.16rem;
  height: 0.4rem;
  line-height: 0.4rem;
  background: #f46600;
  border-radius: 0.04rem;
  color: #ffffff;
  font-size: 0.14rem;
  text-align: center;
  cursor: pointer;
  margin-top: 0.4rem;
  margin-left: auto;
}
.commentBox {
  display: flex;
  margin-top: 0.3rem;
}
.rateName:before {
  content: "*";
  color: #f56c6c;
  width: 0.02rem;
  height: 0.02rem;
  margin-right: 0.05rem;
}
.rateName {
  width: 42px;
  margin-right: 0.17rem;
  font-size: 0.14rem;
  color: #bbbbbb;
}
.commonNameInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.commonImg {
  width: 1.5rem;
  height: 0.9rem;
  border-radius: 0.04rem;
  overflow: hidden;
  margin-right: 0.2rem;
}
.commonImg img {
  width: 100%;
}
.commonName {
  margin-top: 0.5rem;
  display: flex;
}
::v-deep .el-dialog--center .el-dialog__body {
  padding: 0 25px 30px;
}
::v-deep .el-dialog__title {
  font-weight: bold;
}
</style>