import {
	frontUploadRankList,
	frontPageSoftware,
  frontPageTeacher,
  frontPageData,
  getCourseNew,
  getMaterial,
  getWorkList,
  getPsList,
  getHotPs,
  getHotSchemeText,
  frontHotCoursePageData,
	getRelatedMaterial,
	pictureGetList,
	
} from "@/Api";
import {getToken} from "../../utils/auth";
import { photoGetList, getSuModeList, getMaxModeList,getSchemeTextList } from "../../Api";

const getDefaultState = () => {
  return {
		
    hotTeacher: [],
    hotSoftware: [],
    hotCourse: [],
    hotCourseList: [],
    hotMaterialList: [],
    hotSu: [],
    hotMax: [],
    hotPs: [],
    hotSchemeText: [],
    hotMaterial: [],
    courseListObj: {
      total: 0,
      per_page: 15,
      data: [],
    },
    materialListObj: {
      total: 0,
      per_page: 15,
      data: [],
    },
    psListObj: {
      total: 0,
      per_page: 15,
      data: [],
    },
    photoListObj: {
      total: 0,
      per_page: 15,
      data: [],
    },
    suListObj: {
      total: 0,
      per_page: 15,
      data: [],
    },
    maxListObj: {
      total: 0,
      per_page: 15,
      data: [],
    },
    schemeTextListObj:{
      total: 0,
      per_page: 15,
      data: [],
    },
    workListObj: {
      total: 0,
      per_page: 8,
      data: [],
    },
		chartletObj: {
			total: 0,
			per_page: 15,
			current_page: 0,
			data: [],
		},
		recommendList: [],
  };
};
const state = getDefaultState();

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_HOT_TEACHER: (state, teacher) => {
    state.hotTeacher = teacher;
  },
  SET_HOT_SOFTWARE: (state, hotSoftware) => {
    state.hotSoftware = hotSoftware;
  },
  SET_HOT_COURSELIST: (state, hotCourseList) => {
    state.hotCourseList = hotCourseList;
  },
  SET_HOT_MATERIALLIST: (state, hotMaterialList) => {
    state.hotMaterialList = hotMaterialList;
  },
  SET_HOT_COURSE: (state, hotCourse) => {
    state.hotCourse = hotCourse;
  },
  SET_HOT_SU: (state, hotSu) => {
    state.hotSu = hotSu;
  },
  SET_HOT_MAX:(state,hotMax) => {
    state.hotMax = hotMax;
  },
  SET_HOT_PS:(state,hotPs) => {
    state.hotPs = hotPs;
  },
  SET_HOT_SCHEMETEXT:(state,hotSchemeText) => {
    state.hotSchemeText = hotSchemeText;
  },
  SET_HOT_MATERIAL: (state, hotMaterial) => {
    state.hotMaterial = hotMaterial;
  },
  SET_COURSE_LIST: (state, courseListObj) => {
    state.courseListObj = courseListObj;
  },
  SET_MATERIAL_LIST: (state, materialListObj) => {
    state.materialListObj = materialListObj;
  },
  SET_PS_LIST: (state, psListObj) => {
    state.psListObj = psListObj;
  },
  SET_SU_LIST: (state, suListObj) => {
    state.suListObj = suListObj;
  },
  SET_MAX_LIST: (state, maxListObj) => {
    state.maxListObj = maxListObj;
  },
  SET_SCHEMETEXT_LIST: (state, schemeTextListObj) => {
    state.schemeTextListObj = schemeTextListObj;
  },
  SET_PHOTO_LIST: (state, photoListObj) => {
    state.photoListObj = photoListObj;
  },
  SET_WORK_LIST: (state, workListObj) => {
    state.workListObj = workListObj;
  },
  SET_CHARTLET_LIST: (state, chartletObj) => {
    state.chartletObj = chartletObj;
  },
	SET_RECOMMEND:(state, recommendList) => {
		state.recommendList = recommendList;
	},
};
const actions = {
  // 获取常用软件
  getHotSoftware({ commit }) {
    return new Promise((resolve, reject) => {
      frontPageSoftware({type: 1})
          .then((res) => {
						let resData = res.data || []
						let data = resData.splice(0, 8)
            commit("SET_HOT_SOFTWARE", data);
            resolve();
          })
          .catch((error) => {
            console.log("错误");
            reject(error);
          });
    });
  },
  // 获取销售课程榜单
  getHotCourseList({ commit }) {
    return new Promise((resolve, reject) => {
      frontUploadRankList({type: 1})
          .then((res) => {
						let resData = res.data || []
						// let data = resData.splice(0, 8)
            commit("SET_HOT_COURSELIST", resData);
            resolve();
          })
          .catch((error) => {
            console.log("错误");
            reject(error);
          });
    });
  },
  // 获取销售素材榜单
  getHotMaterialList({ commit }) {
    return new Promise((resolve, reject) => {
      frontUploadRankList({type: 2})
          .then((res) => {
						let resData = res.data || []
						// let data = resData.splice(0, 8)
            commit("SET_HOT_MATERIALLIST", resData);
            resolve();
          })
          .catch((error) => {
            console.log("错误");
            reject(error);
          });
    });
  },
  // 获取热门教师
  getHotTeacher({ commit }) {
    return new Promise((resolve, reject) => {
      frontPageTeacher({token:getToken()})
          .then((res) => {
            commit("SET_HOT_TEACHER", res.data);
            resolve();
          })
          .catch((error) => {
            console.log("错误");
            reject(error);
          });
    });
  },
  // 获取热门课程
  getHotCourse({ commit }) {
    return new Promise((resolve, reject) => {
      frontHotCoursePageData(6)
        .then((res) => {
          commit("SET_HOT_COURSE", res.data);
          resolve();
        })
        .catch((error) => {
          console.log("错误");
          reject(error);
        });
    });
  },
  // 获取热门SU
  getHotSu({ commit }) {
    return new Promise((resolve, reject) => {
      frontPageData(561)
        .then((res) => {
          commit("SET_HOT_SU", res.data);
          resolve();
        })
        .catch((error) => {
          console.log("错误");
          reject(error);
        });
    });
  },
  // 获取热门max
  getHotMax({ commit }) {
    return new Promise((resolve, reject) => {
      frontPageData(1393)
          .then((res) => {
            commit("SET_HOT_MAX", res.data);
            resolve();
          })
          .catch((error) => {
            console.log("错误");
            reject(error);
          });
    });
  },
  // 获取热门ps
  getHotPs({ commit }) {
    let params={
      position:1986,
      cate:1988
    }
    return new Promise((resolve, reject) => {
      getHotPs(params)
          .then((res) => {
            commit("SET_HOT_PS", res.data);
            resolve();
          })
          .catch((error) => {
            console.log("错误");
            reject(error);
          });
    });
  },
  // 获取热门schemeText
  getHotSchemeText({ commit }) {
    let params={
      position:1986,
      cate:1989
    }
    return new Promise((resolve, reject) => {
      getHotSchemeText(params)
          .then((res) => {
            commit("SET_HOT_SCHEMETEXT", res.data);
            resolve();
          })
          .catch((error) => {
            console.log("错误");
            reject(error);
          });
    });
  },
  // 获取热门素材
  getHotMaterial({ commit }) {
    return new Promise((resolve, reject) => {
      frontPageData(7)
        .then((res) => {
          commit("SET_HOT_MATERIAL", res.data);
          resolve();
        })
        .catch((error) => {
          console.log("错误");
          reject(error);
        });
    });
  },
  //获取课程列表
  getCourseList({ commit }, data) {
    return new Promise((resolve, reject) => {
      getCourseNew(data)
        .then((res) => {
          commit("SET_COURSE_LIST", res.data);
          resolve(res);
        })
        .catch((error) => {
          console.log("错误");
          reject(error);
        });
    });
  },
  //获取素材列表
  getMaterialList({ commit }, data) {
    return new Promise((resolve, reject) => {
      getMaterial(data)
        .then((res) => {
          commit("SET_MATERIAL_LIST", res.data);
          resolve(res);
        })
        .catch((error) => {
          console.log("错误");
          reject(error);
        });
    });
  },
  //获取PS列表
  getPsList({ commit }, data) {
    return new Promise((resolve, reject) => {
      getPsList(data)
        .then((res) => {
          commit("SET_PS_LIST", res.data);
          resolve(res);
        })
        .catch((error) => {
          console.log("错误");
          reject(error);
        });
    });
  },
  //获取su裂变
  getSulList({ commit }, data) {
    return new Promise((resolve, reject) => {
      getSuModeList(data)
        .then((res) => {
          commit("SET_SU_LIST", res.data);
          resolve(res);
        })
        .catch((error) => {
          console.log("错误");
          reject(error);
        });
    });
  },
  //获取max列表
  getMaxList({ commit }, data) {
    return new Promise((resolve, reject) => {
      getMaxModeList(data)
          .then((res) => {
            commit("SET_MAX_LIST", res.data);
            resolve(res);
          })
          .catch((error) => {
            console.log("错误");
            reject(error);
          });
    });
  },
  //获取方案文本列表
  getSchemeTextList({ commit }, data) {
    return new Promise((resolve, reject) => {
      getSchemeTextList(data)
          .then((res) => {
            commit("SET_SCHEMETEXT_LIST", res.data);
            resolve(res);
          })
          .catch((error) => {
            console.log("错误");
            reject(error);
          });
    });
  },
  //获取图库列表
  getPhoto({ commit }, data) {
    return new Promise((resolve, reject) => {
      photoGetList(data)
        .then((res) => {
          commit("SET_PHOTO_LIST", res.data);
          resolve(res);
        })
        .catch((error) => {
          console.log("错误");
          reject(error);
        });
    });
  },
  //获取作品列表
  getWorkList({ commit }, data) {
    return new Promise((resolve, reject) => {
      getWorkList(data)
        .then((res) => {
          commit("SET_WORK_LIST", res.data);
          resolve(res);
        })
        .catch((error) => {
          console.log("错误");
          reject(error);
        });
    });
  },
	//获取贴图列表
	getChartletList({ commit }, data) {
		return new Promise((resolve, reject) => {
			pictureGetList(data)
				.then((res) => {
					commit("SET_CHARTLET_LIST", res.data);
					resolve(res);
				})
				.catch((error) => {
					console.log("错误");
					reject(error);
				});
		});
	},
	// 获取推荐详情列表
	getRelatedMaterial({ commit }, data) {
		return new Promise((resolve, reject) => {
			getRelatedMaterial(data)
				.then((res) => {
					commit("SET_RECOMMEND", res.data);
					resolve(res);
				})
				.catch((error) => {
					console.log("错误");
					reject(error);
				});
		});
	}
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
