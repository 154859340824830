<!--
 * @FilePath: \zsw_new_edition\src\components\navComeDown\SuComeDown.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-25 14:21:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-03-03 09:26:35
-->
<!--Su下拉框组件-->
<template>
  <div class="comeDownBox">
    <div class="imgBox">
       <a target="_blank" :href="comeDownBanner.url">
        <img :src="comeDownBanner.src" alt="" />
      </a>
    </div>
    <div class="contentBox">
      <ComeDownMiddle  :link="link" :classList="classList"></ComeDownMiddle>
    </div>
  </div>
</template>

<script>
import ComeDownMiddle from "@/components/navComeDown/ComeDownMiddle";
import { getBanner,getNavigation } from "../../Api";

export default {
  name: "MaxComeDown",
  props:[
    "link"
  ],
  data(){
    return {
      comeDownBanner:"",
      classList:""
    }
  },
  components: {
    ComeDownMiddle,
  },
  created() {
    getBanner({
      position: 1975,
    }).then((res) => {
      this.comeDownBanner = res.data[0];
    });
    getNavigation(1393).then((res) => {
      this.classList = res.data;
      console.log(res)
    });
  },
};
</script>

<style scoped>
.comeDownBox {
  height: 4.27rem;
  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(184, 184, 184, 0.16);
  opacity: 1;
  display: flex;
  padding: 0.3rem 0 0.3rem 2.8rem;
}
.imgBox {
  width: 2.48rem;
  height: 3.67rem;
  border-radius: 8px;
  margin-right: 0.4rem;
  overflow: hidden;
}

.imgBox img {
  width: 2.48rem;
  height: 3.67rem;
  border-radius: 8px;
  transition: all 0.4s;
  -moz-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
}

.imgBox img:hover {
  transform: scale(1.2);
}

.contentBox {
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
  display: flex;
}
</style>