<template>
  <div>
    <el-dialog
      :visible.sync="centerDialogVisible"
      center
      :destroy-on-close="true"
      :show-close="false"
    >
      <div class="secretBox">
        <div v-if="cate">
          <p>该栏目设置了访问密码</p>
          <p>请输入栏目访问密码</p>
          <el-input v-model="input"></el-input>
          <div class="btnBox">
            <el-button type="primary" @click="centerDialogVisible = false"
              >取消</el-button
            >
            <el-button type="primary" @click="check()">确定</el-button>
          </div>
        </div>
        <div v-else>
          <p>手机验证</p>
          <p>
            短信验证码已发送至绑定手机{{
              username.replace(username.substring(3, 7), "****")
            }}
          </p>
          <el-input v-model="input"></el-input>
          <div class="btnBox">
            <el-button type="primary" @click="centerDialogVisible = false"
              >取消</el-button
            >
            <el-button type="primary" @click="checkCode()">确定</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getToken } from "../../utils/auth";
import {
  checkAuth,
  sendMessage,
  getMessage,
  getInfo,
  checkMessageCode,
} from "../../Api";
export default {
  name: "index",
  data() {
    return {
      centerDialogVisible: false,
      success: false,
      input: "",
      cate: "",
      username: "",
    };
  },
  methods: {
    show(cate = "") {
      this.centerDialogVisible = true;
      this.success = false;
      this.input = "";
      this.cate = "";
      this.username = "";
      if (cate) {
        this.cate = cate;
      } else {
        getInfo(getToken()).then((res) => {
          this.username = res.data.user_name;
          //发送验证码
          getMessage(this.username).then((res) => {
            if (res.data.auth) {
              sendMessage({ auth: this.$randomWord(true, 8, 8) + res.data.auth, type: 9 });
            } else {
              this.$message.error("发送验证码失败");
            }
          });
        });
      }
    },
    check() {
      checkAuth({
        token: getToken(),
        secret: this.input,
        cate: this.cate,
      }).then((res) => {
        if (res.data.message == "isCorrect") {
          this.success = true;
          this.centerDialogVisible = false;
        } else {
          this.$toast({ text: "密码错误" });
        }
      });
    },
    checkCode() {
      checkMessageCode({
        token: getToken(),
        messageCode: this.input,
        tel: this.username,
      }).then((res) => {
        if (res.data.message == "codeIsCorrect") {
          this.success = true;
          this.centerDialogVisible = false;
        } else {
          this.$toast({ text: "验证码错误" });
        }
      });
    },
  },
};
</script>

<style scoped>
.secretBox {
  margin: 0 auto;
  border-radius: 8px;
  position: relative;
  width: 350px;
  height: 248px;
  background: #ffffff;
  border: 1px solid #707070;
  opacity: 1;
  padding: 20px 40px 0 40px;
}
.secretBox div p:first-child {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
  opacity: 1;
  margin-bottom: 10px;
}

.secretBox div p:last-child {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #999999;
  opacity: 1;
}
.btnBox {
  display: flex;
  justify-content: space-between;
}

::v-deep .el-input {
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 270px;
  height: 38px;
  background: #eeeeee;
  opacity: 1;
  border-radius: 4px;
}

::v-deep .el-input__inner {
  -webkit-appearance: none;
  background: #eeeeee;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}

::v-deep .el-button--primary:first-child {
  width: 120px;
  background-color: #ffffff;
  border-color: #d9d9d9;
  color: #999999;
}
::v-deep .el-button--primary:last-child {
  width: 120px;
  background-color: #ff9e58;
  border-color: #ff9e58;
}

::v-deep .el-dialog {
  background: rgba(0, 0, 0, 0);

  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0);

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0);
}
</style>