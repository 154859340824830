/*
 * @FilePath: \zsw_pc_test\src\main.js
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-23 14:04:09
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: Xxster 40750223+Xxster@users.noreply.github.com
 * @LastEditTime: 2024-07-24 15:22:59
 */
import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/zh-CN";
import "element-ui/lib/theme-chalk/index.css";
import VideoPlayer from "vue-video-player";
import waterfall from "vue-waterfall2";
import "vue-video-player/src/custom-theme.css";
import "video.js/dist/video-js.css";
import "@/permission";
import "@/assets/index.css";
import '../public/UEditor/ueditor.config.js';
import '../public/UEditor/ueditor.all.min.js';
import '../public/UEditor/lang/zh-cn/zh-cn.js';
import '../public/UEditor/ueditor.parse.min.js';
import login from "./components/loginPop/index.js";
import collectPop from "./components/collectPop/index.js";
import commentPop from "./components/commentPop/index.js";
import payPop from "./components/payPop/index.js";
import topUpPop from "./components/topUpPop/index.js";
import schedulePop from "./components/schedulePop/index.js";
import vipPop from "./components/vipPop/index.js";
import filters from "./utils/filters.js"; // global filters
import toastRegistry from './components/toastPop/index.js'
import placardPop from './components/placardPop/index'
import renewPop from './components/renewPop/index'
import aboutPop from './components/aboutPop/index'
import inviteVipPop from './components/inviteVipPop/index'
import secretPop from "./components/secretPop/index.js"
import wxPop from "./components/wxPop/index.js"
import tooltipPop from "./components/tooltipPop/index.js"
import servicePop from "./components/servicePop/index.js"
import picturePop from "./components/picturePop/index.js"
import bindTelPop from "./components/bindTelPop/index.js"
import changePwdPop from "./components/changePwdPop/index.js"
import reportErrorPop from './components/reportErrorPop/index'
import paySoftwarePop from "./components/paySoftwarePop/index.js";
import "@/assets/iconFont/iconfont.css"
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)
import { dealObjectValue } from '@/utils/index';
Vue.use(toastRegistry)
// register global utility filters
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});
Vue.prototype.$login = login;
Vue.prototype.$collectPop = collectPop;
Vue.prototype.$commentPop = commentPop;
Vue.prototype.$payPop = payPop;
Vue.prototype.$topUpPop = topUpPop;
Vue.prototype.$schedulePop = schedulePop;
Vue.prototype.$vipPop = vipPop;
Vue.prototype.$placardPop = placardPop;
Vue.prototype.$reportErrorPop = reportErrorPop;
Vue.prototype.$inviteVipPop = inviteVipPop;
Vue.prototype.$secretPop = secretPop;
Vue.prototype.$wxPop = wxPop;
Vue.prototype.$tooltipPop = tooltipPop;
Vue.prototype.$servicePop = servicePop;
Vue.prototype.$picturePop = picturePop;
Vue.prototype.$bindTelPop = bindTelPop;
Vue.prototype.$changePwdPop = changePwdPop;
Vue.prototype.$renewPop = renewPop;
Vue.prototype.$aboutPop = aboutPop;
Vue.prototype.$paySoftwarePop = paySoftwarePop;
Object.defineProperties(Vue.prototype, {
  //处理json参数
  $dealObjectValue: {
    value: dealObjectValue
  },
})

if (process.env.NODE_ENV == 'development') {
  Vue.prototype.$ROOT_DIR = "http://localhost:8080/v2api"
} else {
  Vue.prototype.$ROOT_DIR = 'https://apidev.zhishew.com/v2'
  // Vue.prototype.$ROOT_DIR = 'http://apitest.zhishew.com/v2'
}

//
//获取随机验证
Vue.prototype.$randomWord = function (randomFlag, min, max) {
  let str = "",
    range = min,
    arr = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];

  // 随机产生
  if (randomFlag) {
    range = Math.round(Math.random() * (max - min)) + min;
  }
  for (let i = 0; i < range; i++) {
    let pos = Math.round(Math.random() * (arr.length - 1));
    str += arr[pos];
  }
  return str;
};
Vue.config.productionTip = false;

Vue.use(ElementUI, { locale });
Vue.use(VideoPlayer);
Vue.use(waterfall);
new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
});
