import { render, staticRenderFns } from "./registerForm.vue?vue&type=template&id=231ff319&scoped=true&"
import script from "./registerForm.vue?vue&type=script&lang=js&"
export * from "./registerForm.vue?vue&type=script&lang=js&"
import style0 from "./registerForm.vue?vue&type=style&index=0&id=231ff319&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "231ff319",
  null
  
)

export default component.exports