<template>
  <div>
    <el-dialog
      :visible.sync="centerDialogVisible"
      center
      :destroy-on-close="true"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div class="scheduleBox">
        <p class="p1">配套资源下载提示</p>
        <p class="p2">
          很抱歉，您的该视频观看分钟数不足10% 无法下载该视频的配套文件
        </p>
      </div>
      <div class="imgBox" @click="close">
        <img src="../../assets/image/pop/x.png" alt="" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      centerDialogVisible: false,
    };
  },
  methods: {
    show() {
      this.centerDialogVisible = true;
    },
    //关闭回调
    close() {
      this.centerDialogVisible = false;
    },
  },
};
</script>

<style scoped>
.scheduleBox {
  margin: 0 auto;
  width: 400px;
  height: 309px;
  background: url("../../assets/image/pop/schedule.png") no-repeat;
  padding-top: 153px;
}

.p1 {
  margin: 0 136px 24px;
  width: 128px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
  opacity: 1;
}

.p2 {
  margin-left: 73px;
  width: 254px;
  height: 50px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 30px;
  color: #999999;
  opacity: 1;
  text-align: center;
}

.imgBox {
  width: 400px;
  margin: 30px auto 0;
  display: flex;
  justify-content: center;
}

.imgBox img {
  width: 30px;
  height: 30px;
}

::v-deep .el-dialog {
  background: rgba(0, 0, 0, 0);

  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0);

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0);
}
/* ::v-deep .el-dialog__headerbtn{
  top: 0.15rem;
  right: 0.15rem;
  font-size: 0.16rem;
}
::v-deep .el-dialog__header{
  text-align: left;
  color: #040404;
  font-weight: bold;
  padding: 0.2rem 0.3rem;
  background: linear-gradient(180deg, #A4C3FC 0%, #F3F7FF 100%);
  height: 2.09rem;
}
::v-deep .el-dialog__title{
  font-size: 0.18rem;
} */
</style>