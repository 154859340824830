import Cookies from 'js-cookie'

const TokenKey = 'userToken'

export function getToken() {
  return Cookies.get(TokenKey)
}
export function setToken(token) {
  return Cookies.set(TokenKey, token)
}
export function removeToken() {
  return Cookies.remove(TokenKey)
}
export function getRemember() {
  if(!Cookies.get('memberObj')){
    return
  }
  return JSON.parse(Cookies.get('memberObj'))
}
export function setRemember(memberObj){
  return Cookies.set('memberObj', JSON.stringify(memberObj))
}
export function removeRemember(){
  return Cookies.remove('memberObj')
}

