<template>
  <div>
    <el-dialog
      :visible.sync="centerDialogVisible"
      center
      :destroy-on-close="true"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div v-if="data.type == 1" class="scheduleImgBox">
				<div :class="{pointer: data.url}" @click="jump(data.url)">
					<img :src="data.content" alt="" />
				</div>
      </div>
			<div v-else class="scheduleBox">
				<div class="bckBox1">
					<img src="../../assets/image/pop/gg.jpg" alt="" />
				</div>
				<div class="content">
					<p>尊敬的知设网用户您好：</p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ data.content }}
				</div>
				<div class="bckBox2">
					<img src="../../assets/image/pop/gg1.png" alt="" />
				</div>
			</div>
      <div class="imgBox" @click="close">
        <img v-if="data.type == 1" src="../../assets/image/pop/imgx.png" alt="" />
        <img v-else src="../../assets/image/pop/x.png" alt="" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      centerDialogVisible: false,
			data: {}
    };
  },
  methods: {
    show(data) {
      this.data = data
      this.centerDialogVisible = true;
    },
    //关闭回调
    close() {
      this.centerDialogVisible = false;
    },
		
		jump(url) {
			if(url) {
				window.open(url, '_blank')
			}
		}
  },
};
</script>

<style scoped>
.pointer {
	cursor: pointer;
}
.scheduleBox {
  margin: 0 auto;
  width: 400px;
  min-height: 354px;
  border-radius: 8px;
  position: relative;
  background: #ffffff;
}
.scheduleImgBox {
	margin: 0 auto;
	width: 400px;
}
.scheduleImgBox img {
	margin: 0 auto;
	width: 400px;
}
.content {
  position: absolute;
  top: 113px;
  left: 20px;
  z-index: 2;
  width: 360px;
  min-height: 214px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(28, 64, 199, 0.1);
  opacity: 1;
  border-radius: 8px;
  padding: 90px 20px 0;
  font-size: 14px;
  font-family: PingFang SC;
  line-height: 26px;
  color: #040404;
  opacity: 1;
}

.content p {
  font-weight: bold;
}

.bckBox1 {
  position: absolute;
  width: 400px;
  height: 180px;
}
.bckBox1 img {
  width: 400px;
  height: 180px;
  border-radius: 8px;
}

.bckBox2 {
  position: absolute;
  width: 140px;
  height: 140px;
  z-index: 3;
  top: 65px;
  left: 130px;
}
.bckBox2 img {
  width: 140px;
  height: 140px;
}

.p1 {
  margin: 0 136px 24px;
  width: 128px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
  opacity: 1;
}

.p2 {
  margin-left: 73px;
  width: 254px;
  height: 50px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 30px;
  color: #999999;
  opacity: 1;
  text-align: center;
}

.imgBox {
	cursor: pointer;
  width: 30px;
  margin: 30px auto 0;
  display: flex;
  justify-content: center;
}

.imgBox img {
  width: 30px;
  height: 30px;
}

::v-deep .el-dialog {
  background: rgba(0, 0, 0, 0);

  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0);

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0);
}
/* ::v-deep .el-dialog__headerbtn{
  top: 0.15rem;
  right: 0.15rem;
  font-size: 0.16rem;
}
::v-deep .el-dialog__header{
  text-align: left;
  color: #040404;
  font-weight: bold;
  padding: 0.2rem 0.3rem;
  background: linear-gradient(180deg, #A4C3FC 0%, #F3F7FF 100%);
  height: 2.09rem;
}
::v-deep .el-dialog__title{
  font-size: 0.18rem;
} */
</style>