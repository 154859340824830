import {login, getInfo, smsLogin, register, getCheckJoin,weChatLogin} from '@/Api'
import {getToken, setToken, setRemember, removeRemember, removeToken} from '@/utils/auth'
import {Message} from 'element-ui'
import {resetRouter} from '@/router'

const getDefaultState = () => {
    return {
        token: getToken(),
        userInfo: null,
        uid: '123123',
        userEarningsInfo: null,
        joinStatus: null,
        balance: 0,
        isLogin: false,
    }
}
const state = getDefaultState()

const mutations = {
    RESET_STATE: (state) => {
        Object.assign(state, getDefaultState())
    },
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_USER_INFO: (state, userInfo) => {
        state.userInfo = userInfo
    },
    SET_USER_UID: (state, uid) => {
        state.uid = uid
    },
    SET_JOIN_STATUS: (state, joinStatus) => {
        state.joinStatus = joinStatus
    },
    SET_BALANCE: (state, balance) => {
        state.balance = balance
    },
    SET_LOGIN_STATUS: (state, data) => {
        state.isLogin = data
    }
}
const actions = {
    //账号登录
    login({commit}, loginForm) {
        return new Promise((resolve, reject) => {
            const {username, pwd, rememberPwd} = loginForm
            login({username: username, pwd: pwd}).then(response => {
                const {data} = response
                if (data.message == "loginSuccess") {
                    setToken(data.token)
                    commit('SET_TOKEN', data.token)
                    commit('SET_USER_UID', data.uid)
                    if (rememberPwd) {
                        let memberObj = {username: username, pwd: pwd, rememberPwd: rememberPwd}
                        setRemember(memberObj)
                    } else {
                        removeRemember()
                    }
                    resolve()

                } else {
                    Message({
                        message: '用户名或密码错误',
                        type: 'error',
                        duration: 5 * 1000
                    })
                }
            }).catch(error => {
                console.log('错误')
                reject(error)
            })
        })
    },
    //验证码登录
    codeLogin({commit}, loginForm) {
        return new Promise((resolve, reject) => {
            smsLogin(loginForm).then(response => {
                const {data} = response
                if (data.message == "loginSuccess") {
                    commit('SET_TOKEN', data.token)
                    commit('SET_USER_UID', data.uid)
                    setToken(data.token)
                    resolve()
                } else {
                    Message({
                        message: '验证码错误',
                        type: 'error',
                        duration: 5 * 1000
                    })
                }
            }).catch(error => {
                console.log('错误')
                reject(error)
            })
        })
    },
    //扫码登录
    wxLogin({commit}, loginForm) {
        return new Promise((resolve, reject) => {
            weChatLogin(loginForm).then(response => {
                const {data} = response
                if (data.message == "loginSuccess") {
                    commit('SET_TOKEN', data.token)
                    commit('SET_USER_UID', data.uid)
                    setToken(data.token)
                    resolve()
                }
            }).catch(error => {
                console.log('错误')
                reject(error)
            })
        })
    },
    //注册
    register({commit}, registerForm) {
        return new Promise((resolve, reject) => {
            register(registerForm).then(res => {
                const {data} = res
                if (res.status == 200 && data.error == 0) {
                    commit('SET_TOKEN', data.token)
                    commit('SET_USER_UID', data.uid)
                    setToken(data.token)
                    resolve()
                } else {
                    Message({
                        message: '验证码错误',
                        type: 'error',
                        duration: 5 * 1000
                    })
                }
            }).catch(error => {
                console.log('错误')
                reject(error)
            })
        })
    },
    //获取用户信息
    getInfo({commit, state}) {
        //用户基础信息
        let userInfo = new Promise((resolve, reject) => {
            getInfo(state.token).then(res => {
                const {data} = res
                if (data.error != -1) {
                    commit('SET_USER_INFO', data)
                    commit('SET_USER_UID', data.uid)
                }
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })

        // 用户入驻状态 getCheckJoin
        let joinStatus = new Promise((resolve, reject) => {
            getCheckJoin(state.token).then(res => {
                commit('SET_JOIN_STATUS', res.data.message)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
        return Promise.all([userInfo, joinStatus]).then((res) => {
            if (res[0].error == 10000) {
                commit('SET_LOGIN_STATUS', false)
            } else {
                commit('SET_BALANCE', res[0].zhiCoin)
                commit('SET_LOGIN_STATUS', true)
            }
        })
    },
    logout({commit}) {
        removeToken()
        resetRouter()
        commit('RESET_STATE')
    },
    resetToken({commit}) {
        return new Promise(resolve => {
            removeToken()
            commit('RESET_STATE')
            resolve()
        })
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}