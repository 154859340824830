/*
 * @FilePath: \zsw_new_edition\src\store\getters.js
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-23 14:04:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-03-03 10:49:07
 */
const getters = {
    isLogin:state => state.user.isLogin, // 是否登录
    token: state => state.user.token, // 用户token
    uid:state => state.user.uid, // 用户uid
    userInfo: state => state.user.userInfo, // 用户信息
    userEarningsInfo:state => state.user.userEarningsInfo, // 用户收益情况
    joinStatus:state => state.user.joinStatus, // 用户入驻状态
    balance:state=>state.user.balance, // 用户知币余额
    hotTeacher:state => state.app.hotTeacher, // 首页教师
    hotSoftware:state => state.app.hotSoftware, // 首页软件
    hotCourse:state => state.app.hotCourse, // 首页热门课程
    hotCourseList:state => state.app.hotCourseList, // 首页销售课程
    hotMaterialList:state => state.app.hotMaterialList, // 首页销售素材
    hotSu:state => state.app.hotSu, // 首页热门su
    hotMax:state => state.app.hotMax, // 首页热门max
    hotPs:state => state.app.hotPs, // 首页热门ps
    hotSchemeText:state => state.app.hotSchemeText, // 首页热门方案文本
    hotMaterial:state => state.app.hotMaterial, // 首页热门素材
    courseListObj:state=>state.app.courseListObj, //课程主页列表
    materialListObj:state=>state.app.materialListObj, // 素材主页列表
    suListObj:state=>state.app.suListObj, // su主页列表
    maxListObj:state=>state.app.maxListObj, // 3d主页列表
    schemeTextListObj:state=>state.app.schemeTextListObj, // 方案文本主页列表
    photoListObj:state=>state.app.photoListObj, // 图库主页列表
    uploadTokenInfo:state=>state.upload.uploadTokenInfo, // 七牛云上传秘钥
    unSubmittedMaterial:state=>state.upload.unSubmittedMaterial, // 本地编辑并未提交审核的素材列表（草稿箱）
    unSubmittedSu:state=>state.upload.unSubmittedSu,
    unSubmittedMax:state=>state.upload.unSubmittedMax,
    unSubmittedSchemeText:state=>state.upload.unSubmittedSchemeText,
    CourseMultiVideo:state=>state.upload.CourseMultiVideo, // 上传章节课程时的章节信息（临时存储）
    courseBanner:state=>state.nav.courseBanner,//课程分类banner
    joinData:state=>state.sign.joinData,//企业签约数据
    workListObj:state=>state.app.workListObj,//上传作品列表
    psListObj:state=>state.app.psListObj,//PS素材列表
    recommendList:state=>state.app.recommendList, //推荐详情列表
    chartletObj:state=>state.app.chartletObj,	// 贴图列表
}
export default getters
