<template>
  <div>
    <el-dialog
      :visible.sync="centerDialogVisible"
      center
      :destroy-on-close="true"
      :show-close="false"
    >
      <div class="secretBox">
				<div class="head">
					<img src="../../assets/image/icon/close.png" alt="" @click="centerDialogVisible=false"/>
				</div>
				<p class="title">温馨提示</p>
				<div class="qrcode">
					<img :src="vipImg" alt="">
				</div>
				<p class="info">
					请使用微信扫码
				</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getBanner } from "../../Api.js";
export default {
  name: "servicePop",
  data() {
    return {
			centerDialogVisible: false,
			vipImg: ''
    };
  },
	created() {
		getBanner({ position: 1133, cate: 2151 }).then((res) => {
			let data = res.data;
			this.vipImg = data[0].src;
		});
	},
  methods: {
    show() {
      this.centerDialogVisible = true;
    },
  },
};
</script>

<style scoped>
.secretBox {
  position: relative;
  margin: 0 auto;
  border-radius: 8px;
  width: 300px;
  height: 420px;
  background: #ffffff;
  opacity: 1;
	padding: 17px;
	overflow: hidden;
}
.title {
	margin-top: 20px;
	text-align: center;
  font-size: 24px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: 500;
  letter-spacing: 0px;
  color: rgba(56, 56, 56, 1);
}
.info {
	margin: 0 auto;
  font-family: PingFang SC-Regular, PingFang SC;
	font-size: 12px;
	font-weight: 400;
	letter-spacing: 0px;
	line-height: 0px;
	color: rgba(128, 128, 126, 1);
	letter-spacing: 0px;
  text-align: center;
}
.qrcode {
  width: 220px;
  height: 220px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin: 20px auto;
}
.qrcode img{
  width: 220px;
  height: 220px;
}
::v-deep .el-input {
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 270px;
  height: 38px;
  background: #eeeeee;
  opacity: 1;
  border-radius: 4px;
}

::v-deep .el-input__inner {
  -webkit-appearance: none;
  background: #eeeeee;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}

::v-deep .el-button--primary:first-child {
  width: 120px;
  background-color: #ffffff;
  border-color: #d9d9d9;
  color: #999999;
}
::v-deep .el-button--primary:last-child {
  width: 120px;
  background-color: #ff9e58;
  border-color: #ff9e58;
}

::v-deep .el-dialog {
  background: rgba(0, 0, 0, 0);

  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0);

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0);
}
::v-deep .el-dialog__headerbtn {
    position: absolute;
    top: 69px;
    right: 175px;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    font-size: 16px;
    z-index: 1000;
}
.head{
  display: flex;
  flex-direction: row-reverse;
}
.head img{
  width: 16px;
  height: 16px;
}
</style>