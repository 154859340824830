<!--mmp胎神设计，一个收藏功能要点尼玛三种不同的操作-->
<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="7.1rem"
      :destroy-on-close="true"
    >
      <div>
        <div class="collectPopBox">
          <div class="popLeft">
            <div class="imgBox" v-if="fidd">
              <img :src="img" alt="" />
            </div>
          </div>
          <div class="popRight">
            <p class="c_66 f16">选择收藏夹</p>
            <el-input
              class="mt20"
              v-model="search"
              placeholder="搜索或创建收藏夹"
            ></el-input>
            <!--  用户输入时如果有匹配项则显示  -->
            <ul class="searchList" v-if="search">
              <li class="searchItem active">
                <div class="df_Yc">
                  <i class="iconfont f16 mr10 searchIcon">&#xe67d;</i>
                  <p class="c_66 f14">{{ search }}</p>
                </div>
                <div class="collectButton" @click="createAndDoCollect(search)">
                  收藏
                </div>
              </li>
              <li
                class="searchItem"
                v-for="(item, index) in searchCollectList"
                :key="index"
              >
                <div class="df_Yc">
                  <i class="iconfont f16 mr10 searchIcon">&#xe67d;</i>
                  <p class="c_66 f14">{{ item.name }}</p>
                </div>
                <div class="collectButton" @click="doCollect(item.id)">
                  收藏
                </div>
              </li>
            </ul>
            <!-- 默认显示（没有输入） -->
            <div class="searchListBox" v-if="!search">
              <!-- 最近使用过的收藏夹 -->
              <ul class="searchList" v-if="recentCollectList.length > 0">
                <li class="searchItem active">
                  <div class="df_Yc">
                    <i class="iconfont f16 mr10 searchIcon">&#xe6f8;</i>
                    <p class="c_66 f14">最近添加过的收藏夹</p>
                  </div>
                  <div class="collectButton" style="visibility: hidden">
                    收藏
                  </div>
                </li>
                <li
                  class="searchItem"
                  v-for="(item, index) in recentCollectList"
                  :key="index"
                >
                  <div class="df_Yc">
                    <i class="iconfont f16 mr10 searchIcon">&#xe6f8;</i>
                    <p class="c_66 f14">{{ item.name }}</p>
                  </div>
                  <div class="collectButton" @click="doCollect(item.id)">
                    收藏
                  </div>
                </li>
              </ul>
              <!-- 已有的收藏夹 -->
              <div
                class="mt10 yyCollectBox"
                v-if="collectFiles.length > 0 && hasCollectList.length > 0"
              >
                <ul style="margin-top: 5px" class="searchList">
                  <li
                    class="searchItem active"
                    v-if="collectFiles.length === 0"
                  >
                    <div class="df_Yc">
                      <i class="iconfont f16 mr10 searchIcon">&#xe6f8;</i>
                      <p class="c_66 f14">默认收藏夹</p>
                    </div>
                    <div
                      class="collectButton"
                      @click="createAndDoCollect('默认收藏夹')"
                    >
                      收藏
                    </div>
                  </li>
                  <li
                    class="searchItem"
                    v-for="(item, index) in hasCollectList"
                    :key="index"
                  >
                    <div class="df_Yc">
                      <p class="c_66 f14 mr10" style="width: 0.14rem">
                        {{ index == 0 ? item.spell : "&nbsp;&nbsp;" }}
                      </p>
                      <p class="c_66 f14">{{ item.name }}</p>
                    </div>
                    <div class="collectButton" @click="doCollect(item.id)">
                      收藏
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          class="dfc mt20 c_p"
          style="margin-left: 1.6rem"
          v-if="searchCollectList.length == 0"
        >
          <i class="iconfont c_66 f14 mr10">&#xe60f;</i>
          <p class="c_66 f14">创建收藏夹“收藏夹名字”</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Pinyin from "@/utils/spell";
import { doCollect, doPhotoCollect } from "../../Api";
export default {
  name: "index",
  data() {
    return {
      search: "",
      collectFiles: [],
      dialogVisible: false,
      isCollect: false,
      fidd: "",
    };
  },
  computed: {
    //过滤搜索方法
    searchCollectList: function () {
      var _search = this.search;
      if (_search) {
        //不区分大小写处理
        var reg = new RegExp(_search, "ig");
        //es6 filter过滤匹配，有则返回当前，无则返回所有
        let collect = this.collectFiles.filter(function (e) {
          //匹配所有字段
          // return Object.keys(e).some(function (key) {
          //   return e[key].match(reg);
          // });
          //匹配某个字段
          return e.name.match(reg);
        });
        return collect;
      }
      return this.collectFiles;
    },
    //过滤最近添加过的收藏
    recentCollectList: function () {
      return this.collectFiles.filter(function (e) {
        //计算时间差
        if (
          parseInt(new Date().getTime() / 1000) -
            parseInt(new Date(e.addTime).getTime() / 1000) <
          86400 * 2
        ) {
          return e;
        }
      });
    },
    //已有的收藏夹过滤最近添加部分
    hasCollectList: function () {
      return this.collectFiles.filter(function (e) {
        //计算时间差
        if (
          parseInt(new Date().getTime() / 1000) -
            parseInt(new Date(e.addTime).getTime() / 1000) >
          86400 * 2
        ) {
          return e;
        }
      });
    },
  },
  methods: {
    //挂载显示弹窗
    show() {
      return new Promise((resolve) => {
        this.fidd = this.fid;
        this.collectFiles = this.collectList;
        if (this.fidd == 7 || this.fidd == 561 || this.fidd == 1196 ||  this.fidd == 1394) {
          this.img = this.detailInfo.cover;
        } else if (this.fidd == 6) {
          this.img = this.detailInfo.img;
        } else if (this.fidd == 273) {
          this.img = this.detailInfo.img;
        } else if (this.fidd == 2205) {
          this.img = this.detailInfo.pic_str;
        } else if (this.fidd == 2381) {
          this.img = this.detailInfo.pic_str;
        } else {
          this.img = this.detailInfo.pic;
        }
        this.dialogVisible = true;
        resolve();
      });
    },
    //首字母排序
    spellSort(list) {
      let pinyinArray = [];
      let reg = /^[A-Za-z]*$/;
      list.map((v) => {
        let ken = Pinyin.getSpell(v.name[0], function (charactor, spell) {
          return spell[1];
        });
        pinyinArray.push({
          ...v,
          spell: reg.test(ken[0]) ? ken[0].toUpperCase() : "#",
        });
      });
      let map = {
        title: "",
        datas: [],
      };
      pinyinArray.forEach((item) => {
        if (!map[item.spell]) {
          map[item.spell] = {
            title: item.spell,
            datas: [],
          };
        }
        map[item.spell].datas.push(item);
      });
      let turn = new Array();
      let letters = "ABCDEFGHIJKLNMOPQRSTUVWXYZ#".split("");
      for (var i = 1; i < letters.length; i++) {
        if (map[letters[i]]) {
          turn.push(map[letters[i]]);
        }
      }
      return turn;
    },
    //收藏
    doCollect(id) {
      if (this.fidd == 273 || this.fidd == 2205 || this.fidd == 2381) {
        doPhotoCollect({
          token: this.token,
          pid: this.pid,
          fid: this.fidd,
          id: id,
        }).then((res) => {
          if (res.data.message === "addSuccess" && res.data.error === 0) {
            this.dialogVisible = false;
            this.$message.success("收藏成功");
            this.isCollect = true;
          }
        });
      } else {
        doCollect({
          token: this.token,
          pid: this.pid,
          fid: this.fidd,
          id: id,
        }).then((res) => {
          if (res.data.message === "addSuccess" && res.data.error === 0) {
            this.dialogVisible = false;
            this.$message.success("收藏成功");
            this.isCollect = true;
          }
        });
      }
    },
    //先创建再收藏
    createAndDoCollect(name) {
      if (this.fidd == 273 || this.fidd == 2205 || this.fidd == 2381) {
        doPhotoCollect({
          token: this.token,
          pid: this.pid,
          fid: this.fidd,
          name: name,
        }).then((res) => {
          if (res.data.message === "addSuccess" && res.data.error === 0) {
            this.dialogVisible = false;
            this.$message.success("收藏成功");
            this.isCollect = true;
          }
        });
      } else {
        doCollect({
          token: this.token,
          pid: this.pid,
          fid: this.fid,
          name: name,
        }).then((res) => {
          if (res.data.message === "addSuccess" && res.data.error === 0) {
            this.dialogVisible = false;
            this.$message.success("收藏成功");
            this.isCollect = true;
          }
        });
      }
    },
  },
  mounted() {},
};
</script>
<style scoped>
.searchListBox {
  max-height: 3.1rem;
  overflow: auto;
}
.searchListBox::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.searchListBox::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 3px;
}
.searchList {
  margin-top: 0.15rem;
}
.searchItem.active {
  background: linear-gradient(90deg, #f5f5f5 0%, rgba(247, 246, 251, 0) 100%);
}
.collectButton {
  width: 0.78rem;
  height: 0.36rem;
  line-height: 0.36rem;
  background: #f46600;
  border-radius: 0.04rem;
  text-align: center;
  color: #ffffff;
  font-size: 0.14rem;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}
.searchIcon {
  visibility: hidden;
  opacity: 0;
}
.active .searchIcon {
  visibility: visible;
  opacity: 1;
}
.searchItem:hover .collectButton {
  visibility: visible;
  opacity: 1;
}
.searchItem:hover {
  background: linear-gradient(90deg, #f5f5f5 0%, rgba(247, 246, 251, 0) 100%);
}
.searchItem {
  display: flex;
  justify-content: space-between;
  padding-left: 0.1rem;
  cursor: pointer;
}
.popRight {
  width: 3.5rem;
}
.collectPopBox {
  display: flex;
  justify-content: space-between;
}
.imgBox {
  width: 2.2rem;
  max-height: 3.6rem;
  overflow: hidden;
}
.imgBox img {
  width: 100%;
}

.yyCollectBox {
  border-top: 2px dashed WhiteSmoke;
}
</style>