<!--
 * @FilePath: \zhishe-pc\src\components\navComeDown\PhotoComeDown.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-25 14:21:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2021-12-10 16:07:57
-->
<!--图库下拉框组件-->
<template>
  <div class="comeDownBox">
    <div class="contentBox">
      <ComeDownMiddle  :link="link" :classList="classList"></ComeDownMiddle>
    </div>
  </div>
</template>

<script>
import ComeDownMiddle from "@/components/navComeDown/ComeDownMiddle";
import { getNavigation } from "../../Api";
export default {
  name: "PhotoComeDown",
  props:[
    "link"
  ],
  data() {
    return {
      comeDownBannerRight: {},
      classList: {},
    };
  },
  components: {
    ComeDownMiddle,
  },
  created() {
    getNavigation(2381).then((res) => {
      this.classList = res.data;
    });
  },
};
</script>

<style scoped>
.comeDownBox {
  height: 4.27rem;
  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(184, 184, 184, 0.16);
  opacity: 1;
  display: flex;
  justify-content: space-between;
  padding: 0.3rem ;
}
.imgBox {
  width: 2.48rem;
  height: 3.67rem;
  border-radius: 8px;
  overflow: hidden;
}

.imgBox img {
  width: 2.48rem;
  height: 3.67rem;
  border-radius: 8px;
  transition: all 0.4s;
  -moz-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
}
.imgBox img:hover {
  transform: scale(1.2);
}

.contentBox {
  /* width: 80px; */
  /* height: 407px; */
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
  display: flex;
	margin: 0 auto;
}

.rightBox {
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
  display: flex;
}

.rightBox {
  display: flex;
  flex-direction: column;
}
.rightBox a > img:first-child {
  margin-bottom: 0.29rem;
}
.rightBox a > img {
  width: 2.65rem;
  height: 1.69rem;
  opacity: 1;
  border-radius: 8px;
  cursor: pointer;
}
</style>