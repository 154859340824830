<!--
 * @FilePath: \zsw_new_edition\src\components\loginPop\index.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2022-02-09 15:21:17
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-02-16 09:25:03
-->
<template>
  <div class="confirm">
    <el-dialog
      :visible.sync="dialogVisible"
      width="7.49rem"
      @close="clear"
    >
      <div class="loginBox">
        <div class="loginBg">
          <img src="@/assets/image/login/bck.png" alt="" class="bgImg" />
        </div>
        <div class="loginFormBox">
          <LoginForm
            @loginSucceed="getUserInfo"
            @changeLoginForm="changeLoginForm"
            v-if="loginType == 'account'"
          ></LoginForm>
          <ForgetForm
            @loginSucceed="getUserInfo"
            @changeLoginForm="changeLoginForm"
            v-if="loginType == 'forget'"
          ></ForgetForm>
          <CodeForm
            @loginSucceed="getUserInfo"
            @changeLoginForm="changeLoginForm"
            v-if="loginType == 'code'"
          ></CodeForm>
          <RegisterForm
            @registerSucceed="getUserInfo"
            @changeLoginForm="changeLoginForm"
            v-if="loginType == 'register'"
          ></RegisterForm>
          <WxForm
            @loginSucceed="getUserInfo"
            @changeLoginForm="changeLoginForm"
            v-if="loginType == 'wx'"
            ref="wx"
          ></WxForm>
          <div class="formBottom">
            <p>登录即表示您同意并遵守知设网</p>
            <p>
              <span @click="goLink(1)">《用户使用协议》</span> 、<span
                @click="goLink(2)"
                >《隐私政策》</span
              >、<span @click="goLink(3)">《会员服务协议》</span>
            </p>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
import { userCheckTokenState } from "../../Api";
import LoginForm from "./loginForm";
import ForgetForm from "./forgetForm";
import CodeForm from "./codeForm";
import RegisterForm from "./registerForm";
import WxForm from "./wxForm";
import store from "@/store";
export default {
  components: {
    LoginForm,
    ForgetForm,
    CodeForm,
    RegisterForm,
    WxForm
  },
  data() {
    return {
      loginType: "wx",
      dialogVisible: false,
    };
  },
  methods: {
    clear() {
      this.$refs.wx.clear()
    },
    goLink(active) {
      window.open("/#/other/index?type=2&activeName=" + active, "_blank");
    },
    //切换登录方式
    changeLoginForm(type) {
      this.loginType = type;
    },
    show() {
      if (this.register) {
        this.changeLoginForm("register");
      }
      //判断是否是绑定账号
      if (this.bind) {
        this.changeLoginForm("bind");
        this.dialogVisible = true;
      } else {
        //先验证是否已登录或token是否过期
        const hasToken = getToken();
        return new Promise((resolve) => {
          if (hasToken) {
            // 已登录
            userCheckTokenState(hasToken).then((res) => {
              const tokenState = res.data.message;
              if (tokenState == "expired" || tokenState == "pleaseFillInInfo") {
                // token过期
                store.dispatch("user/logout"); // 先删除token（这个写法我也不知道正确否）
                this.dialogVisible = true;
              } else {
                const hasGetUserInfo = store.getters.userInfo; // 用户信息是否在vuex（刷新保持数据完整）
                if (hasGetUserInfo) {
                  this.dialogVisible = false;
                  if (this.isRouter) this.next();
                } else {
                  // vuex中没有用户数据
                  this.getUserInfo();
                }
                resolve({ isLogin: true });
              }
            });
          } else {
            // 未登录
            store.dispatch("user/logout"); // 先删除token（这个写法我也不知道正确否）
            this.dialogVisible = true;
            resolve({ isLogin: false });
          }
        });
      }
    },
    // 获取登录用户信息
    getUserInfo() {
      store.dispatch("user/getInfo").then(() => {
        this.dialogVisible = false;
        if (this.isRouter) this.next();
      });
    },
  },
};
</script>

<style scoped>
::v-deep .el-dialog {
  display: flex;
  display: -ms-flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
  border-radius: 10px;
}
::v-deep .el-dialog__headerbtn {
  top: 0.26rem;
  right: 0.26rem;
  z-index: 10000;
}
.loginBox {
  display: flex;
	
	height: 5rem;
}
.loginFormBox {
  flex: 1;
  padding-bottom: 0.15rem;
  width: 4.47rem;
  position: relative;
}
.loginBg {
  width: 3.02rem;
  display: flex;
  z-index: 0;
  position: relative;
}
.bgImg {
  width: 100%;
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-dialog__body {
  padding: 0;
}
.formBox {
  margin: 0.3rem 0.8rem 0.74rem 0.8rem;
}
.formBox p {
  font-size: 0.2rem;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #040404;
  line-height: 0.28rem;
  text-align: center;
  margin-bottom: 0.3rem;
}

.formBottom {
  position: absolute;
  bottom: 0.2rem;
  width: 100%;
}
.formBottom p {
  text-align: center;
  font-size: 0.12rem;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  line-height: 0.17rem;
  cursor: pointer;
}

.formBottom p:nth-child(2) {
  color: #f46600;
}
</style>