/*
 * @FilePath: \zsw_new_edition\src\router\modules\upload.js
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2022-01-11 10:24:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-02-07 11:30:51
 */
// 上传页路由列表
import Layout from "@/layout";

const uploadRouter = {
  path: "/upload",
  component: Layout,
  redirect: "/upload/uploadWork",
  name: "Upload",
  meta: { title: "上传" },
  children: [
    {
      path: "uploadWork",
      component: () => import("@/views/upload/uploadWork"),
      name: "UploadWork",
      meta: { activeMenu: "/upload", is_verify: true, title: "上传作品" },
    },
    {
      path: "myUpload",
      component: () => import("@/views/upload/myUpload"),
      name: "MyUpload",
      meta: { activeMenu: "/upload", is_verify: true, title: "我的上传" },
    },
    {
      path: "teacherList",
      component: () => import("@/views/upload/teacherList"),
      name: "TeacherList",
      meta: { activeMenu: "/upload", is_verify: true, title: "老师列表" },
    },
  ],
};
export default uploadRouter;
