<template>
  <div class="centerSidebarBox">
    <div class="centerSidebarUser">
      <div class="sidebarUserAvatarBox">
        <router-link :to="{ path: '/personalCenter/myAccountSet' }">
          <div class="sidebarUserAvatar">
            <img :src="userInfo.avatar" alt="" />
          </div>
          <img
            class="sidebarUserEditor"
            src="@/assets/image/icon/editor-icon.png"
            alt=""
          />
        </router-link>
      </div>
      <p class="userName">{{ userInfo.name }}</p>
      <p class="c_33 f14 mt10">ID：{{ userInfo.uid }}</p>
      <div
        class="userVipStatus"
        v-if="vipStatus.is_class_vip || vipStatus.is_library_vip"
      >
        <img src="@/assets/image/icon/centerVip-icon.png" alt="" />
        <p>VIP会员</p>
      </div>
      <div class="userVipStatus" v-else>
        <!-- <img src="@/assets/image/icon/centerVip-icon.png" alt="" /> -->
        <span>普通会员</span>
      </div>
      <div
        class="openVip"
        @click="goLink()"
        v-if="!vipStatus.is_class_vip && !vipStatus.is_library_vip"
      >
        开通VIP会员
      </div>
    </div>
    <ul class="sidebarList">
      <router-link
        v-for="(item, index) in routeList"
        :key="index"
        tag="li"
        class="sidebarItem"
        :class="{ active: activePath == item.name }"
        :to="{ path: item.path }"
      >
        <div class="sidebarItemD">
          <i class="iconfont f22 mr20" v-html="item.meta.icon"></i>
          <span>{{ item.meta.title }}</span>
        </div>
      </router-link>
    </ul>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { getToken } from "../../utils/auth";
import { userIsVip, isVipAuthor } from "../../Api";
export default {
  name: "PersonalCenterSidebar",
  data() {
    return {
      routeList: [],
      vipStatus: {},
    };
  },
  created() {
    userIsVip(getToken()).then((res) => {
      this.vipStatus = res.data;
    });
    let routeList = this.$router.options.routes.find(
      (item) => item.name == "PersonalCenter"
    ).children;
    routeList.filter((item) => {
      if (item.meta.isHidde) {
        routeList.splice(routeList.indexOf(item), 1);
      }
    });
    //判断是否是vip作者
    isVipAuthor(getToken()).then((res) => {
      if (res.data.res == 0) {
        routeList = routeList.filter((item) => {
          if (item.meta.title != '密码设置') {
            return item
          }
        });
      }
      this.routeList = routeList;
    });
  },
  computed: {
    activePath() {
      const route = this.$route;
      const { name } = route;
      return name;
    },
    ...mapGetters(["userInfo"]),
  },
  methods: {
    goLink() {
      const { href } = this.$router.resolve({
        path: "/vip/material",
      });
      window.open(href, "_blank");
    },
  },
};
</script>
<style scoped>
.openVip {
  width: 1.4rem;
  height: 0.36rem;
  line-height: 0.36rem;
  text-align: center;
  border-radius: 0.04rem;
  background: #f46600;
  color: #ffffff;
  font-size: 0.14rem;
  cursor: pointer;
  font-weight: bold;
  margin: 0.3rem auto 0;
}
.centerSidebarBox {
  margin-bottom: 0.3rem;
}
.sidebarItemD {
  display: flex;
  align-items: center;
  font-size: 0.16rem;
  cursor: pointer;
  padding-left: 00.6rem;
  border-left: 0.02rem solid #ffffff00;
  transition: 500ms ease-out;
}
.sidebarItemD span {
  font-weight: bold;
}
.sidebarItem.active {
  background: #f46600;
  color: #ffffff;
}
.sidebarItem {
  height: 0.62rem;
  padding: 0.2rem 0;
  display: flex;
  color: #040404;
}
.sidebarItem.active .sidebarItemD:hover {
  color: #ffffff;
}
.sidebarItem .sidebarItemD:hover {
  color: #f46600;
  border-left: 0.02rem solid #f46600;
}
.sidebarList {
  margin-top: 0.2rem;
  padding-top: 00.1rem;
  padding-bottom: 0.1rem;
  background: #ffffff;
  border-radius: 0.04rem;
}
.userVipStatus {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.4rem;
}
.userVipStatus img {
  width: 0.24rem;
  height: 0.24rem;
  margin-right: 0.1rem;
}
.userVipStatus p {
  color: #f0c762;
  font-size: 0.14rem;
  font-weight: bold;
}
.userVipStatus span {
  color: #040404;
  font-size: 0.14rem;
  font-weight: bold;
}
.userName {
  color: #040404;
  font-size: 00.18rem;
  font-weight: bold;
  margin-top: 0.2rem;
}
.sidebarUserEditor {
  width: 0.24rem;
  height: 00.24rem;
  position: absolute;
  top: -0.1rem;
  right: 0.9rem;
  cursor: pointer;
}
.sidebarUserAvatar {
  width: 0.64rem;
  height: 0.64rem;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 0px 0.03rem 0.06rem rgb(102 102 102 / 20%);
}
.sidebarUserAvatar img {
  width: 0.64rem;
  height: 0.64rem;
}
.sidebarUserAvatarBox {
  position: relative;
}
.centerSidebarUser {
  padding-top: 0.4rem;
  padding-bottom: 0.6rem;
  background: #ffffff;
  border-radius: 0.04rem;
  text-align: center;
}
</style>