<!--
 * @FilePath: \zsw_new_edition\src\layout\componts\Footer.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-23 14:04:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-01-13 20:50:35
-->
<template>
  <div>
    <div class="footerDiv" :style="bck">
      <div class="top">
        <img src="../../assets/image/footer/logo.png" alt="" />
        <ul>
          <router-link :to="{ path: '/other/index', query: { type: 1 } }">
            <li>关于知设</li>
          </router-link>
          <router-link :to="{ path: '/other/index', query: { type: 2 } }">
            <li>知设协议</li>
          </router-link>
          <router-link :to="{ path: '/other/index', query: { type: 3 } }">
            <li>常见问题</li>
          </router-link>
          <router-link :to="{ path: '/other/index', query: { type: 4 } }">
            <li>意见反馈</li>
          </router-link>
          <router-link :to="{ path: '/other/index', query: { type: 5 } }">
            <li>平台集锦</li>
          </router-link>
          <router-link :to="{ path: '/sign/index' }">
            <li>签约老师</li>
          </router-link>
          <a href="https://www.zhishew.com/recruitment?type=1" target="_blank">
            <li>知设招聘</li>
          </a>
        </ul>
      </div>
      <div class="border"></div>
      <div class="bot">
        <div class="content">
          <p>
            免责声明：本网站内容由用户自行上传，如发现有任何侵权相关问题，请联系我们处理。
          </p>
          <p>
            <span>友情链接：渲云-云渲染平台</span>
            <span>天艺集团</span>
            <span>Sketchup坯子库</span>
          </p>
          <p>
            <span>
							<a style="color: #d9d9d9;" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">
								蜀ICP备15024973号-2
							</a>
						</span>
            <span>CopyRight © 2021 www.zhishew.com.All Right Reserved</span>
            <span>知设网版权所有</span>
          </p>
        </div>
        <div class="service">
          <div class="sbox">
            <div>
              <img :src="vipImg1" alt="" />
            </div>
            <p>课程VIP咨询服务</p>
          </div>
          <div class="sbox">
            <div>
              <img :src="vipImg2" alt="" />
            </div>
            <p>素材VIP咨询服务</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getBanner } from "../../Api";
export default {
  name: "Footer",
  data() {
    return {
      bck: {
        backgroundImage:
          "url(" + require("../../assets/image/footer/bck.png") + ")",
      },
      vipImg1: "",
      vipImg2: "",
    };
  },
  created() {
    getBanner({ position: 1133 }).then((res) => {
      let data = res.data;
      for (let i = 0; i < data.length; i++) {
        if (data[i].cate_id == 1137) {
          this.vipImg1 = data[i].src;
        }
        if (data[i].cate_id == 1138) {
          this.vipImg2 = data[i].src;
        }
      }
    });
  },
};
</script>

<style scoped>
.footerDiv {
  width: 100%;
  height: 2.75rem;
  border-top: 1px dashed #d9d9d9;
  padding: 0.3rem 2.8rem 0 2.8rem;
  overflow: hidden;
}

.top {
  display: flex;
  margin-bottom: 0.3rem;
  justify-content: space-between;
}

.border {
  border: 1px solid #666666;
}

.top img {
  width: 1.86rem;
  height: 0.52rem;
}

.top ul {
  margin-left: 1.27rem;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 10rem;
}

.top li {
  width: 0.64rem;
  height: 0.22rem;
  font-size: 0.16rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
  opacity: 1;
}

.bot {
  display: flex;
  margin-bottom: 0.35rem;
  justify-content: space-between;
}

.content {
  margin-top: 0.37rem;
}

.content p {
  height: 0.17rem;
  font-size: 0.12rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #d9d9d9;
  opacity: 1;
}

.content p > span:not(:first-child) {
  margin-left: 0.4rem;
}

.content p:not(:first-child) {
  margin-top: 0.2rem;
}

.service {
  display: flex;
  margin: 0.26rem 0 0.25rem 0;
}

.service p {
  height: 0.22rem;
  font-size: 0.16rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #d9d9d9;
  opacity: 1;
}

.service div > div {
  width: 0.8rem;
  height: 0.8rem;
  background: #ffffff;
  opacity: 1;
  border-radius: 2px;
  margin: 0 0.21rem 0.1rem 0.21rem;
}

.sbox:not(:first-child) {
  margin-left: 0.6rem;
}
.sbox img {
  width: 0.8rem;
  height: 0.8rem;
}
</style>