import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
import Layout from "@/layout";
import uploadRouter from "./modules/upload";
import personalCenter from "./modules/personalCenter";
import { columnListNew, getMyInfo } from "../Api";
import { getToken } from "../utils/auth";
//路由列表
export const constantRoutes = [
  {
    name: "Home",
    path: "/",
    component: Layout,
    redirect: "/index",
    meta: { title: "首页" },
    isShow: true,
    children: [
      {
        path: "index",
        component: () => import("@/views/home/index"),
        name: "HomeIndex",
        meta: { activeMenu: "/", title: "首页" },
      },
    ],
  },
  {
    name: "TypeCourse",
    path: "/typeCourse",
    component: Layout,
    redirect: "/typeCourse/index",
    meta: { title: "课程" },
    isShow: false,
    children: [
      {
        path: "index",
        component: () => import("@/views/typeCourse/index"),
        name: "TypeCourseIndex",
        meta: { activeMenu: "/typeCourse", title: "课程" },
      },
    ],
  },
  {
    name: "TeacherCourse",
    path: "/teacherCourse",
    component: Layout,
    redirect: "/teacherCourse/index",
    meta: { title: "课程" },
    isShow: false,
    children: [
      {
        path: "index",
        component: () => import("@/views/teacherCourse/index"),
        name: "TeacherCourseIndex",
        meta: { activeMenu: "/teacherCourse", title: "课程" },
      },
    ],
  },
  {
    name: "Course",
    path: "/course",
    component: Layout,
    redirect: "/course/index",
    meta: { title: "课程" },
    isShow: true,
    children: [
      {
        path: "index",
        component: () => import("@/views/course/index"),
        name: "CourseIndex",
        meta: { activeMenu: "/course", title: "课程" },
      },
      {
        path: "detail",
        component: () => import("@/views/course/detail"),
        name: "CourseDetail",
        meta: { activeMenu: "/course", title: "课程详情" },
      },
    ],
  },
  {
    name: "Su",
    path: "/su",
    component: Layout,
    redirect: "/su/index",
    meta: { title: "SU模型" },
    isShow: true,
    children: [
      {
        path: "index",
        component: () => import("@/views/su/index"),
        name: "SuIndex",
        meta: { activeMenu: "/su", title: "SU模型" },
      },
      {
        path: "detail",
        component: () => import("@/views/su/detail"),
        name: "SuDetail",
        meta: { activeMenu: "/su", title: "SU模型详情" },
      },
    ],
  },
  {
    name: "Max",
    path: "/max",
    component: Layout,
    redirect: "/max/index",
    meta: { title: "3D模型" },
    isShow: true,
    children: [
      {
        path: "index",
        component: () => import("@/views/max/index"),
        name: "MaxIndex",
        meta: { activeMenu: "/max", title: "3D模型" },
      },
      {
        path: "detail",
        component: () => import("@/views/max/detail"),
        name: "MaxDetail",
        meta: { activeMenu: "/max", title: "3D模型详情" },
      },
    ],
  },
  {
    name: "SchemeText",
    path: "/schemeText",
    component: Layout,
    redirect: "/schemeText/index",
    meta: { title: "方案文本" },
    isShow: true,
    children: [
      {
        path: "index",
        component: () => import("@/views/schemeText/index"),
        name: "SchemeTextIndex",
        meta: { activeMenu: "/schemeText", title: "方案文本" },
      },
      {
        path: "detail",
        component: () => import("@/views/schemeText/detail"),
        name: "SchemeTextDetail",
        meta: { activeMenu: "/schemeText", title: "方案文本" },
      },
    ],
  },
  {
    name: "Material",
    path: "/material",
    component: Layout,
    redirect: "/material/index",
    meta: { title: "资料库" },
    isShow: true,
    children: [
      {
        path: "index",
        component: () => import("@/views/material/index"),
        name: "MaterialIndex",
        meta: { activeMenu: "/material", title: "资料库" },
      },
      {
        path: "detail",
        component: () => import("@/views/material/detail"),
        name: "MaterialDetail",
        meta: { activeMenu: "/material", title: "资料库详情" },
      },
    ],
  },
  {
    name: "Photo",
    path: "/photo",
    component: Layout,
    redirect: "/photo/index",
    meta: { title: "图库" },
    isShow: true,
    children: [
      {
        path: "index",
        component: () => import("@/views/photo/index"),
        name: "PhotoIndex",
        meta: { activeMenu: "/photo", title: "图库" },
      },
      {
        path: "typeIndex",
        component: () => import("@/views/photo/typeIndex"),
        name: "PhotoTypeIndex",
        meta: { activeMenu: "/photo", title: "图库" },
      },
      {
        path: "detail",
        component: () => import("@/views/photo/detail"),
        name: "PhotoDetail",
        meta: { activeMenu: "/photo", title: "图库详情" },
      },
			{
				path: "album",
				component: () => import("@/views/photo/album"),
				name: "photoAlbum",
				meta: { activeMenu: "/photo", title: "专辑详情" },
			},
    ],
  },
	{
		name: "Chartlet",
		path: "/chartlet",
		component: Layout,
		redirect: "/chartlet/index",
		meta: { title: "贴图" },
		isShow: true,
		children: [
			{
				path: "typeChartlet",
				component: () => import("@/views/chartlet/typeChartlet"),
				name: "TypeChartlet",
				meta: { activeMenu: "/chartlet", title: "贴图" },
			},
			{
				path: "index",
				component: () => import("@/views/chartlet/index"),
				name: "ChartletIndex",
				meta: { activeMenu: "/chartlet", title: "贴图" },
			},
			{
				path: "detail",
				component: () => import("@/views/chartlet/detail"),
				name: "ChartletDetail",
				meta: { activeMenu: "/chartlet", title: "贴图详情" },
			},
			{
				path: "album",
				component: () => import("@/views/chartlet/album"),
				name: "CourseAlbum",
				meta: { activeMenu: "/chartlet", title: "专辑详情" },
			},
		],
	},
  {
    name: "Ps",
    path: "/ps",
    component: Layout,
    redirect: "/ps/index",
    meta: { title: "PS素材" },
    isShow: true,
    children: [
      {
        path: "index",
        component: () => import("@/views/ps/index"),
        name: "PsIndex",
        meta: { activeMenu: "/ps", title: "PS素材" },
      },
      {
        path: "detail",
        component: () => import("@/views/ps/detail"),
        name: "PsDetail",
        meta: { activeMenu: "/ps", title: "PS素材详情" },
      },
    ],
  },
  {
    name: "Software",
    path: "/software",
    component: () => import("@/views/software/index"),
    redirect: "/software/index",
    meta: { title: "免费软件" },
    isShow: true,
    children: [
      {
        path: "index",
        component: () => import("@/views/software/index"),
        name: "SoftwareIndex",
        meta: { activeMenu: "/software", title: "免费软件" },
      },
    ],
  },
  {
    name: "software",
    path: "/software",
    component: () => import("@/views/software/uploadSoftware"),
    redirect: "/software/uploadSoftware",
    meta: { title: "上传免费软件" },
    isShow: true,
    children: [
      {
        path: "uploadSoftware",
        component: () => import("@/views/software/uploadSoftware"),
        name: "UploadSoftwareIndex",
        meta: { activeMenu: "/uploadSoftware", title: "上传免费软件" },
      },
    ],
  },
  {
    name: "software",
    path: "/software",
    component: () => import("@/views/software/softwareList"),
    redirect: "/software/softwareList",
    meta: { title: "免费软件" },
    isShow: true,
    children: [
      {
        path: "softwareList",
        component: () => import("@/views/software/softwareList"),
        name: "SoftwareListIndex",
        meta: { activeMenu: "/softwareList", title: "免费软件" },
      },
    ],
  },
  {
    name: "software",
    path: "/software",
    component: () => import("@/views/software/softwareDetail"),
    redirect: "/software/softwareDetail",
    meta: { title: "免费软件" },
    isShow: true,
    children: [
      {
        path: "softwareDetail",
        component: () => import("@/views/software/softwareDetail"),
        name: "SoftwareDetailIndex",
        meta: { activeMenu: "/softwareDetail", title: "免费软件" },
      },
    ],
  },
  {
    name: "software",
    path: "/software",
    component: () => import("@/views/software/softwareOrder"),
    redirect: "/software/softwareOrder",
    meta: { title: "远程订单" },
    isShow: true,
    children: [
      {
        path: "softwareOrder",
        component: () => import("@/views/software/softwareOrder"),
        name: "SoftwareOrderIndex",
        meta: { activeMenu: "/softwareOrder", title: "远程订单" },
      },
    ],
  },
	{
		name: "Competition",
		path: "/competition",
		component: Layout,
		redirect: "/competition/index",
		meta: { title: "设计大赛" },
		isShow: true,
		children: [
			{
				path: "index",
				component: () => import("@/views/competition/index"),
				name: "CompetitionIndex",
				meta: { activeMenu: "/competition", title: "设计大赛" },
			},
      {
        path: "detail",
        component: () => import("@/views/competition/detail"),
        name: "CompetitionDetail",
        meta: { activeMenu: "/competition", title: "设计大赛详情" },
      },
      {
        path: "uploadCompetition",
        component: () => import("@/views/competition/uploadCompetition"),
        name: "UploadCompetition",
        meta: { activeMenu: "/competition", title: "添加设计竞赛" },
      },
		],
	},
  {
    name: "User",
    path: "/user",
    component: Layout,
    redirect: "/user/index",
    meta: { title: "门户中心主页" },
    isShow: false,
    children: [
      {
        path: "index",
        component: () => import("@/views/user/index"),
        name: "UserIndex",
        meta: { activeMenu: "/user", title: "门户中心主页" },
      },
    ],
  },
  {
    name: "Sign",
    path: "/sign",
    component: Layout,
    redirect: "/sign/index",
    meta: { title: "签约入驻" },
    isShow: false,
    children: [
      {
        path: "index",
        component: () => import("@/views/sign/index"),
        name: "SignIndex",
        meta: { activeMenu: "/sign", title: "签约" },
      },
      {
        path: "personalSign",
        component: () => import("@/views/sign/personalSign"),
        name: "PersonalSign",
        meta: { activeMenu: "/sign", is_verify: true, title: "个人签约" },
      },
      {
        path: "companySign",
        component: () => import("@/views/sign/companySign"),
        name: "CompanySign",
        meta: { activeMenu: "/sign", is_verify: true, title: "企业签约" },
      },
    ],
  },
  {
    name: "Other",
    path: "/other",
    component: Layout,
    redirect: "/other/index",
    meta: { title: "其他" },
    isShow: false,
    children: [
      {
        path: "index",
        component: () => import("@/views/other/index"),
        name: "OtherIndex",
        meta: { activeMenu: "/other", title: "关于知设" },
      },
    ],
  },
  {
    name: "Vip",
    path: "/vip",
    component: Layout,
    redirect: "/vip/material",
    meta: { title: "VIP" },
    isShow: false,
    children: [
      {
        path: "material",
        component: () => import("@/views/vip/material"),
        name: "VipMaterial",
        meta: { activeMenu: "/vip", title: "素材VIP" },
      },
      {
        path: "course",
        component: () => import("@/views/vip/course"),
        name: "VipCourse",
        meta: { activeMenu: "/vip", title: "课程VIP" },
      },
    ],
  },
  {
    name: "Student",
    path: "/student",
    component: Layout,
    redirect: "/student/index",
    meta: { title: "学生认证" },
    isShow: false,
    children: [
      {
        path: "index",
        component: () => import("@/views/student/index"),
        name: "StudentIndex",
        meta: { activeMenu: "/index", title: "学生认证" },
      },
    ],
  },
  { path: "/mainBody", redirect: "/" },
  personalCenter,
  uploadRouter,
];
//创建路由
const createRouter = () =>
  new Router({
    mode: "hash",
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });
const router = createRouter();
//重置路由
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher;
}
//获取原型对象上的push函数
const originalPush = Router.prototype.push;
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
//路由守卫
router.beforeEach((to, from, next) => {
  if (to.meta.activeMenu != "/personalCenter") {
    //判断是发布或签约 需要绑定手机号
    let bindPop = Vue.prototype.$bindTelPop()
    if (to.path == '/upload/uploadwork' || to.path == '/sign/personalSign' || to.path == '/sign/companySign') {
      getMyInfo(getToken()).then((res) => {
        if (!res.data.user_name || res.data.error == -1) {
          bindPop.show(true)
          next(false);
        }else{
          next();
        }
      })
    } else {
      bindPop.close()
      next();
    }
  } else {
    let title = to.meta.title;
    let pop = Vue.prototype.$secretPop();
    let timer = "";
    let nextStatus = true;
    //先判断是否是密码设置
    if (title == "密码设置") {
      //弹出密码框
      pop.show();
      next(false);
      //定时查看弹框
      timer = setInterval(() => {
        if (!pop.centerDialogVisible) clearInterval(timer);
        if (pop.success) {
          next();
          clearInterval(timer);
        }
      }, 1000);
    } else {
      //获取路由列表
      columnListNew(getToken()).then((res) => {
        if (from.meta.title != "我的收藏详情") {
          //判断路由名称
          Object.keys(res.data).forEach((key) => {
            if (
              res.data[key].title == title &&
              res.data[key].isSet &&
              res.data[key].title != from.meta.title
            ) {
              //弹出密码框
              pop.show(res.data[key].id);
              nextStatus = false;
              next(false);
              //定时查看弹框
              timer = setInterval(() => {
                if (!pop.centerDialogVisible) clearInterval(timer);
                if (pop.success) {
                  next();
                  clearInterval(timer);
                }
              }, 1000);
            }
          });
        }
        if (nextStatus) next();
      });
    }
  }
  document.title = to.meta.title + " - 知设网";
});
export default router;
