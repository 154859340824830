<!--
 * @FilePath: \zsw_new_edition\src\components\navComeDown\CourseComeDown.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-25 14:21:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-01-21 09:41:16
-->
<!--课程下拉框组件-->
<template>
  <div class="comeDownBox">
    <div style="">
      <div class="imgBox">
      <a target="_blank" :href="comeDownBanner.url">
        <img :src="comeDownBanner.src" alt="" />
      </a>
    </div>
    </div>
    <div class="contentBox">
      <ComeDownMiddle :link="link" :classList="classList"></ComeDownMiddle>
    </div>
    <div class="recommendBox">
      <p>推荐：</p>
      <div>
        <div v-for="(item, index) in recommendList" :key="index">
          <router-link
            :to="{ path: '/course/detail?detailId=' + item.id }"
            target="_blank"
          >
            <img :src="item.img" alt="" />
            <p>{{ item.title }}</p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ComeDownMiddle from "@/components/navComeDown/ComeDownMiddle";
import { getBanner, navigationBar,getNavigation } from "../../Api";
export default {
  name: "CourseComeDown",
  components: {
    ComeDownMiddle,
  },
  props:[
    "link"
  ],
  data() {
    return {
      comeDownBanner: [],
      recommendList: [],
      classList:[]
    };
  },
  created() {
    getBanner({
      position: 1054,
    }).then((res) => {
      this.comeDownBanner = res.data[0];
    });
    navigationBar(6).then((res) => {
      this.recommendList = res.data.slice(0, 3);
    });
    getNavigation(6).then((res) => {
      this.classList = res.data;
    });
  },
};
</script>

<style scoped>
.comeDownBox {
  height: 4.27rem;
  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(184, 184, 184, 0.16);
  opacity: 1;
  display: flex;
  justify-content: space-between;
  padding: 0.3rem 2.81rem 0.3rem 2.2rem;
}
.imgBox {
  width: 2.48rem;
  height: 3.67rem;
  border-radius: 8px;
  overflow: hidden;
}

.imgBox img {
  width: 2.48rem;
  height: 3.67rem;
  border-radius: 8px;
  transition: all 0.4s;
  -moz-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
}
.imgBox img:hover {
  transform: scale(1.2);
}

.contentBox {
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
  display: flex;
}

.recommendBox {
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
  display: flex;
  padding-bottom: 0.25rem;
}

.recommendBox > p {
  width: 0.48rem;
  height: 0.2rem;
  font-size: 0.14rem;
  font-family: PingFang SC;
  font-weight: bold;
  /* line-height: 0px; */
  color: #f46600;
  opacity: 1;
  margin-right: 0.1rem;
}

.recommendBox div {
  width: 1.45rem;
  margin-bottom: 0.15rem;
  cursor: pointer;
}

.recommendBox div > div > a > img {
  width: 1.45rem;
  height: 0.87rem;
  opacity: 1;
  border-radius: 4px;
  margin-bottom: 0.1rem;
}

.recommendBox div > div > a > p {
  /* height: 0.17rem; */
  width: 1.45rem;
  font-size: 0.12rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
  opacity: 1;
  margin: 0 0.05rem 0.15rem 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>