<template>
  <el-dialog
    width="9.2rem"
    class="paySucceed"
    :visible.sync="innerVisible"
    v-if="!isPay"
     @close="close()"
    append-to-body
  >
    <div slot="title" v-if="data.balance">
      知币充值 <span class="yue">（余额：{{ data.balance }}知币）</span>
    </div>
    <div class="payBox">
      <div class="left">
        <div
          class="item"
          :class="{ active: activeIndex == index }"
          @click="selectItem(item, index)"
          v-for="(item, index) in zhiCoinList"
          :key="index"
        >
          <div class="discountBox" v-if="item.discount < 1">
            <img src="../../assets/image/icon/discount.png" alt="" />
            <p>{{ (item.discount * 10).toString().replace(".", "") }}折</p>
          </div>
          <img class="zIcon" src="@/assets/image/centerIndexImg2.png" alt="" />
          <p class="c_33 f16">{{ item.zm }}知币</p>
          <p class="price">¥{{ item.rmb }}</p>
        </div>
      </div>
      <div class="right">
        <p class="mt20 f0404 f16">请选择支付方式</p>
        <el-radio-group v-model="payType" class="mt40">
          <el-radio label="0" class="">
            <i class="iconfont alipay-icon">&#xe655;</i>
            <p class="payTxt">支付宝支付</p>
          </el-radio>
          <el-radio label="1" class="">
            <i class="iconfont weCat-icon">&#xe662;</i>
            <p class="payTxt">微信支付</p>
          </el-radio>
        </el-radio-group>
        <div class="erWeiMaBox">
          <div class="payErWeiMa">
            <img class="img" :src="qrCodeSrc" alt="" />
          </div>
          <div class="t_c">
            <p class="priceNum">
              ¥<span style="margin-left: 0.05rem">{{ price }}</span>
            </p>
            <p class="discount">赠送{{ send }}知币</p>
          </div>
        </div>
        <p class="agreement c_p" @click="jump()">
          支付则默认同意《知设网用户协议》
        </p>
      </div>
    </div>
  </el-dialog>
  <el-dialog width="9.2rem" class="paySucceed" :visible.sync="innerVisible" append-to-body  @close="closeSucces()" v-else>
   <div slot="title">知币充值</div>
   <div class="payCont">
     <img src="@/assets/image/icon/addBankSucceed.png" alt="">
     <p class="f24 f_b f0404">恭喜你</p>
     <p class="f24 f_b f0404 mt10">本次交易支付成功</p>
   </div>
 </el-dialog>
</template>

<script>
import { getZhicoinList,haveRecharge } from "../../Api";
import { getToken } from "../../utils/auth";

export default {
  name: "index",
  data() {
    return {
      payType: "0",
      innerVisible: false,
      zhiCoinList: [],
      activeIndex: 0,
      qrCodeSrc: "",
      token: "",
      send: 0,
      price: 0,
      timer:'',
      isPay:false
    };
  },
  methods: {
    jump() {
      window.open("/#/other/index?type=2");
    },
    initData() {
      this.getZhicoinListFun().then(() => {
        this.selectItem(this.zhiCoinList[0], 0);
      });
    },
    //获取获取充值列表
    getZhicoinListFun() {
      return new Promise((resolve) => {
        getZhicoinList().then((res) => {
          this.zhiCoinList = res.data;
          resolve();
        });
      });
    },
    //选择充值
    selectItem(data, index) {
      this.send = data.zm - data.rmb;
      this.activeIndex = index;
      //选择赞赏的价格
      this.price = data.rmb;
      if (this.payType == 0) {
        //支付宝支付
        this.qrCodeSrc =
          this.$ROOT_DIR +
          "/pay/AliPay/generateZmQrCodeImgNew?agent=pc&index=" +
          index +
          "&token=" +
          getToken();
      } else if (this.payType == 1) {
        //微信支付
        this.qrCodeSrc =
          this.$ROOT_DIR +
          "/pay/WeChatPay/generateZmQrCodeImgNew?agent=pc&index=" +
          index +
          "&token=" +
          getToken();
      }
    },
    show() {
      if (!getToken()) {
        this.$login();
      } else {
        this.innerVisible = true;
        this.timer = setInterval(() => {
        this.getRecharge();
      }, 2000);
      }
    },
    getRecharge() {
      haveRecharge({ token: getToken(), type: "recharge" }).then((res) => {
        if (res.data.res == 1) {
          this.isPay = true;
          clearInterval(this.timer);
        }
      });
    },
    close() {
      clearInterval(this.timer);
    },
    closeSucces(){
      this.isPay = false
      location.reload()
    }
  },
  mounted() {
    this.initData();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  watch: {
    payType() {
      this.selectItem(this.zhiCoinList[this.activeIndex], this.activeIndex);
    },
  },
};
</script>

<style scoped>
.agreement {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #666666;
  font-size: 0.14rem;
}
.discount {
  height: 0.3rem;
  width: 1.23rem;
  text-align: center;
  line-height: 0.3rem;
  border: 0.01rem dashed #999999;
  border-radius: 0.16rem;
  margin-top: 0.2rem;
}
.priceNum {
  color: #f46600;
  font-size: 0.4rem;
  font-weight: bold;
}
.erWeiMaBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 3.33rem;
  height: 1.6rem;
  margin: 1.2rem auto 0 auto;
}
.payErWeiMa {
  width: 1.6rem;
  height: 1.6rem;
  box-shadow: 0px 0px 0.06rem rgba(102, 102, 102, 0.2);
}
.payErWeiMa .img {
  width: 100%;
  height: 100%;
  background: coral;
  border-radius: 0.06rem;
}
::v-deep .el-radio-group {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.payTxt {
  font-size: 0.16rem;
  color: #040404;
  margin-left: 0.1rem;
}
::v-deep .el-radio {
  display: flex;
  align-items: center;
}
::v-deep .el-radio__label {
  display: flex;
  align-items: center;
}
.alipay-icon {
  color: #02a9f1;
  font-size: 0.3rem;
}
.weCat-icon {
  color: #09bb07;
  font-size: 0.28rem;
}
.payBox {
  display: flex;
}
.yue {
  color: #694114;
  font-size: 0.14rem;
}
.right {
  flex: 1;
  position: relative;
}
.left {
  width: 4.8rem;
}
.left::after {
  content: "";
  visibility: hidden;
  opacity: 1;
  display: block;
  clear: both;
}
::v-deep .el-dialog__body {
  padding: 0 0.3rem 0.3rem 0.3rem;
}
.price {
  margin-top: 0.2rem;
  color: #ecb054;
  font-size: 0.24rem;
  font-weight: bold;
}
.item {
  width: 2rem;
  height: 1.7rem;
  background: #ffffff;
  border: 0.01rem solid #eeeeee;
  border-radius: 0.06rem;
  text-align: center;
  float: left;
  margin-right: 0.2rem;
  margin-top: 0.2rem;
  cursor: pointer;
  position: relative;
}

.discountBox {
  position: absolute;
  left: 0.1rem;
  top: -5px;
  width: 40px;
  height: 26px;
  z-index: 2;
}
.discountBox img {
  width: 40px;
  height: 26px;
}

.discountBox p {
  position: absolute;
  font-size: 12px;
  font-family: DIN;
  font-weight: bold;
  color: #ffffff;
  opacity: 1;
  top: 3px;
  width: 45px;
}
.item.active {
  background: rgba(254, 223, 135, 0.46);
  border: 0.01rem solid #e09d06;
}
.zIcon {
  margin-top: 0.05rem;
  margin-bottom: 0.05rem;
  width: 0.6rem;
  height: 0.6rem;
}
.payCont {
  text-align: center;
  margin-top: 0.8rem;
  margin-bottom: 1.6rem;
}
.payCont img {
  width: 2.26rem;
  height: 2.24rem;
}
.paySucceed >>> .el-dialog__headerbtn {
  top: 0.2rem;
  right: 0.2rem;
  font-size: 0.22rem;
}
.paySucceed >>> .el-dialog__header {
  text-align: center;
  background: linear-gradient(90deg, #ffeed7 0%, #ffeed7 100%);
  height: 0.7rem;
  font-size: 0.22rem;
  color: #694114;
  font-weight: bold;
}
::v-deep .el-dialog__title {
  font-size: 0.18rem;
}
</style>