<!--
 * @FilePath: \zsw_new_edition\src\components\loginPop\wxForm.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2022-02-09 15:21:17
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-02-16 09:40:43
-->
<template>
  <div>
    <div class="formBox">
      <p class="formBoxTitle">微信登录</p>
      <div class="hintBox">
        扫码并 <span>关注知设网公众号</span> 安全快捷登录
      </div>
      <div class="qrcode">
        <img v-if="qrCodeSrc" :src="qrCodeSrc" alt="" />
      </div>
    </div>
    <div class="border"></div>
    <div class="other">
      <div @click="changeLoginForm('account')">
        <img src="../../assets/image/login/tel.png" alt="" />
        <p>手机号登录</p>
      </div>
      <div @click="changeLoginForm('register')">
        <img src="../../assets/image/login/telMan.png" alt="" />
        <p>手机号注册</p>
      </div>
    </div>
  </div>
</template>

<script>
import { loginQrCode, checkIfSubscribe } from "@/Api";
import store from "@/store";
export default {
  name: "wxForm",
  data() {
    return {
      username: "",
      password: "",
      qrCodeSrc: "",
      qr_scene_str: "",
      timer: null,
    };
  },
  created() {
    loginQrCode().then((res) => {
      this.qrCodeSrc = res.data.qr_code;
      this.qr_scene_str = res.data.qr_scene_str;
      //开始轮序
      this.timer = setInterval(() => {
        this.checkIfSubscribe();
      }, 1000);
    });
  },
  methods: {
    changeLoginForm(type) {
      this.$emit("changeLoginForm", type);
    },
    clear() {
      window.clearInterval(this.timer);
    },
    checkIfSubscribe() {
      checkIfSubscribe({ qr_scene_str: this.qr_scene_str }).then((res) => {
        if (res.data.error == 0) {
          window.clearInterval(this.timer);
          store
            .dispatch("user/wxLogin", { open_id: res.data.data.open_id })
            .then(() => {
              this.$emit("loginSucceed");
            });
        }
      });
    },
  },
  beforeDestroy() {
    window.clearInterval(this.timer);
  },
};
</script>

<style scoped>
::v-deep input::-webkit-input-placeholder {
  font-size: 0.14rem;
}

::v-deep input::-moz-input-placeholder {
  font-size: 0.14rem;
}

::v-deep input::-ms-input-placeholder {
  font-size: 0.14rem;
}
.formBox {
  margin: 0.3rem 0.8rem 0 0.8rem;
}
.formBoxTitle {
  font-size: 0.2rem;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #040404;
  line-height: 0.28rem;
  text-align: center;
  margin-bottom: 0.3rem;
}
.username {
  margin-bottom: 0.1rem;
}
::v-deep .el-input__prefix {
  left: 5px;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-input__prefix p {
  font-size: 0.14rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #040404;
  line-height: 0.2rem;
  padding-right: 0.3rem;
  width: 0.74rem;
}
::v-deep .el-input--prefix .el-input__inner {
  padding-left: 0.74rem;
}
.btn {
  width: 2.87rem;
  height: 0.4rem;
  background: #ff842a;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn p {
  height: 0.2rem;
  font-size: 0.14rem;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ffffff;
  line-height: 0.2rem;
}
.border {
  width: 3.67rem;
  opacity: 1;
  border-bottom: 1px dashed #bfbfbf;
  margin: 0 auto;
  margin-bottom: 0.25rem;
}
.other {
  width: 2.36rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.7rem;
}
.other div {
  display: flex;
  cursor: pointer;
}
.other div p:hover {
  color: #f46600;
}
.other div img {
  width: 0.2rem;
  height: 0.2rem;
  margin-right: 0.06rem;
}
.other div p {
  font-size: 0.14rem;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #040404;
  line-height: 0.2rem;
}
.hintBox {
  width: 3.02rem;
  height: 0.4rem;
  background: #f7f6fb;
  border-radius: 0.22rem;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.14rem;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
}
.hintBox span {
  color: #f46600;
}
.qrcode {
  width: 1.46rem;
  height: 1.46rem;
  background: #d8d8d8;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin: 0.3rem 0 0.4rem 0.78rem;
}
.qrcode img {
  width: 1.46rem;
  height: 1.46rem;
}
</style>