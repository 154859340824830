<template>
  <div>
    <el-dialog
      :visible.sync="centerDialogVisible"
      center
      :destroy-on-close="true"
      :show-close="false"
    >
      <div class="secretBox">
				<div 
					class="InviteRules tag-read" 
					@click="InviteRules"
					:data-clipboard-text="url">
					<img src="../../assets/image/InviteRules.png" alt="邀请好友领取知币">
				</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Clipboard from "clipboard";
import { getInfo } from "../../Api.js"
import { getToken } from "../../utils/auth";
export default {
  name: "picturePop",
  data() {
    return {
			centerDialogVisible: false,
			url: ''
    };
  },
	created() {	
		if (!getToken()) {
			return
		}
		getInfo(getToken()).then((res) => {
			this.url = window.location.host + '/#/?inviteUid=' + res.data.uid
		})
	},
  methods: {
    show() {
      this.centerDialogVisible = true;
    },
		InviteRules() {
      //先判断是否登录
      if (!getToken()) {
        this.$login();
        return
      }
			let clipboard = new Clipboard(".tag-read");
			clipboard.on("success", () => {
				this.$message.success("邀请链接复制成功，快去邀请吧");
				clipboard.destroy();
			});
			clipboard.on("error", () => {
				this.$message.error("邀请链接复制失败，请切换浏览器重试");
				clipboard.destroy();
			});
    }
  },
};
</script>

<style scoped>
.secretBox {
  position: relative;
	width: 4.475rem;
  margin: 0 auto;
  opacity: 1;
	overflow: hidden;
}
.InviteRules img {
	display: block;
	width: 4.275rem;
	margin: 0 auto;
}
::v-deep .el-input {
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 270px;
  height: 38px;
  background: #eeeeee;
  opacity: 1;
  border-radius: 4px;
}

::v-deep .el-input__inner {
  -webkit-appearance: none;
  background: #eeeeee;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}

::v-deep .el-button--primary:first-child {
  width: 120px;
  background-color: #ffffff;
  border-color: #d9d9d9;
  color: #999999;
}
::v-deep .el-button--primary:last-child {
  width: 120px;
  background-color: #ff9e58;
  border-color: #ff9e58;
}

::v-deep .el-dialog {
  background: rgba(0, 0, 0, 0);

	width: 5rem;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0);

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0);
}
::v-deep .el-dialog__headerbtn {
    position: absolute;
    top: 69px;
    right: 175px;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    font-size: 16px;
    z-index: 1000;
}
</style>