/*
 * @FilePath: \zhishe-pc\src\components\loginPop\index.js
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-23 14:04:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2021-12-23 10:09:43
 */
import Vue from 'vue';
import login from './index.vue';

let confirmInstance;
const ConfirmConstructor = Vue.extend(login);  // 使用extend方法创建类似Vue的构造函数

const initInstance = () => {
    confirmInstance = new ConfirmConstructor();
    document.body.appendChild(confirmInstance.$mount().$el);
    // 实例化后手动挂载，得到$el真实Dom，将其添加到body最后
}

export default options => { // options 为可配置选项
    if (!confirmInstance) {
        initInstance(); // 未挂载就进行挂载
    }
    Object.assign(confirmInstance, options); // 该操作可以修改confirmInstance,将新的配置data挂载到this下

    return new Promise(resolve => {
        confirmInstance.show().then(res=>{
            resolve(res)
            confirmInstance = null;  // 调用之后即清空
            document.getElementsByClassName('loginBox')
        })
    })
}

