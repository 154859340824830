<template>
  <el-dialog
      :close-on-click-modal="false"
    width="6.7rem"
    class="paySucceed"
    :visible.sync="innerVisible"
    @close="close"
    append-to-body
      v-if="innerVisible"
  >
    <div slot="title">
      {{ paramss.title }}
    </div>
    <div class="payBox">
      <div class="left">
        <p class="mt30">A: 请先下载好相关软件或系统</p>
        <p>B: 选择支付所需的远程费用</p>
        <p>C: 添加工程师QQ号，并发送订单截图</p>
        <div class="notes">注：以下仅为单次远程安装费用，支付后，即可添加软件工程师QQ号</div>
        <div style="margin-top:0.62rem">
          <div class="btn" @click="changeTypeVal(1)" :class="{'btnActive': paramss.type === 1}">远程插件：20元</div>
          <div class="btn" @click="changeTypeVal(2)" :class="{'btnActive': paramss.type === 2}">远程软件：30元</div>
          <div class="btn" @click="changeTypeVal(3)" :class="{'btnActive': paramss.type === 3}">远程系统：50元</div>
        </div>
      </div>
      <div class="middle"></div>
      <div class="right">
        <div style="width:1.84rem;margin:auto">
          <el-radio-group v-model="paramss.payType" class="mt40">
            <el-radio :label="1" class="">
              <!--            <i class="iconfont alipay-icon">&#xe655;</i>-->
              <p class="payTxt">支付宝</p>
            </el-radio>
            <el-radio :label="2" class="">
              <!--            <i class="iconfont weCat-icon">&#xe662;</i>-->
              <p class="payTxt">微信</p>
            </el-radio>
          </el-radio-group>
        </div>
        <div class="erWeiMaBox">
          <div class="payErWeiMa">
            <img
              class="img"
              :src="
                $ROOT_DIR +
                '/pay/' +
                (paramss.payType == 1 ? 'AliPay' : 'WeChatPay') +
                '/softwareQrCode?type=' +
                paramss.type +
                '&token=' +
                token
              "
              alt=""
            />
              <p class="priceNum">
                支付价格： <span style="margin-left: 0.05rem">【{{ paramss.type === 1 ? '20' : paramss.type === 2 ? '30' : paramss.type === 3 ? '50' : '未知'}}元】</span>
              </p>
          </div>
        </div>
        <div class="erWeiMaBox">
          <div class="payErWeiMa">
            <img src="../../assets/image/qr_2.png" class="imgBlur" alt="" v-if="!isPay">
            <img :src="paramss.qqCodeImg" class="img" alt="" v-else>
<!--            <img-->
<!--                class="img"-->
<!--                :src="-->
<!--                $ROOT_DIR +-->
<!--                '/pay/' +-->
<!--                (payType == 1 ? 'AliPay' : 'WeChatPay') +-->
<!--                '/softwareQrCode?type=' +-->
<!--                paramss.type +-->
<!--                '&token=' +-->
<!--                token-->
<!--              "-->
<!--                alt=""-->
<!--            />-->
            <p>
              <span style="margin-left: 0.05rem;color: rgba(0, 0, 0, 1);
font-size: 0.12rem;
font-weight: 400;">扫码添加工程师QQ号</span>
            </p>
          </div>
        </div>
<!--        <p class="agreement c_p" @click="jump()">-->
<!--          支付则默认同意《知设网用户协议》-->
<!--        </p>-->
      </div>
    </div>
  </el-dialog>
<!--  <el-dialog-->
<!--    width="9.2rem"-->
<!--    class="paySucceed"-->
<!--    :visible.sync="innerVisible"-->
<!--    append-to-body-->
<!--    @close="closeSucces()"-->
<!--    v-else-->
<!--  >-->
<!--    <div slot="title">远程安装教程</div>-->
<!--    <div class="payCont">-->
<!--      <img src="@/assets/image/icon/addBankSucceed.png" alt="" />-->
<!--      <p class="f24 f_b f0404">恭喜你</p>-->
<!--      <p class="f24 f_b f0404 mt10">本次交易支付成功</p>-->
<!--    </div>-->
<!--  </el-dialog>-->
</template>

<script>
import { getToken } from "../../utils/auth";
import { haveRechargeSoftware } from "../../Api";

export default {
  name: "index",
  data() {
    return {
      paramss:{
        qqCodeImg: '',
        title: "远程安装服务，欢迎你！",
        type: 1,
        payType: 1
      },
      innerVisible: false,
      token: getToken(),
      isPay: false,
      timer: "",
    };
  },
  methods: {
    jump() {
      window.open("/#/other/index?type=2");
    },
    changeTypeVal(val){
      this.paramss.type = val
    },
    show() {
      this.innerVisible = true;
      this.timer = setInterval(() => {
        this.getRecharge();
      }, 2000);
    },
    getRecharge() {
      haveRechargeSoftware({ token: getToken()}).then((res) => {
        if (res.data.res == 1) {
          this.$message.success('本次支付成功')
          this.isPay = true;
          this.paramss.qqCodeImg = res.data.qq_qr_code
          clearInterval(this.timer);
        }
      });
    },
    close() {
      clearInterval(this.timer);
    },
    closeSucces(){
      this.isPay = false
      location.reload()
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
.agreement {
  color: #666666;
  font-size: 0.14rem;
  margin-top: 0.6rem;
}
.discount {
  height: 0.3rem;
  width: 1.23rem;
  text-align: center;
  line-height: 0.3rem;
  border: 0.01rem dashed #999999;
  border-radius: 0.16rem;
  margin-top: 0.2rem;
}
.priceNum {
  color: rgba(255, 87, 51, 1);
  font-size: 0.14rem;
  font-weight: 500;
}
.erWeiMaBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 1.8rem;
  margin-top: 0.1rem;
}
.payErWeiMa {
  width: 1.8rem;
  height: 1.4rem;
  /*box-shadow: 0px 0px 0.06rem rgba(102, 102, 102, 0.2);*/
  margin: auto;
  text-align: center;
}
.payErWeiMa .img {
  width: 1.4rem;
  height: 1.4rem;
  background: coral;
  border-radius: 0.06rem;
}
.payErWeiMa .imgBlur {
  width: 1.4rem;
  height: 1.4rem;
  background: coral;
  border-radius: 0.06rem;
  filter:blur(4px);
}
::v-deep .el-radio-group {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.payTxt {
  font-size: 0.16rem;
  color: #040404;
  margin-left: 0.1rem;
}
::v-deep .el-radio {
  display: flex;
  align-items: center;
}
::v-deep .el-radio__label {
  display: flex;
  align-items: center;
}
.alipay-icon {
  color: #02a9f1;
  font-size: 0.3rem;
}
.weCat-icon {
  color: #09bb07;
  font-size: 0.28rem;
}
.payBox {
  display: flex;
  position: relative;
  /* height: 4.27rem; */
}
.yue {
  color: #694114;
  font-size: 0.14rem;
}
.middle{
  width: 0px;
  height: 2rem;
  border: 1px solid rgba(229, 229, 229, 1);
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -1rem;
}
.right {
  flex: 1;
  position: relative;
  padding: 0rem 0.4rem 0.4rem 0.4rem;
}
.left {
  width: 50%;
  padding: 0.1rem 0.4rem 0.4rem 0.4rem;
  /*background-color: rgba(255, 250, 243, 0.5);*/
  border-radius: 0px 0px 0px 6px;
  /*margin-top:20px;*/
}
.left::after {
  content: "";
  visibility: hidden;
  opacity: 1;
  display: block;
  clear: both;
}
::v-deep .el-dialog__body {
  padding: 0;
}
.price {
  margin-top: 0.2rem;
  color: #ecb054;
  font-size: 0.24rem;
  font-weight: bold;
}
.item {
  width: 2rem;
  height: 1.7rem;
  background: #ffffff;
  border: 0.01rem solid #eeeeee;
  border-radius: 0.06rem;
  text-align: center;
  float: left;
  margin-right: 0.2rem;
  margin-top: 0.2rem;
  cursor: pointer;
}
.item.active {
  background: rgba(254, 223, 135, 0.46);
  border: 0.01rem solid #e09d06;
}
.zIcon {
  margin-top: 0.05rem;
  margin-bottom: 0.05rem;
  width: 0.6rem;
  height: 0.6rem;
}
.payCont {
  text-align: center;
  margin-top: 0.8rem;
  margin-bottom: 1.6rem;
}
.payCont img {
  width: 2.26rem;
  height: 2.24rem;
}
.paySucceed >>> .el-dialog__headerbtn {
  top: 0.2rem;
  right: 0.2rem;
  font-size: 0.22rem;
}
.paySucceed >>> .el-dialog__header {
  /*text-align: center;*/
  /*background: linear-gradient(90deg, #ffeed7 0%, #ffeed7 100%);*/
  color: rgba(56, 56, 56, 1);
  font-size: 0.16rem;
  font-weight: 500;
  background: rgba(250, 250, 250, 1);
  border-top-right-radius: 0.17rem;
  border-top-left-radius: 0.17rem;
}

::v-deep .el-dialog__title {
  font-size: 0.18rem;
}

.left p {
  color: rgba(102, 102, 102, 1);
  font-size: 0.14rem;
  font-weight: 500;
  color: #040404;
  opacity: 1;
  margin-bottom: 0.22rem
}
.left>.notes{
  height: 0.3rem;
  color: rgba(166, 166, 166, 1);
  font-size: 0.12rem;
  font-weight: 400;
}
.btn{
  width: 2.2rem;
  height: 0.4rem;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(244, 102, 0, 1);
  border-radius: 0.04rem;
  margin-bottom: 0.2rem;
  text-align: center;
  line-height: 0.4rem;
  color:rgba(244, 102, 0, 1);
  cursor: pointer;
}
.btnActive{
  background:rgba(244, 102, 0, 1) !important;
  color:white
}

/deep/ .el-dialog{
  border-radius:0.08rem
}
</style>