<!--
 * @FilePath: \zsw_new_edition\src\layout\componts\Side.vue
 * @Brief: 侧边导航
 * @Version: 1.0
 * @Date: 2021-11-23 14:04:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-01-20 17:43:18
-->
<template>
  <div class="sideBox" v-if="navFixed">
    <!-- <div class="student">
      <img src="../../assets/image/side/student.jpg" alt="" />
    </div> -->
    <div class="student" slot="reference">
        <router-link :to="{ path: '/student/index' }" target="_blank">
        <img src="../../assets/image/student/1.gif" alt="" />
        </router-link>
      </div>
    <div class="box" @click="getAnnouncemen()">
      <img src="../../assets/image/icon/speaker.png" alt="" />
      <p>公告</p>
    </div>
    <div class="box" @click="$topUpPop({ data: { balance: balance } })">
      <img src="../../assets/image/icon/zgold.png" alt="" />
      <p>知币</p>
    </div>
    <div class="box">
      <router-link :to="{ path: '/sign/index' }">
        <img src="../../assets/image/icon/money.png" alt="" />
        <p>签约</p>
      </router-link>
    </div>
    <el-popover placement="left" trigger="hover">
      <div class="appMenu">
        <!-- <div>
          <img src="../../assets/image/qrcode/ios.png" alt="" />
          <p>IOS</p>
        </div> -->
        <div>
          <img src="../../assets/image/qrcode/wx.png" alt="" />
          <!-- <p>Android</p> -->
        </div>
      </div>
      <div class="box" slot="reference">
        <img src="../../assets/image/icon/MiniProgram.png" alt="" />
        <p>小程序</p>
      </div>
    </el-popover>

    <div class="box">
      <router-link :to="{ path: '/other/index', query: { type: 4 } }">
        <img src="../../assets/image/icon/feedback.png" alt="" />
        <p>反馈</p>
      </router-link>
    </div>
    <!-- <div class="box" @click="goOld()">
      <img src="../../assets/image/icon/old.png" alt="" />
      <p>旧版</p>
    </div> -->
    <div class="box" @click="goTop">
      <img src="../../assets/image/icon/top.png" alt="" />
      <p>顶部</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { latestAnnouncement } from "../../Api";
export default {
  name: "Side",
  data() {
    return {
      navFixed: false,
    };
  },
  methods: {
    getAnnouncemen() {
      latestAnnouncement().then((res) => {
        this.$placardPop().show(res.data);
      });
    },
    handleScroll() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // const offsetTop = document.querySelector('.sideBox').offsetTop * 3;
      if (scrollTop > 500) {
        this.navFixed = true;
      } else {
        this.navFixed = false;
      }
    },
    goTop() {
      cancelAnimationFrame(this.timer);
      const self = this;
      self.timer = requestAnimationFrame(function fn() {
        const oTop =
          document.body.scrollTop || document.documentElement.scrollTop;
        if (oTop > 0) {
          document.body.scrollTop = document.documentElement.scrollTop =
            oTop - 100;
          self.timer = requestAnimationFrame(fn);
        } else {
          cancelAnimationFrame(self.timer);
        }
      });
    },
    goOld() {
      window.open("https://old.zhishew.com");
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    ...mapGetters(["balance"]),
  },
};
</script>

<style scoped>
.sideBox {
  width: 50px;
  height: 4.15rem;
  /* background: black; */
  opacity: 1;
  border-radius: 2px;
}

.box {
  width: 0.5rem;
  /* height: 0.67rem; */
  background: #ffffff;
  opacity: 1;
  border-radius: 2px;
  margin-bottom: 0.05rem;
  padding: 0.1rem 0;
  cursor: pointer;
}

.box p {
  font-size: 0.12rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #040404;
  opacity: 1;
  text-align: center;
  cursor: pointer;
}

.box img {
  width: 0.26rem;
  height: 0.26rem;
  margin: 0 0.12rem 0.04rem;
}
.appMenu {
  /* background: #ffffff; */
  opacity: 1;
  border-radius: 4px;
  /* padding: 20px 10px 14px; */
  /* height: 128px; */
  display: flex;
}
/* .appMenu div:first-child {
  margin-right: 40px;
} */
.appMenu img {
  /* width: 68px; */
  height: 120px;
  opacity: 1;
  border-radius: 0px;
}
.appMenu p {
  margin-top: 6px;
  text-align: center;
}
.student {
  width: 0.5rem;
  height: 0.85rem;
  opacity: 1;
  border-radius: 2px;
  margin-bottom: 0.05rem;
}
.student img {
  width: 0.5rem;
  height: 0.85rem;
}
.studentPop {
  opacity: 1;
  border-radius: 6px;
}
.studentPop img {
  width: 222px;
}
</style>