<!--
 * @FilePath: \zhishe-pc\src\layout\index.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-23 14:04:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2021-12-20 11:01:20
-->
<template>
  <div class="main_Div">
    <Navbar></Navbar>
    <div class="uploadLayout" v-if="pageActivePath == '/upload'">
      <div class="layoutLeft xiding">
        <UploadSidebar></UploadSidebar>
      </div>
      <div class="layoutRight">
        <router-view />
      </div>
    </div>
    <div class="uploadLayout" v-else-if="pageActivePath == '/personalCenter'">
      <div class="layoutLeft" style="min-width: 2.7rem">
        <PersonalCenterSidebar></PersonalCenterSidebar>
      </div>
      <div class="layoutRight">
        <router-view />
      </div>
    </div>
    <div v-else>
      <router-view />
    </div>
    <Footer></Footer>
    <div class="side">
      <Side></Side>
    </div>
  </div>
</template>

<script>
import {
  Navbar,
  UploadSidebar,
  PersonalCenterSidebar,
  Footer,
  Side,
} from "./componts";
import {mapGetters} from "vuex";
export default {
  name: "Layout",
  created() {
    // 会员倒计时七天提示续费
    if((this.isLogin && this.userInfo.is_first_login && this.userInfo.is_vip.class.is_vip && this.userInfo.is_vip.class.remain_day <= 7) || (this.isLogin && this.userInfo.is_first_login && this.userInfo.is_vip.library.is_vip && this.userInfo.is_vip.library.remain_day <= 7)){
      this.$nextTick(()=>{
        this.$renewPop().show(this.userInfo);
      })
    }
    // if((this.isLogin && this.userInfo.is_first_login && !this.userInfo.is_vip.class.is_vip) && (this.isLogin && this.userInfo.is_first_login && !this.userInfo.is_vip.library.is_vip)) {
    //   this.$nextTick(()=>{
    //     this.$inviteVipPop().show();
    //   })
    // }
  },
  components: {
    Navbar,
    UploadSidebar,
    PersonalCenterSidebar,
    Footer,
    Side,
  },
  computed: {
    ...mapGetters(["isLogin",'userInfo']),
    pageActivePath() {
      const route = this.$route;
      const { meta } = route;
      return meta.activeMenu;
    },
  },
};
</script>

<style scoped>
.xiding {
  position: sticky;
  top: 0.68rem;
}
.layoutLeft {
  margin-right: 0.2rem;
  width: 2.4rem;
}
.layoutRight {
  flex: 1;
}
.uploadLayout {
  width: 100%;
  min-width: 1400px;
  padding: 0 2.8rem;
  display: flex;
  align-items: flex-start;
  margin-top: 0.2rem;
}
.side {
  position: fixed;
  top: 2.48rem;
  right: 5px;
  z-index: 9;
}
</style>