<template>
  <div>
    <el-dialog
      :visible.sync="centerDialogVisible"
      center
      :destroy-on-close="true"
      :show-close="false"
      :close-on-click-modal="true"
    >
      <div class="secretBox">
        <div class="head">
          <p>绑定手机号</p>
          <img
            src="../../assets/image/icon/close.png"
            alt=""
            @click="centerDialogVisible = false"
          />
        </div>
        <p>
          绑定后即可用该手机号登录知设网，并可以在知设网内发布作品以及签约知设网
        </p>
        <el-input
          v-model="username"
          placeholder="输入手机号码"
          class="username"
          @change="checkTel"
        >
        </el-input>
        <div class="codeBox">
          <el-input v-model="code" placeholder="输入验证码"> </el-input>
          <div class="getCode" @click="getCode">{{ getTxt }}</div>
        </div>
        <el-input
          v-model="password"
          placeholder="输入密码"
          v-if="!isRegister"
          show-password
        >
        </el-input>
        <div class="submit" @click="submitForm">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getMessage, sendMessage, checkTel, bindMobile } from "@/Api";
import { getToken, setToken } from "../../utils/auth";
export default {
  name: "index",
  data() {
    return {
      centerDialogVisible: false,
      timer: "",
      token: getToken(),
      username: "",
      code: "",
      getTxt: "获取验证码",
      password: "",
      isRegister: false,
      isGet: true,
      status: false,
    };
  },
  methods: {
    close() {
      this.centerDialogVisible = false;
    },
    show(status = false) {
      //先判断登录状态
      if (this.token) {
        this.status = status;
        this.centerDialogVisible = true;
      }else{
        this.$login()
      }
    },
    //检查手机号是否注册
    checkTel() {
      if (this.username.length == 11)
        checkTel(this.username).then((res) => {
          if (res.data.res == 0) {
            this.isRegister = false;
          } else {
            this.isRegister = true;
          }
        });
    },
    // 获取验证
    getCode() {
      if (!this.username) {
        this.$toast({ text: "手机号不能为空" });
        return;
      } else {
        const reg =
          /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
        if (!reg.test(this.username)) {
          this.$toast({ text: "手机号格式不正确" });
          return;
        }
      }
      if (this.isGet) {
        getMessage(this.username).then((response) => {
          let auth = response.data.auth;
          let num = this.$randomWord(true, 8, 8);
          let newSendMsg = num + auth;
          let data = { auth: newSendMsg };
          if (this.isRegister) {
            data = { auth: newSendMsg, type: 9 };
          }
          sendMessage(data).then((res) => {
            if (res.status == 200 && res.data.error == 0) {
              this.$message.success("发送成功");
              this.isGet = false;
              const TIME_COUNT = 60;
              if (!this.timer) {
                this.count = TIME_COUNT;
                this.timer = setInterval(() => {
                  if (this.count > 0 && this.count <= TIME_COUNT) {
                    this.getTxt = "重新获取" + this.count;
                    this.count--;
                  } else {
                    this.getTxt = "重新获取";
                    this.isGet = true;
                    clearInterval(this.timer);
                    this.timer = null;
                  }
                }, 1000);
              }
            } else {
              this.$message.error("发送失败");
            }
          });
        });
      }
    },
    submitForm() {
      if (!this.username) {
        this.$toast({ text: "手机号不能为空" });
        return;
      } else {
        const reg =
          /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
        if (!reg.test(this.username)) {
          this.$toast({ text: "手机号格式不正确" });
          return;
        }
      }
      if (!this.password && !this.isRegister) {
        this.$toast({ text: "密码不能为空" });
        return;
      }
      if (!this.code) {
        this.$toast({ text: "验证码不能为空" });
        return;
      }
      const data = {
        code: this.code,
        password: this.password,
        mobile: this.username,
        token: this.token,
      };
      bindMobile(data).then((res) => {
        if (res.data.msg == "success") {
          this.$toast({ text: "绑定成功", type: "success" });
          setToken(res.data.token);
          this.centerDialogVisible = false;
          window.location.reload()
        } else if (res.data.message == "验证码错误") {
          this.$toast({ text: "验证码错误" });
        } else {
          this.$toast({ text: "绑定错误" });
        }
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
.secretBox {
  margin: 0 auto;
  border-radius: 8px;
  position: relative;
  width: 384px;
  height: 385px;
  background: #ffffff;
  opacity: 1;
  padding: 20px 30px 0 30px;
}
.secretBox p:nth-child(1) {
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #040404;
  line-height: 22px;
  margin-bottom: 20px;
}
.secretBox p:nth-child(2) {
  width: 324px;
  height: 34px;
  font-size: 12px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 17px;
  margin-bottom: 40px;
}

::v-deep .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  height: px;
  background: #eeeeee;
  opacity: 1;
  border-radius: 4px;
  margin-bottom: 17px;
}

::v-deep .el-input__inner {
  -webkit-appearance: none;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
}

::v-deep .el-button--primary:first-child {
  width: 120px;
  background-color: #ffffff;
  border-color: #d9d9d9;
  color: #999999;
}
::v-deep .el-button--primary:last-child {
  width: 120px;
  background-color: #ff9e58;
  border-color: #ff9e58;
}

::v-deep .el-dialog {
  background: rgba(0, 0, 0, 0);

  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0);

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0);
}
.codeBox {
  display: flex;
  justify-content: space-between;
}
.getCode {
  width: 94px;
  height: 40px;
  background: #f7f6fb;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #040404;
  cursor: pointer;
}
.submit {
  margin-top: 20px;
  width: 94px;
  height: 40px;
  background: #ff9e58;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  font-size: 14px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ffffff;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  float: right;
}
.head {
  display: flex;
  justify-content: space-between;
}
.head img {
  width: 16px;
  height: 16px;
}
</style>